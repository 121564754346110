import auth0 from 'auth0-js'
import { replace } from 'connected-react-router'
import { authChanged, logout } from 'actions/authentication'
import { activeTenantChanged } from 'actions/user'
import { AUTH0_CLIENT_ID } from 'helper/constants'

const ROOT_URI = process.env.ROOT_URI || 'http://localhost:8000'

export default class Auth {
  constructor(props) {
    const { store } = props
    this.store = store
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
  }

  auth0 = new auth0.WebAuth({
    domain: 'ritterwald.eu.auth0.com',
    audience: 'https://ritterwald-api-gateway',
    clientID: AUTH0_CLIENT_ID,
    redirectUri: `${ROOT_URI}/callback`,
    responseType: 'token id_token',
    scope: 'openid',
  });

  login() {
    this.auth0.authorize()
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
        this.store.dispatch(replace('/'))
      } else if (err) {
        this.store.dispatch(replace('/'))
      }
    })
  }

  setSession(authResult) {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)

    this.store.dispatch(authChanged({ accessToken: authResult.accessToken, idToken: authResult.idToken, expiresAt }))
    // navigate to the home route
    this.store.dispatch(replace('/'))
  }

  logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    // navigate to the home route
    this.store.dispatch(activeTenantChanged(null))
    this.store.dispatch(logout())
    this.store.dispatch(replace('/'))
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }
}
