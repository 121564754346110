import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.less'

class PackageItem extends Component {
  static propTypes = {
    lineItemPackage: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
  }

  static defaultProps = {
    loading: false,
    loadingMessage: '',
  }

  handleClick = () => {
    const { lineItemPackage, onClick } = this.props
    onClick(lineItemPackage)
  }

  renderLoading = () => (
    <>
      <FontAwesomeIcon className="PackageItem__LoadingIcon" icon={['fal', 'spinner']} spin />
      <div>{this.props.loadingMessage}</div>
    </>
  )

  renderValues = () => (
    <>
      <div className="PackageItem__Name">
        {this.props.lineItemPackage.name}
      </div>
      <div className="PackageItem__Description">
        {this.props.lineItemPackage.description}
      </div>
    </>
  )

  render() {
    const { loading } = this.props
    return (
      <button className="PackageItem" type="button" onClick={this.handleClick}>
        {loading ? this.renderLoading() : this.renderValues()}
      </button>
    )
  }
}

export default PackageItem
