import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const Filter = ({
  children,
}) => (
  <div className="Filter">
    {children}
  </div>
)

Filter.propTypes = {
  children: PropTypes.node,
}

Filter.defaultProps = {
  children: '',
}

export default Filter
