
import { handleActions } from 'redux-actions'

import { orderTasksChangeAction, taskIdsChanged } from 'actions/tasks'


export const orderAttributes = {
  orderByUpdateNewest: 'orderByUpdateNewest',
  orderByUpdateOldest: 'orderByUpdateOldest',
  orderByGreatestVolume: 'orderByGreatestVolume',
}

const reducer = handleActions({
  [orderTasksChangeAction]: (state, action) => ({
    ...state,
    order: action.payload,
  }),
  [taskIdsChanged]: (state, action) => ({
    ...state,
    ids: action.payload,
  }),
}, {
  order: orderAttributes.orderByUpdateNewest,
  ids: [],
})

export default reducer
