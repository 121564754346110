import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import OrderProfitability from 'components/order/OrderProfitability'
import OrderBaseData from 'containers/order/OrderBaseData'
import OrderRoomSizeSelection from 'containers/order/OrderRoomSizeSelection'
import OrderProductSummary from 'containers/order/OrderProductSummary'
import { Route, Switch } from 'react-router-dom'


import { TopBar } from 'components/common/ToolBar'
import ToolBarItem from 'components/common/ToolBarItem'
import getPath, { getSelectedOrderId } from 'selectors/navigation'
import { getSelectedOrder } from 'selectors/order'

import './style.less'

class OrderSummary extends Component {
  static propTypes = {
    orderId: PropTypes.string.isRequired,
    ...i18nPropTypes,
  }

  getPath = (orderId, locationKey) => `/orders/${orderId}/${locationKey}`

  render() {
    const {
      t,
      orderId,
      order,
    } = this.props
    return (
      <div className="OrderSummary">
        <TopBar>
          <ToolBarItem to={this.getPath(orderId, 'basedata')} label={t('navigation.basedata')} icon="sliders-h" />
          <ToolBarItem to={this.getPath(orderId, 'comments')} label={t('navigation.orderLineItems')} icon="bars" />
          <ToolBarItem to={this.getPath(orderId, 'rooms')} label={t('navigation.rooms')} icon="cubes" />
          <ToolBarItem to={this.getPath(orderId, '')} label={t('navigation.profitability')} icon="chart-line" />
        </TopBar>
        <div className="OrderSummary__Content">
          <Switch>
            <Route
              exact
              path={this.getPath(orderId, 'basedata')}
              component={OrderBaseData}
            />
            <Route
              exact
              path={this.getPath(orderId, 'comments')}
              component={OrderProductSummary}
            />
            <Route
              exact
              path={this.getPath(orderId, 'rooms')}
              component={OrderRoomSizeSelection}
            />
            <Route
              exact
              path={this.getPath(orderId, '')}
              render={() => <OrderProfitability order={order} />}
            />
          </Switch>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  orderId: getSelectedOrderId(state),
  order: getSelectedOrder(state),
})

export default connect(mapStateToProps, { })(translate()(OrderSummary))
