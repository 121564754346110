import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const HashLink = ({
  className = '',
  children,
  to,
}) => (
  <a className={className} href={to}>
    { children }
  </a>
)

/* eslint-disable react/require-default-props */
HashLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
}

export default HashLink
