import { schema } from 'normalizr'

export const roomSizesSchema = new schema.Entity('roomSizes')
export const roomTypesSchema = new schema.Entity('roomTypes')
export const gradesSchema = new schema.Entity('grades')

export const roomSchema = new schema.Entity('rooms', {
  roomType: roomTypesSchema,
})

export const userSchema = new schema.Entity('users')

export const contractorSchema = new schema.Entity('contractors')

export const teamSchema = new schema.Entity('teams', {
  manager: userSchema,
  users: [userSchema],
})

export const apartmentSchema = new schema.Entity('apartments', {
  rooms: [roomSchema],
})

export const apartmentImportSchema = new schema.Entity('apartmentImports')
export const catalogSchema = new schema.Entity('catalogs')
export const orderLineItemsSchema = new schema.Entity('orderLineItems')
export const commentSchema = new schema.Entity('comments')
export const changelogSchema = new schema.Entity('changelogs')

export const packageSchema = new schema.Entity('packages', {
  catalog: catalogSchema,
})

export const packageProductSchema = new schema.Entity('packageProducts', {})

export const tenantSchema = new schema.Entity('tenants', {
  departmentHead: userSchema,
  defaultCatalog: catalogSchema,
})

export const productsSchema = new schema.Entity('products', {
  roomType: roomTypesSchema,
  roomSize: roomSizesSchema,
  grade: gradesSchema,
})

catalogSchema.define({
  products: [productsSchema],
})

export const orderSchema = new schema.Entity('orders', {
  apartment: apartmentSchema,
  catalog: catalogSchema,
  orderLineItems: [orderLineItemsSchema],
  contractor: contractorSchema,
  owner: userSchema,
  technician: userSchema,
  processUser: userSchema,
  comments: [commentSchema],
})

commentSchema.define({
  orderLineItem: orderLineItemsSchema,
  orderHeader: orderSchema,
})

changelogSchema.define({
  orderLineItem: orderLineItemsSchema,
})

orderLineItemsSchema.define({
  comments: [commentSchema],
  changelogs: [changelogSchema],
})

export const reportSchema = new schema.Entity('reports')
export const craftSchema = new schema.Entity('crafts')


export default {
  rooms: roomSchema,
  roomSizes: roomSizesSchema,
  users: userSchema,
  teams: teamSchema,
  apartments: apartmentSchema,
  apartmentImports: apartmentImportSchema,
  catalogs: catalogSchema,
  orderLineItems: orderLineItemsSchema,
  orders: orderSchema,
  tenants: tenantSchema,
  roomTypes: roomTypesSchema,
  grades: gradesSchema,
  products: productsSchema,
  comments: commentSchema,
  contractors: contractorSchema,
  packages: packageSchema,
  packageProducts: packageProductSchema,
  reports: reportSchema,
  crafts: craftSchema,
  changelogs: changelogSchema,
}
