/* global window */
// import { hashHistory } from 'react-router'
import { applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import LocalStorageDump from 'middlewares/LocalStorageDump'
import LocalStorageLoad from 'middlewares/LocalStorageLoad'

import RavenIntegration from 'integrations/Raven'

import reducer from 'reducers'

// import createBrowserHistory from 'history/createBrowserHistory'
import { createBrowserHistory  } from 'history'
import { initializeStore, getStore } from 'store'

let history

export const getHistory = () => history

export const initializeApp = () => {
  history = createBrowserHistory()

  const enhancers = [
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      thunk,
      LocalStorageLoad,
      LocalStorageDump,
      ...RavenIntegration.getMiddlewares(),
    ),
  ]

  if (window.devToolsExtension && process.env.NODE_ENV !== 'production') {
    enhancers.push(window.devToolsExtension())
  }

  initializeStore(
    connectRouter(history)(reducer), // new root reducer with router state
    compose(...enhancers),
  )
  getStore().dispatch({ type: 'INIT' })
}
