import React from 'react'

import {
  Route,
  Switch,
} from 'react-router-dom'

import OrderSideMenu from 'containers/order/OrderSideMenu'
import MainSideMenu from 'components/MainSideMenu'
import ApartmentDetailSideMenu from 'containers/apartment/ApartmentDetailSideMenu'
import SettingsSideMenu from 'components/settings/SettingsSideMenu'

import './style.less'

const SideMenu = () => (
  <div className="SideMenu">
    <Switch>
      <Route path="/orders/:id" component={OrderSideMenu} />
      <Route path="/apartments/:id" component={ApartmentDetailSideMenu} />
      <Route path="/settings" component={SettingsSideMenu} />
      <Route path="/" component={MainSideMenu} />
    </Switch>
  </div>
)

export default SideMenu
