import * as api from 'api'
import { denormalize } from 'normalizr'

import { orderSchema, packageSchema } from 'api/schemas'
import { receiveEntities, requestEntityAction } from 'actions/entities'

export const upsertPackageAction = productPackage => async (dispatch, getState) => {
  const apiCall = productPackage.id ? api.updatePackage : api.createPackage
  const payload = await apiCall(productPackage)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, packageSchema, getState().entities)
}

export const upsertPackageProductAction = packageProduct => async (dispatch, getState) => {
  const apiCall = packageProduct.id ? api.updatePackageProduct : api.createPackageProduct
  const payload = await apiCall(packageProduct)
  dispatch(receiveEntities({ results: payload }))
  dispatch(requestEntityAction('packages', packageProduct.package))
  return denormalize(payload.result, packageSchema, getState().entities)
}

export const applyPackageAction = (lineItemPackage, order) => async (dispatch, getState) => {
  const payload = await api.applyPackage(lineItemPackage, order)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, orderSchema, getState().entities)
}
