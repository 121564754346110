import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'

import ProcessState from 'components/process/ProcessState'

import TableDataView from 'containers/common/TableDataView'

import { userDisplayName } from 'helper/viewHelper'


const ENTITY_TYPE = 'orders'

class OrdersTable extends Component {
  static propTypes = {
    ...i18nPropTypes,
    push: PropTypes.func.isRequired,
    viewId: PropTypes.string.isRequired,
    filters: PropTypes.shape({}),
  }

  static defaultProps = {
    filters: {},
  }

  handleSelectOrder = entity => this.props.push(`/orders/${entity.id}`)

  render = () => {
    const { t, viewId, filters } = this.props

    const columns = [
      { header: t('orders.processState'), accessor: order => <ProcessState className="Orders__ProcessState" order={order} /> },
      { header: t('table.id'), accessor: order => `#${order.id}`, ordering: 'id' },
      { header: t('table.externalId'), accessor: 'apartment.externalId' },
      { header: t('apartment.address'), accessor: 'apartment.address' },
      { header: t('apartment.owner'), accessor: order => userDisplayName(order.owner), ordering: 'owner.lastName' },
      { header: t('apartment.size'), accessor: order => `${order?.apartment?.size?.toFixed(2)}m²`, style: { textAlign: 'right' } },
    ]

    return (
      <TableDataView
        viewId={viewId}
        entityType={ENTITY_TYPE}
        columns={columns}
        onSelectEntity={this.handleSelectOrder}
        filters={filters}
      />
    )
  }
}

const mapDispatchToProps = {
  push,
}

export default connect(null, mapDispatchToProps)(translate()(OrdersTable))
