import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userDisplayName } from 'helper/viewHelper'
import Price from 'components/common/Price'
import ProcessState from 'components/process/ProcessState'
import './style.less'

const ChangelogItem = ({ changelogItem, t }) => {
  let { action, attribute, oldValue, newValue, role, product, orderProcessState } = changelogItem
  const isComment = !action

  let unit = product?.unit || ''
  let obj = ''
  let verb = ''
  let icon = null
  let name = userDisplayName(changelogItem?.author)
  let className = 'ChangelogItem'
  if (!role) {
    role = []
  } else if (role.constructor !== Array) {
    role = [role]
  }
  if (role.length > 0) {
    name += ' (' + role.map(r => t(`changelogs.${r}`)).join(', ') + ')'
  }

  if (['qty', 'product', 'price'].includes(attribute)) {
    oldValue = parseFloat(oldValue)
    newValue = parseFloat(newValue)
  }
  if (attribute === 'qty' && oldValue === 0 && newValue > 0) {
    action = 'CREATE'
  }
  if (attribute === 'qty' && oldValue > 0 && newValue === 0) {
    action = 'REMOVE'
  }

  switch (attribute) {
    case 'description':
    case 'price':
    case 'product':
    case 'qty':
      obj = t(`changelogs.${attribute}WithArticle`)
      break
    case 'is_required':
      obj = t(`changelogs.isRequiredWithArticle`)
      break
    default:
      obj = t('changelogs.thisProduct')
      break
  }

  switch (true) {
    case isComment:
      verb = t('changelogs.commented')
      icon = 'comment-dots'
      className += ' ChangelogItem--Comment'
      break
    case action === 'CREATE':
      obj = t('changelogs.thisProduct')
      verb = t('changelogs.added')
      icon = 'plus-circle'
      break
    case action === 'REMOVE':
      obj = t('changelogs.thisProduct')
      verb = t('changelogs.removed')
      icon = 'trash'
      break
    case attribute === 'is_required':
      verb = oldValue === 'false' && newValue === 'true'
        ? t('changelogs.activated')
        : t('changelogs.deactivated')
      icon = 'pen'
      break
    case action === 'UPDATE':
    default:
      verb = t('changelogs.edited')
      icon = 'pen'
      break
  }

  const created = moment(changelogItem.created)

  return (
    <div className={className}>
      <div className="ChangelogItem__Aside">
        <div className="ChangelogItem__Icon">
          <FontAwesomeIcon icon={['fal', icon]} />
        </div>
      </div>
      <div className="ChangelogItem__Main">
        <div className="ChangelogItem__Action">
          <strong>{name}</strong> {t('changelogs.has')} <strong>{obj}</strong> {verb}.
        </div>
        {isComment &&
          <div className="ChangelogItem__Update">
            {changelogItem.text}
          </div>
        }
        {!isComment && attribute && attribute !== 'is_required' && action === 'UPDATE' &&
          <div className="ChangelogItem__Update">
            {attribute === 'description' && <span>{oldValue}</span>}
            {attribute === 'price' && <Price>{oldValue}</Price>}
            {attribute === 'product' && <span>{oldValue?.title}</span>}
            {attribute === 'qty' && <span>{oldValue} {unit}</span>}
            &nbsp;<FontAwesomeIcon icon={['fal', 'long-arrow-alt-right']} className="ChangelogItem__UpdateIcon" />&nbsp;
            {attribute === 'description' && <span>{newValue}</span>}
            {attribute === 'price' && <Price>{newValue}</Price>}
            {attribute === 'product' && <span>{newValue?.title}</span>}
            {attribute === 'qty' && <span>{newValue} {unit}</span>}
          </div>
        }
        <div className="ChangelogItem__StateAndDate">
          <ProcessState className="ChangelogItem__ProcessState" processState={orderProcessState} isActive={true} isInline={true} />
          <span className="ChangelogItem__CreatedDate">
            {created.format('DD.MM.YYYY') + ' ' + t('changelogs.dateAt') + ' ' + created.format('HH:mm') + ' ' + t('changelogs.dateOclock')}
          </span>
        </div>
      </div>
    </div>
  )
}

ChangelogItem.propTypes = {
  changelogItem: PropTypes.shape({}),
  ...i18nPropTypes,
}

ChangelogItem.defaultProps = {
  changelogItem: {},
}

export default translate()(ChangelogItem)
