import {
  fromPairs,
  toPairs,
  groupBy,
  keys,
  sumBy,
  values,
} from 'lodash'
import { createSelector } from 'reselect'
import slugify from 'slugify'
import { getSelectedOrderId, getSelectedRoomId, getSelectedProductPackageId, getSelectedProductId } from 'selectors/navigation'
import { denormalize } from 'normalizr'
import { orderSchema, productsSchema } from 'api/schemas'
import CatalogSelector from 'selectors/catalogs'
import { sortRooms } from 'helper/dataHelper'

import EnumValuesSelector from 'selectors/enumValues'

// TODO: The key for each craft should be the external_id of the craft used in the backend -> not yet implemented in backend
const defaultCrafts = [
  { icon: 'ruler-combined', name: 'Abbruch' },
  { icon: 'bolt', name: 'Elektro' },
  { icon: 'cabinet-filing', name: 'Küchenmöbel' },
  { icon: 'toolbox', name: 'Tischler' },
  { icon: 'bullseye', name: 'Bodenbelag' },
  { icon: 'paint-roller', name: 'Maler' },
  { icon: 'th', name: 'Fliesen' },
  { icon: 'wrench', name: 'Sanitär und Heizung' },
  { icon: 'screwdriver', name: 'Sonstiges' },
]

const entitySelector = state => state?.entities || {}

export const getSelectedOrder = createSelector(
  entitySelector,
  getSelectedOrderId,
  (entities, selectedOrderId) => {
    if (!entities.orders) return null
    return denormalize(selectedOrderId, orderSchema, entities)
  },
)

export const getRoomsOfSelectedOrder = createSelector(
  getSelectedOrder,
  order => sortRooms(order?.apartment?.rooms || []),
)

const getSelectedRoom = createSelector(
  entitySelector,
  getSelectedRoomId,
  (entities, selectedRoomId) => entities?.rooms?.[selectedRoomId],
)

const productFilter = (product, room) => product && (!product.roomType || product.roomType === room.roomType || product.roomType === null)
  && (product.roomSize === null || product.roomSize === room.roomSize)


const buildProductGroups = (products, productLineItemIndex, grades, entities) => {
  const productsByGroupKey = groupBy(products, product => (product.groupKey ? `PG_${product.groupKey}` : product.id))
  return keys(productsByGroupKey).map((productGroupKey) => {
    const groupKeyProducts = productsByGroupKey[productGroupKey]
    const lineItemProduct = groupKeyProducts.find(product => (productLineItemIndex[product.id] ? product : null))
    const lineItem = productLineItemIndex[lineItemProduct?.id]

    return {
      productGroupKey,
      products: groupKeyProducts.sort((p1, p2) => grades[p1.grade]?.order - grades[p2.grade]?.order),
      // .map(product => denormalize(product.id, productsSchema, entities)), TODO: Does not work yet
      lineItem,
      lineItemProduct,
    }
  }).sort((pg1, pg2) => pg1.products[0].catalogOrder - pg2.products[0].catalogOrder)
}

const resolveProducts = (productIds, entities) => productIds?.map(productId => entities?.products?.[productId])

const getCrafts = createSelector(
  getSelectedOrder,
  getSelectedRoom,
  CatalogSelector.getCatalogForSelectedOrder,
  EnumValuesSelector.getGrades,
  entitySelector,
  (order, room, catalog, grades, entities) => {
    const roomLineItems = order?.orderLineItems?.filter(lineItem => lineItem && (lineItem.room === room.id || !lineItem.room)) || []
    const productLineItemIndex = fromPairs(roomLineItems.map(lineItem => [lineItem.product, lineItem]))
    const products = resolveProducts(catalog?.products, entities)?.filter(product => productFilter(product, room))

    const crafts = defaultCrafts.map(craft => craft.name)

    const productsByCraft = groupBy(products, product => (crafts.includes(product.craft) ? product.craft : 'Sonstiges'))

    return defaultCrafts.filter(craft => productsByCraft[craft.name]).map((craft) => {
      const productGroups = buildProductGroups(productsByCraft[craft.name], productLineItemIndex, grades, entities)
      const sumGroups = groups => sumBy(groups, group => group?.lineItem.qty * group?.lineItem.price)

      return {
        ...craft,
        slugifiedName: slugify(craft.name).toLowerCase(),
        productGroups,
        badgeCount: productGroups.filter(group => group?.lineItem?.qty > 0).length,
        requiredAmount: sumGroups(productGroups.filter(group => group?.lineItem?.isRequired)),
        overallAmount: sumGroups(productGroups.filter(group => group?.lineItem)),
      }
    })
  },
)

const getLineItemsWithDifferentRoomSize = createSelector(
  getSelectedOrder,
  getSelectedRoom,
  CatalogSelector.getCatalogForSelectedOrder,
  EnumValuesSelector.getGrades,
  entitySelector,
  (order, room, catalog, grades, entities) => order?.orderLineItems?.filter((lineItem) => {
    if (lineItem && lineItem.room === room.id && lineItem.product) {
      const product = entities?.products?.[lineItem.product]
      return product && product.roomSize && product.roomSize !== room.roomSize
    }
    return false
  }).map(lineItem => ({
    ...lineItem,
    product: {
      ...entities?.products?.[lineItem.product],
      roomSize: { ...entities?.roomSizes?.[entities?.products?.[lineItem.product].roomSize] }
    },
  })),
)

const getFreeTextLineItems = createSelector(
  getSelectedOrder,
  getSelectedRoom,
  (order, room) => order?.orderLineItems?.filter(lineItem => lineItem && !lineItem.product && (!room || lineItem.room === room.id)) || [],
)

const getFreeTextLineItemsSummary = createSelector(
  getFreeTextLineItems,
  lineItems => lineItems.reduce(({ overallAmount = 0, requiredAmount = 0 }, lineItem) => ({
    overallAmount: overallAmount + lineItem.price * lineItem.qty,
    requiredAmount: requiredAmount + (lineItem.isRequired ? lineItem.price * lineItem.qty : 0),
  }), {}),
)

const getFreeOrderLineItemsByRooms = createSelector(
  getSelectedOrder,
  (order) => {
    const lineItemsWithRoom = order?.orderLineItems?.filter(
      lineItem => lineItem.room && lineItem.qty > 0 && lineItem.product === null
      // todo: switch to show in overview
      // lineItem => lineItem.room && lineItem.product === null
    ) || []
    const lineItemsByRoom = groupBy(lineItemsWithRoom, lineItem => lineItem.room)
    return lineItemsByRoom
  },
)

const getProductGroupsByRooms = createSelector(
  getSelectedOrder,
  CatalogSelector.getCatalogForSelectedOrder,
  EnumValuesSelector.getGrades,
  entitySelector,
  (order, catalog, grades, entities) => {
    const lineItemsByRoom = groupBy(order?.orderLineItems, lineItem => lineItem.room || 'Wohnung')
    const result = []
    Object.entries(lineItemsByRoom).forEach(([roomId, lineItems]) => {
      const room = entities?.rooms?.[roomId] || { id: 'Wohnung', name: 'Wohnung' }
      const productLineItemIndex = fromPairs(lineItems.map(lineItem => [lineItem.product, lineItem]))

      const products = resolveProducts(catalog?.products, entities)?.filter(product => productFilter(product, room))
        .filter(product => productLineItemIndex[product.id])

      const crafts = defaultCrafts.map(craft => craft.name)

      const productsByCraft = groupBy(products, product => (crafts.includes(product.craft) ? product.craft : 'Sonstiges'))

      result.push({
        ...room,
        lineItemsByProduct: defaultCrafts.filter(craft => productsByCraft[craft.name]).map((craft) => {
          const productGroups = buildProductGroups(productsByCraft[craft.name], productLineItemIndex, grades, entities)
            .filter(group => {
              if (group.lineItem?.qty > 0) {
                return true
              }
              let removedByTechnician = group.lineItem?.changelogs?.reduce((acc, changelogItem) => {
                if (changelogItem.orderProcessState === 'Draft') {
                  return acc
                }
                // if (!changelogItem.role.includes('TECHNICIAN')) {
                //   return acc
                // }
                if (changelogItem.attribute !== 'qty') {
                  return acc
                }
                if (parseFloat(changelogItem.newValue) !== 0) {
                  return acc
                }
                return true
              }, false)
              return removedByTechnician
            })
          const sumGroups = groups => sumBy(groups, group => group?.lineItem.qty * group?.lineItem.price)

          return {
            ...craft,
            slugifiedName: slugify(craft.name).toLowerCase(),
            productGroups,
            badgeCount: productGroups.filter(group => group?.lineItem?.qty > 0).length,
            requiredAmount: sumGroups(productGroups.filter(group => group?.lineItem?.isRequired)),
            overallAmount: sumGroups(productGroups.filter(group => group?.lineItem)),
          }
        }),
      })
    })
    return result
  },
)

const sortProduct = (p1, p2) => ['roomType', 'roomSize', 'grade']
  .map(attr => (p1[attr]?.order || 0) - (p2[attr]?.order || 0))
  .find(result => !!result)


const getProductPackageGroupsByCrafts = createSelector(
  getSelectedProductPackageId,
  entitySelector,
  (productPackageId, entities) => {
    const productPackage = entities?.packages?.[productPackageId]
    const catalog = entities?.catalogs?.[productPackage?.catalog]

    if (!productPackage || !catalog) return null

    const packageProductsByProductId = fromPairs(productPackage.packageProducts
      .map(packageProduct => [packageProduct.product, packageProduct]))

    const products = denormalize(catalog?.products, [productsSchema], entities)
      ?.filter(product => product != null)
      ?.map(product => ({ ...product, packageProduct: packageProductsByProductId[product.id] }))

    if (!products || products.includes(undefined)) return null

    const craftNames = defaultCrafts.map(craft => craft.name)
    const productsByCraft = groupBy(products, product => (craftNames.includes(product.craft) ? product.craft : 'Sonstiges'))

    const result = defaultCrafts.filter(craft => productsByCraft[craft.name]).map((craft) => {
      const craftProducts = productsByCraft[craft.name]
      const productsByGroupKey = groupBy(craftProducts, product => (product.groupKey ? `PG_${product.groupKey}` : product.id))

      return {
        ...craft,
        productsByGroupKey: values(productsByGroupKey)
          .map(group => group.sort(sortProduct)),
      }
    })

    return result
  }
)

const roomSizeMissing = createSelector(
  getSelectedRoom,
  entitySelector,
  (room, entities) => {
    const roomType = entities?.roomTypes[room?.roomType]
    return (roomType?.externalId === 'Zimmer') && !(room?.roomSize)
  },
)

const anyRoomSizeMissing = createSelector(
  getSelectedOrder,
  entitySelector,
  (order, entities) => {
    const apartment = entities.apartments?.[order.apartment.id]

    return apartment.rooms
      .map(roomId => entities.rooms?.[roomId])
      .filter(r => (entities.roomTypes?.[r.roomType]?.externalId === 'Zimmer') && !(r?.roomSize))
      .length > 0
  },
)

const editStates = ['Draft', 'Correct', 'ReviewOrder']

const isReadOnly = createSelector(
  getSelectedOrder,
  order => !editStates.includes(order?.processState),
)

const getRoomSummary = createSelector(
  getSelectedOrder,
  (order) => {
    const orderLineItems = order?.orderLineItems?.filter(lineItem => lineItem !== undefined) || []
    const lineItemsByRoom = groupBy(orderLineItems, lineItem => lineItem.room)
    return fromPairs(toPairs(lineItemsByRoom).map(([roomId, lineItems]) => [
      roomId,
      {
        lineItemCount: lineItems.filter(lineItem => lineItem.qty > 0).length,
        totalPrice: lineItems.reduce((sum, lineItem) => sum + lineItem.price * lineItem.qty, 0),
      },
    ]))
  },
)

const findProductForRoom = (room, grade, products) => products?.filter(product => (!room.roomSize
  || (room.roomSize && product.roomSize === room.roomSize)) && (!grade || (grade && product.grade === grade)))[0]

const getLineItemsByProductGroup = createSelector(
  entitySelector,
  getSelectedOrder,
  getSelectedProductId,
  CatalogSelector.getCatalogForSelectedOrder,
  getRoomsOfSelectedOrder,
  (entities, order, productId, catalog, rooms) => {
    const product = entities?.products[productId]
    if (!product || !product.groupKey) return []

    const products = resolveProducts(catalog?.products, entities)?.filter(p => p.groupKey === product.groupKey)
    const productsByRoomType = groupBy(products, p => p.roomType)
    const productIds = products.map(p => p.id)
    const roomsLineItemIndex = fromPairs(order?.orderLineItems
      ?.filter(lineItem => productIds.includes(lineItem.product))
      .map(lineItem => [lineItem.room, lineItem]))

    const lineItem = order?.orderLineItems?.filter(l => l.product === product.id)?.[0] || { product: product.id, qyt: 0, isRequired: false }

    return rooms
      .map((room) => {
        const p = findProductForRoom(room, product.grade, productsByRoomType[room.roomType.id])

        if (!p) return null
        return {
          room,
          product: p,
          lineItem: roomsLineItemIndex[room.id] || { product: p.id, qty: lineItem.qty, isRequired: lineItem.isRequired },
        }
      })
      .filter(r => !!r)
  },
)

const resolveSelectedRoomType = createSelector(
  getSelectedRoom,
  entitySelector,
  (room, entities) => {
    return room ? entities?.roomTypes?.[room.roomType] : null
  }
)

export default {
  getSelectedOrder,
  getSelectedRoom,
  getCrafts,
  getProductGroupsByRooms,
  roomSizeMissing,
  isReadOnly,
  getFreeTextLineItems,
  getFreeTextLineItemsSummary,
  getFreeOrderLineItemsByRooms,
  getProductPackageGroupsByCrafts,
  getRoomSummary,
  getRoomsOfSelectedOrder,
  getLineItemsByProductGroup,
  anyRoomSizeMissing,
  getLineItemsWithDifferentRoomSize,
  resolveSelectedRoomType,
}
