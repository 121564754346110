import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { fromPairs, sum } from 'lodash'
import { goBack } from 'connected-react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PrimaryButton, SimpleButton } from 'components/common/Button'
import Price from 'components/common/Price'
import ProductQty from 'components/order/ProductQty'

import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'

import { updateLineItemAction } from 'actions/orders'
import { requestEntityAction } from 'actions/entities'

import { getSelectedOrderId, getSelectedProductId } from 'selectors/navigation'
import OrderSelector from 'selectors/order'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

class OrderProductRooms extends Component {
  static propTypes = {
    ...i18nPropTypes,
    productId: PropTypes.string.isRequired,
    updateLineItem: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    roomItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props)
    this.state = {
      qtysByRoomId: this.roomItemsToQtyMapping(props.roomItems),
      loading: false,
      error: null,
    }
  }

  roomItemsToQtyMapping = roomItems => fromPairs(roomItems.map(roomItem => [roomItem?.room.id, { qty: roomItem.lineItem.qty, dirty: false }]))

  handleQtyChange = roomItem => (newQty) => {
    const { qtysByRoomId } = this.state
    this.setState({ qtysByRoomId: { ...qtysByRoomId, [roomItem.room.id]: { qty: newQty, dirty: true } } })
  }

  componentDidUpdate = () => {
    const { roomItems } = this.props
    const { qtysByRoomId } = this.state
    const changedRoomItems = roomItems.filter(roomItem => !qtysByRoomId[roomItem.room.id] || (!qtysByRoomId[roomItem.room.id]?.dirty && qtysByRoomId[roomItem.room.id]?.qty !== roomItem.lineItem.qty))

    if (changedRoomItems.length > 0) {
      this.setState({ qtysByRoomId: { ...qtysByRoomId, ...this.roomItemsToQtyMapping(changedRoomItems) } })
    }
  }

  handleApplyChanges = async () => {
    const { roomItems, updateLineItem, order, t, requestEntity } = this.props
    const { qtysByRoomId } = this.state
    this.setState({ loading: true })
    try {
      await Promise.all(roomItems.map(async (roomItem) => {
        const { qty, dirty } = qtysByRoomId[roomItem.room.id]
        if (dirty || (!roomItem.lineItem.id && roomItem.lineItem.qty > 0)) {
          await updateLineItem({
            ...roomItem.lineItem,
            orderHeader: order.id,
            room: roomItem.product.roomType ? roomItem.room.id : null,
            qty,
          }, false)
          this.setState(prevState => ({
            qtysByRoomId: { ...prevState.qtysByRoomId, [roomItem.room.id]: { qty, dirty: false } },
          }))
        }
      }))
      await requestEntity('orders', order.id)
      this.setState({ error: null, loading: false })
      this.props.goBack()
    } catch (e) {
      console.error(e)
      this.setState({ error: t('orders.applicationToMultipleRoomsFailed'), loading: false })
    } 
  }

  renderRoomItem = (roomItem) => {
    const { qtysByRoomId, loading } = this.state
    const qty = qtysByRoomId[roomItem.room.id]?.qty || 0
    return (
      <div className="OrderProductRooms__RoomItem" key={`${roomItem.room.id}`}>
        <div className="OrderProductRooms__RoomItemName">{roomItem.room.name}</div>
        <ProductQty
          qty={qty}
          unit={roomItem.product.unit}
          loading={loading}
          onQtyChange={this.handleQtyChange(roomItem)}
        />
        <Price className="OrderProductRooms__RoomItemPrice">{roomItem.product.price}</Price>
        <Price className={modifyClassName('OrderProductRooms__RoomItemPrice', { bold: true })}>
          {roomItem.product.price * qty}
        </Price>
      </div>
    )
  }

  render = () => {
    const { qtysByRoomId, error,loading } = this.state
    const { roomItems, t } = this.props
    return (
      <div className="OrderProductRooms">
        <div className="OrderProductRooms__Heading">{t('orders.applyProductToMultipleRooms')}</div>
        <div className="OrderProductRooms__ProductName">{roomItems[0]?.product.title}</div>
        <div className="OrderProductRooms__RoomItems">
          <div className={modifyClassName('OrderProductRooms__RoomItem', { heading: true })}>
            <div className={modifyClassName('OrderProductRooms__RoomItemName', { heading: true })}>
              {t('common.room')}
            </div>
            <div className={modifyClassName('OrderProductRooms__RoomItemPrice', { heading: true })}>
              {t('orders.unitPrice')}
            </div>
            <div className={modifyClassName('OrderProductRooms__RoomItemPrice', { heading: true })}>
              {t('orders.price')}
            </div>
          </div>
          {roomItems.map(this.renderRoomItem)}
        </div>
        <div className="OrderProductRooms__RoomItem">
          <div className="OrderProductRooms__RoomItemName" />
          <Price className={modifyClassName('OrderProductRooms__RoomItemPrice', { bold: true })}>
            {sum(roomItems.map(roomItem => roomItem.product.price * qtysByRoomId[roomItem.room.id]?.qty || 0))}
          </Price>
        </div>
        { error && <div className="OrderProductRooms__Error">{error}</div>}
        <PrimaryButton className="OrderProductRooms__ApplyButton" onClick={this.handleApplyChanges} disabled={loading}>
          {loading && <FontAwesomeIcon icon={['fal', 'spinner']} spin /> }
          {t('buttons.applySelection')}
        </PrimaryButton>
        {!loading && <SimpleButton onClick={() => this.props.goBack()}>{t('buttons.discard')}</SimpleButton>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  productId: getSelectedProductId(state),
  roomItems: OrderSelector.getLineItemsByProductGroup(state),
})

const mapDispatchToProps = {
  updateLineItem: updateLineItemAction,
  goBack,
  requestEntity: requestEntityAction,
}

export default AsyncDataLoadingHOC({
  order: {
    idSelector: getSelectedOrderId,
    entityType: 'orders',
  },
})(connect(mapStateToProps, mapDispatchToProps)(translate()(OrderProductRooms)))
