import { createAction } from 'redux-actions'
import queryString from 'query-string'
import humps from 'humps'

import { requestEntities } from 'api'
import { receiveEntities } from './entities'

export const initViewAction = createAction('INIT_VIEW_ACTION')
export const updateViewAction = createAction('UPDATE_VIEW_ACTION')


const getView = (viewId, state) => state.dataView[viewId]

const fetchEntities = async (viewId, dispatch, getState) => {
  const view = getView(viewId, getState())
  const query = {
    ...view.filters,
    ordering: view.ordering.attribute ? `${view.ordering.asc ? '' : '-'}${humps.decamelize(view.ordering.attribute.replace('.', '__'))}` : undefined,
    page: view.paging.page,
    page_size: view.paging.pageSize,
  }

  try {
    dispatch(updateViewAction({ id: viewId, status: 'FETCHING' }))

    const payload = await requestEntities(view.entityType, humps.decamelizeKeys(query))
    const result = dispatch(receiveEntities({ ...payload, viewId: view.id }))

    dispatch(updateViewAction({
      id: viewId,
      status: 'FETCHED',
      data: {
        count: payload.count,
        ids: payload.results.result,
      },
    }))
    return result
  } catch (e) {
    dispatch(updateViewAction({
      id: viewId,
      status: 'ERROR',
      data: {
        count: 0,
        ids: [],
      },
    }))
    return e
  }
}

export const intializeViewAction = (viewId, entityType, filters) => async (dispatch, getState) => {
  dispatch(initViewAction({ id: viewId, entityType, filters }))
  return fetchEntities(viewId, dispatch, getState)
}

export const changePageAction = (viewId, pageChange) => async (dispatch, getState) => {
  const view = getView(viewId, getState())
  const { paging: { page } } = view
  dispatch(updateViewAction({ id: viewId, paging: { ...view.paging, page: page + pageChange } }))
  return fetchEntities(viewId, dispatch, getState)
}

export const changeSortAction = (viewId, accessor) => async (dispatch, getState) => {
  const view = getView(viewId, getState())
  let { ordering: { attribute, asc } } = view

  if (accessor === attribute) {
    asc = !asc
  } else {
    attribute = accessor
    asc = true
  }
  dispatch(updateViewAction({ id: viewId, ordering: { attribute, asc } }))
  return fetchEntities(viewId, dispatch, getState)
}

export const changeFilterAction = (viewId, filters) => async (dispatch, getState) => {
  const view = getView(viewId, getState())
  dispatch(updateViewAction({ ...view, filters }))
  return fetchEntities(viewId, dispatch, getState)
}
