import * as api from 'api'
import { denormalize } from 'normalizr'

import { userSchema } from 'api/schemas'
import { receiveEntities } from 'actions/entities'

export const createUserAction = user => async (dispatch, getState) => {
  const payload = await api.createUser(user)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, userSchema, getState().entities)
}

export const updateUserAction = user => async (dispatch, getState) => {
  const payload = await api.updateUser(user)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, userSchema, getState().entities)
}

export const requestUsersAction = () => async (dispatch) => {
  const payload = await api.requestEntities('users', '')
  return dispatch(receiveEntities(payload))
}
