import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { deleteEntityAction } from 'actions/entities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.less'

class TableDataViewDeleteButton extends Component {
  static propTypes = {
    entity: PropTypes.shape({}).isRequired,
    entityType: PropTypes.string.isRequired,
    deleteEntity: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  handleDeleteEntity = async (e) => {
    const {
      t,
      deleteEntity,
      entityType,
      entity,
    } = this.props

    e.stopPropagation()

    if (window.confirm(t('buttons.deleteConfirmation'))) {
      await deleteEntity(entityType, entity.id)
    }
  }

  render() {
    return (
      <button
        className="TableDataViewDeleteButton"
        type="button"
        onClick={this.handleDeleteEntity}
      >
        <FontAwesomeIcon icon={['fal', 'trash-alt']} />
      </button>
    )
  }
}

export default connect(null, { deleteEntity: deleteEntityAction })(translate()(TableDataViewDeleteButton))
