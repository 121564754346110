import * as api from 'api'
import { receiveEntities, removeEntity } from 'actions/entities'

/* eslint-disable import/prefer-default-export */
export const requestRoomSizesAction = () => async (dispatch) => {
  const payload = await api.requestEntities('roomSizes', '')
  const result = await dispatch(receiveEntities(payload))
  return result
}

export const createRoomSizeAction = () => async (dispatch) => {
  const payload = await api.createRoomSize()
  const result = await dispatch(receiveEntities({ results: payload }))
  return result
}

export const updateRoomSizeAction = roomsize => async (dispatch) => {
  const payload = await api.updateRoomSize(roomsize)
  const result = await dispatch(receiveEntities({ results: payload }))
  return result
}

export const deleteRoomSizeAction = roomsize => async (dispatch) => {
  await api.deleteRoomSize(roomsize)
  return dispatch(removeEntity({ entityType: 'roomSizes', entityId: roomsize.id }))
}
