import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { dispatchOrderActionAction } from 'actions/orders'

import AssignTechnicianState from 'components/process/AssignTechnicianState'
import ApproveDraftState from 'components/process/ApproveDraftState'
import DecisionState from 'components/process/DecisionState'
import DraftState from 'components/process/DraftState'
import ProcessState from 'components/process/ProcessState'

import CorrectState from 'containers/process/CorrectState'
import { OrderedState, OrderedStateCaseHandler } from 'containers/process/OrderedState'

import { userDisplayName } from 'helper/viewHelper'

import UserSelector from 'selectors/user'

import './style.less'


const approvalConfig = {
  translationKey: 'approvalState',
  yesAction: 'Approve',
  noAction: 'Reject',
}

const actionComponents = {
  Draft: DraftState,
  Ordered: OrderedState,
  AssignTechnician: AssignTechnicianState,
  Correct: CorrectState,
  ReviewOrder: DecisionState(approvalConfig),
  ApproveDraft: ApproveDraftState(approvalConfig),
  OrderedCaseHandler: OrderedStateCaseHandler,
  RequestTechnician: DecisionState({ translationKey: 'requestTechnician' }),
}

class ProcessStateContainer extends Component {

  constructor(props) {
    super(props)
    this.state = { loading: false, error: false }
  }

  handleDispatchAction = async (order, action) => {
    this.setState({ loading: true })
    try {
      await this.props.onDispatchAction(order, action)
      this.setState({ error: false, loading: false })
    } catch (e) {
      this.setState({ error: true, loading: false })
    }
  }

  renderNotAssignedPlaceholder = () => (
    <div className="ProcessStateContainer__Placeholder">
      <div className="ProcessStateContainer__PlaceholderText">
        {this.props.t('process.notAssignedPlaceHolder', { user: userDisplayName(this.props.order.processUser) })}
      </div>
      <ProcessState order={this.props.order} />
    </div>
  )

  renderClosed = () => (
    <div className="ProcessStateContainer__Closed">
      {this.props.t('process.closed', { closedDate: moment(this.props.order.closedDate).format('ll') })}
    </div>
  )

  render = () => {
    const { order, user, t } = this.props
    const { loading, error } = this.state
    if (!order) return null

    if (loading) {
      return <FontAwesomeIcon icon={['fal', 'spinner']} spin />
    }

    if (error) {
      return (
        <div className="ProcessStateContainer__Error">
          {t('errors.processError')}
        </div>
      )
    }

    if (order.processState === 'CLOSED') {
      return this.renderClosed()
    }

    const Action = actionComponents[order.processState]

    if (user.id === order.processUser?.id && Action) {
      return (
        <div className="ProcessStateContainer__Action">
          <Action order={order} onDispatchAction={this.handleDispatchAction} />
        </div>)
    }
    return this.renderNotAssignedPlaceholder()
  }
}


ProcessStateContainer.propTypes = {
  order: PropTypes.shape({}),
  user: PropTypes.shape({}).isRequired,
  onDispatchAction: PropTypes.func.isRequired,
  ...i18nPropTypes,
}

ProcessStateContainer.defaultProps = {
  order: null,
}

const mapStateToProps = state => ({
  user: UserSelector.getCurrentUser(state),
})

const mapDispatchToProps = {
  onDispatchAction: dispatchOrderActionAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessStateContainer))
