import React from 'react'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import './style.less'

const Callback = ({ t }) => (
  <div className="Callback">
    <div className="Callback__Title">
      {t('callback.loading')}
    </div>
  </div>
)

Callback.propTypes = {
  ...i18nPropTypes,
}

export default translate()(Callback)
