import React from 'react'
import { translate } from 'react-i18next'
import { union } from 'lodash'
import './style.less'

const ReportTooltip = ({ active, payload, label, tickFormatter, segments, t }) => {
  if (active && payload && payload.length) {
    const _x = union(payload.map(item => item.dataKey.split('.').at(0)))
    const _y = union(payload.map(item => item.dataKey.split('.').at(1)))
      .reverse()
    const getItem = (x, y) => {
      return payload.find(item => item.dataKey === `${x}.${y}`)
    }

    // if (segments === 'users') {
    //   const data = payload.reduce((acc, user) => {
    //     const key = user.dataKey.split('.').at(0)
    //     const label = user.dataKey.split('.').at(1)
    //     const teamIndex = _x.indexOf(key)
    //     if (!acc[teamIndex]) {
    //       acc[teamIndex] = [{ ...user, label }]
    //     } else {
    //       acc[teamIndex].push({ ...user, label })
    //     }
    //     return acc
    //   }, [])
    //   const max = Math.max(...data.map(team => team.length))
    //   const output = [...Array(max)].map((_, colIndex) => data.map(row => row[colIndex]))

    //   return (
    //     <div className="ReportTooltip">
    //       <div className="ReportTooltip__Label">{label}</div>
    //       <table className="ReportTooltip__Table">
    //         <tbody>
    //           <tr>
    //             {_x.map((x, i) => <td key={`${x}-${i}`} className="">{x}</td>)}
    //           </tr>
    //           {output.map((row, rowIndex) => (
    //             <tr key={rowIndex}>
    //               {row.map((item, colIndex) => (
    //                 <td key={colIndex} className="ReportTooltip__TdData" style={{
    //                   color: item?.fill,
    //                   opacity: item?.opacity || 1,
    //                   textAlign: 'left',
    //                 }}>
    //                   {item && <>
    //                     <span>{item.label}:</span>
    //                     &nbsp;
    //                     <span style={{ float: 'right', marginLeft: '1rem' }}>{item.value}</span>
    //                   </>}
    //                 </td>
    //               ))}
    //             </tr>
    //           ))}
    //         </tbody>
    //       </table>
    //     </div>
    //   )
    // }

    if (_y.length <= 1 && !_y.at(0)) {
      return (
        <div className="ReportTooltip">
          <div className="ReportTooltip__Label">{label}</div>
          <table className="ReportTooltip__Table">
            <tbody>
              <tr>
                {payload.map((team) => <td key={team.name} className="">{team.name}</td>)}
              </tr>
              <tr style={{
                // color: item?.fill,
                // opacity: item?.opacity || 1,
              }}>
                {payload.map((team) => {
                  return (
                    <td key={team.name} className="ReportTooltip__TdData" style={{
                      color: team?.fill,
                      opacity: team?.opacity || 1,
                    }}>
                      {tickFormatter(team?.value)}
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </div >
      )
    }

    return (
      <div className="ReportTooltip">
        <div className="ReportTooltip__Label">{label}</div>
        <table className="ReportTooltip__Table">
          <tbody>
            <tr>
              <td></td>
              {_x.map((x, i) => <td key={`${x}-${i}`} className="">{x}</td>)}
            </tr>
            {_y.map((y, j) => {
              return (
                <tr key={`${y}-${j}`} style={{
                  // color: item?.fill,
                  // opacity: item?.opacity || 1,
                }}>
                  <td className="ReportTooltip__TdLabel">
                    {segments === 'status' ? t(`process.states.${y}`) : y}
                  </td>
                  {_x.map((x, i) => {
                    const item = getItem(x, y)
                    return (
                      <td key={`${x}-${i}`} className="ReportTooltip__TdData" style={{
                        color: item?.fill,
                        opacity: item?.opacity || 1,
                      }}>
                        {tickFormatter(item?.value)}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div >
    )
  }

  return null;
}

export default translate()(ReportTooltip)