import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../Checkbox'

const RFCheckBox = props => (
  <Checkbox
    onChange={props.input.onChange}
    checked={props.input.value === 'true' || props.input.value === true}
    className={props.className}
    {...props}
  />
)

RFCheckBox.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
}

RFCheckBox.defaultProps = {
  className: '',
}

export default RFCheckBox
