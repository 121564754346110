import { handleActions } from 'redux-actions'

import { authChanged, logout } from 'actions/authentication'

const reducer = handleActions({
  [authChanged]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [logout]: () => ({ accessToken: null, idToken: null, expiresAt: null }),
}, { accessToken: null, idToken: null, expiresAt: null })

export default reducer
