import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { requestCatalogsAction } from 'actions/catalogs'
import { values as _values, keys, isObject } from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import {
  reduxForm,
  Field,
  propTypes as reduxPropTypes,
  formValueSelector,
} from 'redux-form'
import { required } from 'helper/validators'
import { currencyToCent } from 'helper/utils'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import UserSelector from 'selectors/user'
import AuthenticationSelector from 'selectors/authentication'

import { updateOrderAction } from 'actions/orders'

import { BottomBar } from 'components/common/ToolBar'
import { PrimaryButton } from 'components/common/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CatalogSelector from 'selectors/catalogs'
import OrderSelector from 'selectors/order'

import { CurrencyField } from 'components/common/RFTextInput'
import RFDropDownInput from 'components/common/RFDropDownInput'
import RFContractorDropDown from 'components/common/RFContractorDropDown'
import RFUserDropDownInput from 'containers/input/RFUserDropDownInput'
import ValueWithLabel from 'components/common/ValueWithLabel'
import RFDatePickerInput from 'containers/input/RFDatePickerInput'
import Price from 'components/common/Price'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

const FORM_NAME = 'ORDER_SUMMARY_FORM'
const ReduxFormSelector = formValueSelector(FORM_NAME)


const neededFields = [
  'id',
  'catalog',
  'inspectionDate',
  'constructionStart',
  'constructionEnd',
  'rentalStart',
  'rentWOrder',
  'rentWoOrder',
  'rentIncentiveDeduction',
  'rentIncentiveOther',
  'rentPreviousTenant',
  'contractor',
  'owner',
  'technician',
  'processUser',
]

class OrderBaseData extends Component {
  static propTypes = {
    catalogs: PropTypes.shape({}).isRequired,
    catalog: PropTypes.shape({}),
    order: PropTypes.shape({}),
    requestCatalogsAction: PropTypes.func.isRequired,
    rentWOrderFromForm: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    rentWoOrderFromForm: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  static defaultProps = {
    order: {},
    rentWOrderFromForm: null,
    rentWoOrderFromForm: null,
    catalog: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.getCatalogs()
  }

  getCatalogs = async () => {
    const { requestCatalogsAction: requestCatalogs } = this.props
    await requestCatalogs()
  }

  convertObjectsToId = (values) => {
    const result = { ...values }
    keys(result).forEach((key) => {
      if (isObject(values[key])) {
        result[key] = result[key].id
      }
    })
    return result
  }

  onSubmit = async (values) => {
    const { updateOrderAction: updateOrder } = this.props
    await this.setState({ loading: true })
    const objectsToIdValues = this.convertObjectsToId(values)
    await updateOrder(objectsToIdValues)
    await this.setState({ loading: false })
  }

  handleSuggestionsFetchRequested = ({ value, reason }) => {
    const { catalogs } = this.props
    let suggestions = null
    if (reason === 'input-changed') {
      suggestions = _values(catalogs)
        .filter(catalog => catalog.name.includes(value))
        .sort((a, b) => b.updatedAt - a.updatedAt)
        .slice(0, 5)
    } else {
      suggestions = _values(catalogs)
        .sort((a, b) => b.created - a.created)
        .slice(0, 5)
    }
    this.setState({ suggestions })
  }

  handleGetSuggestionValue = catalog => catalog?.name || '?'

  validateRentWOrderField = (value, allValues, props) => {
    if (!value) return props.t('errors.required')
    const { rentWoOrder } = allValues
    if (!rentWoOrder) return undefined
    if (currencyToCent(value) < currencyToCent(rentWoOrder)) return props.t('errors.rentWOrderField')
    return undefined
  }

  validateConstructionEndField = (value, allValues, props) => {
    if (!value) return undefined
    const { constructionStart } = allValues
    if (!constructionStart) return undefined
    if (moment(constructionStart) < moment(value)) return undefined
    return props.t('errors.wrongDateOrder')
  }

  validateRentalStartField = (value, allValues, props) => {
    if (!value) return undefined
    const { constructionStart, constructionEnd } = allValues
    if (!constructionStart && !constructionEnd) return undefined
    const startDate = constructionStart ? moment(constructionStart) : null
    const endDate = constructionEnd ? moment(constructionEnd) : null
    const rentalDate = moment(value)
    if ((rentalDate > startDate) && (rentalDate > endDate)) return undefined
    return props.t('errors.wrongDateOrder')
  }

  render() {
    const {
      t,
      handleSubmit,
      order = {},
      dirty,
      rentWOrderFromForm,
      rentWoOrderFromForm,
      catalog,
      users,
      activeTenant,
    } = this.props

    const { loading } = this.state

    const isWbs = order?.apartment?.isWbs
    const size = order?.apartment?.size || 0.0

    const rentWOrder = rentWOrderFromForm || 0
    const rentWoOrder = rentWoOrderFromForm || 0

    const { suggestions } = this.state

    const { isAdmin } = activeTenant

    return (
      <div className="OrderBaseData">
        <form className="OrderBaseData__Form" onSubmit={handleSubmit(this.onSubmit)}>
          <ScrollContainer className="OrderBaseData__FormFields" scrollVertical>


            <div className="OrderBaseData__FieldsColumn OrderBaseData__General">
              <div className="OrderBaseData__Label">
                {t('orderSummary.basedata.overview')}
              </div>

              <div className="OrderBaseData__FieldsRow">
                <div className="OrderBaseData__FieldsRow OrderBaseData__50 OrderBaseData__paddingRight">
                  <Field className="OrderBaseData__Field" name="inspectionDate" component={RFDatePickerInput} label={t('orderSummary.basedata.fields.inspectionDate')} />
                  <ValueWithLabel className="OrderBaseData__Field" label={t('orderSummary.basedata.fields.specials')}>
                    {isWbs ? t('orderSummary.basedata.fields.isWBS') : '-'}
                  </ValueWithLabel>
                </div>
                <div className="OrderBaseData__FieldColumns OrderBaseData__50" />
              </div>

            </div>

            <div className="OrderBaseData__FieldsColumn OrderBaseData__General">
              <div className="OrderBaseData__Label">
                {t('orderSummary.basedata.persons')}
              </div>

              <div className="OrderBaseData__FieldsRow">
                {!isAdmin && (
                  <ValueWithLabel className="OrderBaseData__Field" label={t('orderSummary.basedata.fields.owner')}>
                    {t('orderSummary.basedata.fields.owner')}
                  </ValueWithLabel>
                )}

                {isAdmin && (
                  <Field className="OrderBaseData__Field" name="owner" component={RFUserDropDownInput} users={users} label={t('orderSummary.basedata.fields.owner')} disabled="true" />
                )}
                <Field className="OrderBaseData__Field" name="technician" component={RFUserDropDownInput} users={users} label={t('orderSummary.basedata.fields.technician')} />
                <Field className="OrderBaseData__Field" name="processUser" component={RFUserDropDownInput} users={users} label={t('orderSummary.basedata.fields.processUser')} />
              </div>
            </div>


            <div className="OrderBaseData__FieldsColumn OrderBaseData__Planing">
              <div className="OrderBaseData__Label">
                {t('orderSummary.basedata.planing')}
              </div>

              <div className="OrderBaseData__FieldsRow">
                <Field className="OrderBaseData__Field OrderBaseData__Field--noMarginBottom" name="constructionStart" component={RFDatePickerInput} label={t('orderSummary.basedata.fields.constructionStart')} />
                <Field className="OrderBaseData__Field OrderBaseData__Field--noMarginBottom" name="constructionEnd" validate={this.validateConstructionEndField} component={RFDatePickerInput} label={t('orderSummary.basedata.fields.constructionEnd')} />
                <Field className="OrderBaseData__Field OrderBaseData__Field--noMarginBottom" name="rentalStart" validate={this.validateRentalStartField} component={RFDatePickerInput} label={t('orderSummary.basedata.fields.rentalStart')} />
              </div>

              <div className="OrderBaseData__FieldsRow">
                {!isAdmin && (
                  <ValueWithLabel className="OrderBaseData__Field" label={t('orderSummary.basedata.fields.catalog')}>
                    {catalog?.name}
                  </ValueWithLabel>
                )}

                {isAdmin && (
                  <Field
                    className="OrderBaseData__Field OrderBaseData__Field--noMarginTop"
                    name="catalog"
                    component={RFDropDownInput}
                    label={t('orderSummary.basedata.fields.catalog')}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    getSuggestionValue={this.handleGetSuggestionValue}
                  />
                )}
                <Field
                  className="OrderBaseData__Field OrderBaseData__Field--noMarginTop"
                  name="contractor"
                  component={RFContractorDropDown}
                  label={t('orderSummary.basedata.fields.contractor')}
                  catalog={catalog}
                />
              </div>

            </div>


            <div className="OrderBaseData__FieldsRow OrderBaseData__LastRow">
              <div className="OrderBaseData__FieldsColumn OrderBaseData__50 OrderBaseData__Rent">
                <div className="OrderBaseData__Label">
                  {t('orderSummary.basedata.rentAfter')}
                </div>

                <div className="OrderBaseData__FieldsRow">
                  <div className="OrderBaseData__Field">
                    <Field name="rentWOrder" component={CurrencyField} validate={this.validateRentWOrderField} label={t('orderSummary.basedata.fields.rentAmount')} numberFormat="0.0000" decimalPlaces={2} />
                  </div>
                  <div className="OrderBaseData__Field">
                    <ValueWithLabel className="OrderBaseData__ValueWithLabel" label={t('orderSummary.basedata.fields.rent')}>
                      <Price>{rentWOrder * size}</Price>
                    </ValueWithLabel>
                  </div>
                </div>
                <Field className="OrderBaseData__Field" name="rentIncentiveDeduction" component={CurrencyField} label={t('orderSummary.basedata.fields.rentIncentiveDeduction')} />
                <Field className="OrderBaseData__Field" name="rentIncentiveOther" component={CurrencyField} label={t('orderSummary.basedata.fields.rentIncentiveOther')} />
              </div>

              <div className="OrderBaseData__FieldsColumn OrderBaseData__50">
                <div className="OrderBaseData__Label">
                  {t('orderSummary.basedata.rentBefore')}
                </div>
                <div className="OrderBaseData__FieldsRow">
                  <div className=" OrderBaseData__Field">
                    <Field name="rentWoOrder" component={CurrencyField} validate={required} label={t('orderSummary.basedata.fields.rentAmount')} numberFormat="0.0000" decimalPlaces={2} />
                  </div>
                  <div className=" OrderBaseData__Field">
                    <ValueWithLabel className="OrderBaseData__ValueWithLabel" label={t('orderSummary.basedata.fields.rent')}>
                      <Price>{rentWoOrder * size}</Price>
                    </ValueWithLabel>
                  </div>
                </div>
                <Field className="OrderBaseData__Field" name="rentPreviousTenant" component={CurrencyField} label={t('orderSummary.basedata.fields.rentPreviousTenant')} />
              </div>
            </div>


          </ScrollContainer>
          <BottomBar borderLineModifier="top" containerClassName="OrderBaseData__Footer">
            <PrimaryButton className="OrderBaseData__SubmitButton" type="submit" disabled={!dirty} loading={loading}>
              {loading ? <FontAwesomeIcon className="OrderBaseData__Spinner" icon={['fal', 'spinner']} spin /> : t('buttons.save')}
            </PrimaryButton>
          </BottomBar>
        </form>
      </div>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: FORM_NAME,
}

const stripToNeededFields = (order) => {
  const result = {}
  keys(order).forEach((key) => {
    if (neededFields.indexOf(key) > -1) result[key] = order[key]
  })
  return result
}

const mapStateToProps = state => ({
  catalogs: CatalogSelector.getCatalogs(state),
  order: OrderSelector.getSelectedOrder(state),
  initialValues: stripToNeededFields(OrderSelector.getSelectedOrder(state)),
  rentWOrderFromForm: ReduxFormSelector(state, 'rentWOrder'),
  rentWoOrderFromForm: ReduxFormSelector(state, 'rentWoOrder'),
  catalog: ReduxFormSelector(state, 'catalog'),
  activeTenant: AuthenticationSelector.getActiveTenant(state),
})

const formComponent = reduxForm(form)(OrderBaseData)
const connectedComponent = connect(mapStateToProps, { requestCatalogsAction, updateOrderAction })(formComponent)
const translatedComponent = translate()(connectedComponent)

export default AsyncDataLoadingHOC({
  users: {
    entitySelector: UserSelector.getUsersAsArray,
    entityType: 'users',
  },
})(translate()(translatedComponent))
