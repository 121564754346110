import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import RFDropDownInput from 'components/common/RFDropDownInput'

class RFProductDropDown extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    onChange: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    initialProduct: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  static defaultProps = {
    className: '',
    input: {
      onChange: () => {},
    },
    meta: {},
    onChange: () => {},
    initialProduct: null,
  }

  onSuggestionsFetchRequested = () => { }

  getSuggestionValue = product => product?.grade?.label || product?.title

  render = () => {
    const { products, initialProduct } = this.props
    return (
      <RFDropDownInput
        {...this.props}
        isLoading={false}
        suggestions={products}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        getSuggestionValue={this.getSuggestionValue}
      />
    )
  }
}

export default translate()(RFProductDropDown)
