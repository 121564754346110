import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AutoSuggest from 'react-autosuggest'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

class RFDropDownInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    suggestions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    getSuggestionValue: PropTypes.func.isRequired,
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    isLoading: PropTypes.bool,
    searchable: PropTypes.bool,
    displaySuggestionsOnTop: PropTypes.bool,
    ...i18nPropTypes,
  }

  static defaultProps = {
    className: '',
    input: {
      onChange: () => {},
    },
    meta: {},
    onChange: () => {},
    suggestions: {},
    isLoading: false,
    value: null,
    searchable: true,
    displaySuggestionsOnTop: false,
  }

  relatedClassNames = [
    'RFDropDownInput',
    'autosuggest',
    'suggestion',
  ]

  constructor(props, context) {
    super(props, context)

    this.state = {
      displayAutoSuggest: false,
      value: props.initialValue,
      query: '',
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  UNSAFE_componentWillReceiveProps = ({ formValue }) => {
    if (this.props.formValue !== formValue) {
      this.props.onSuggestionsFetchRequested({
        value: this.state.value,
        reason: 'form-update',
      })
    }
  }

  shouldCloseAutoSuggest = (className) => {
    const matches = this.relatedClassNames.filter((relatedClassName) => {
      if (className.includes(relatedClassName)) {
        return relatedClassName
      }
      return null
    })
    return matches.length === 0
  }

  handleClickOutside = (event) => {
    const { displayAutoSuggest } = this.state
    if (this.shouldCloseAutoSuggest(event.target?.className) && displayAutoSuggest) {
      this.setState({
        displayAutoSuggest: false,
      })
    }
  }

  handleDisplayAutoSuggest = () => {
    const { displayAutoSuggest } = this.state
    this.setState({
      displayAutoSuggest: !displayAutoSuggest,
    })
  }

  handleOnChange = (e) => {
    this.setState({ query: e.target.value })
  }

  handleKeyPress = (e) => {
    if (e.charCode === 13 && this.props.onCreateSelected) {
      e.preventDefault()
      this.props.onCreateSelected(this.state.value)
      this.setState({ value: null })
    }
  }

  handleSelection = (event, { suggestion }) => {
    event.stopPropagation()
    event.preventDefault()
    const { input, onChange } = this.props
    input.onChange(suggestion)
    onChange(suggestion)
    this.handleDisplayAutoSuggest()
  }

  renderButton = () => {
    const {
      label,
      input,
      isLoading,
      value: propsValue,
    } = this.props

    const {
      value: stateValue,
    } = this.state

    const { value: inputValue } = input
    const value = stateValue || propsValue || inputValue
    return (
      <button type="button" className="RFDropDownInput__Button" onClick={this.handleDisplayAutoSuggest} disabled={isLoading}>
        <div className={modifyClassName('RFDropDownInput__Label', { hasValue: value })}>{label}</div>
        {value && this.props.getSuggestionValue(value)}
        {isLoading && <FontAwesomeIcon className="RFDropDownInput__Spinner" icon={['fal', 'spinner']} spin />}
      </button>)
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => (
    <div {... containerProps}>
      {this.props.renderHeader && this.props.renderHeader({ query })}
      {children}
    </div>
  )

  renderSuggestion = suggestion => (
    <button
      type="button"
      className="suggestion__create"
      onClick={() => {}}
    >
      {this.props.getSuggestionValue(suggestion)}
    </button>
  )

  renderAutoSuggest = () => {
    const {
      suggestions,
      onSuggestionsFetchRequested,
      getSuggestionValue,
      searchable,
      input,
      value: propsValue,
      t,
    } = this.props

    const { value } = input
    const valueToUse = propsValue || value

    const { query } = this.state

    const inputProps = {
      placeholder: searchable ? t('common.search') : getSuggestionValue(valueToUse),
      autoFocus: true,
      onBlur: this.handleDisplayAutoSuggest,
      onChange: this.handleOnChange,
      onKeyPress: this.handleKeyPress,
      value: query || '',
      disabled: !searchable,
    }

    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        renderSectionTitle={this.renderSectionTitle}
        onSuggestionSelected={this.handleSelection}
        inputProps={inputProps}
        shouldRenderSuggestions={() => false}
        alwaysRenderSuggestions
      />)
  }

  render = () => {
    const { displayAutoSuggest } = this.state
    const { displaySuggestionsOnTop, meta } = this.props
    const { error, touched } = meta
    const { className } = this.props
    return (
      <div className={`${modifyClassName('RFDropDownInput', { displayAutoSuggest, displaySuggestionsOnTop })} ${className}`}>
        { displayAutoSuggest ? this.renderAutoSuggest() : this.renderButton() }
        {(touched && error) && <div className="RFDropDownInput__FieldError">{error}</div>}
      </div>
    )
  }
}

export default translate()(RFDropDownInput)
