import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { connect } from 'react-redux'

import OrderLineItemDetail from 'components/order/OrderLineItemDetail'
import Product from 'components/order/Product'

import modifyClassName from 'helper/modifyClassName'

import { updateLineItemAction } from 'actions/orders'
import { requestEntityAction } from 'actions/entities'
import ApartmentSelector from 'selectors/apartment'
import OrderSelector from 'selectors/order'


import './style.less'

class OrderLineItemProductGroup extends Component {
  static propTypes = {
    ...i18nPropTypes,
    productGroup: PropTypes.shape({}).isRequired,
    room: PropTypes.shape({}).isRequired,
    onSelectProduct: PropTypes.func.isRequired,
    updateLineItem: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    orderId: PropTypes.string.isRequired,
    isInOverview: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    roomSizeMissing: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    isInOverview: false,
    isReadOnly: false,
  }

  constructor(props) {
    super(props)
    this.state = { product: null }
  }

  componentDidUpdate = (prevProps) => {
    const { room } = this.props
    if (prevProps.room?.id !== room?.id) {
      this.setState({ product: null })
    }
  }

  handleSelectProduct = () => {
    const { onSelectProduct, productGroup } = this.props
    onSelectProduct(productGroup.productGroupKey)
  }

  handleChangeProduct = (product) => {
    const {
      updateLineItem,
      room,
      orderId,
      productGroup,
    } = this.props

    this.setState({ product })
    if (productGroup?.lineItem) {
      updateLineItem({
        ...productGroup?.lineItem,
        room: product.roomType ? room.id : null,
        orderHeader: orderId,
        product: product.id,
      })
    }
  }

  handleUpdateLineItem = async (lineItem, requestOrder = true) => {
    const {
      updateLineItem,
      room,
      orderId,
      productGroup,
      requestEntity,
    } = this.props
    const { product: stateProduct } = this.state

    const product = productGroup.products.find(p => p.id === lineItem.product)
      || stateProduct
      || productGroup.products[0]

    await updateLineItem({
      ...lineItem,
      room: product.roomType ? room.id : null,
      orderHeader: orderId,
      product: product?.id,
    }, requestOrder)
    await requestEntity('orders', orderId)
    this.setState({ product })
  }

  render = () => {
    const {
      productGroup,
      expanded,
      apartment,
      room,
      isInOverview,
      isReadOnly,
      roomSizeMissing,
      roomType,
    } = this.props

    const { product: stateProduct } = this.state
    const lineItem = productGroup?.lineItem
    const product = stateProduct || productGroup.lineItemProduct || productGroup.products[0]
    return (
      <div className={modifyClassName('OrderLineItemProductGroup', { expanded })}>
        <Product
          product={product}
          craftName={productGroup?.lineItemProduct?.craft}
          expanded={expanded}
          onSelect={this.handleSelectProduct}
          lineItem={lineItem}
          isInOverview={isInOverview}
        />
        { expanded && (
          <OrderLineItemDetail
            onUpdateLineItem={this.handleUpdateLineItem}
            apartment={apartment}
            room={room}
            roomType={roomType}
            product={product}
            allProducts={productGroup.products}
            lineItem={lineItem}
            onChangeProduct={this.handleChangeProduct}
            isInOverview={isInOverview}
            isReadOnly={isReadOnly}
            roomSizeMissing={roomSizeMissing}
          />)}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isReadOnly: OrderSelector.isReadOnly(state),
  roomSizeMissing: OrderSelector.anyRoomSizeMissing(state),
  apartment: ApartmentSelector.getSelectedApartmentFromOrderId(state),
  roomType: OrderSelector.resolveSelectedRoomType(state),
})

const mapDispatchToProps = {
  updateLineItem: updateLineItemAction,
  requestEntity: requestEntityAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrderLineItemProductGroup))
