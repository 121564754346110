
import { handleActions } from 'redux-actions'

import { userMeChanged, activeTenantChanged } from 'actions/user'

const reducer = handleActions({
  [userMeChanged]: (state, action) => ({
    ...state,
    current: action.payload,
  }),
  [activeTenantChanged]: (state, action) => ({
    ...state,
    activeTenant: action.payload,
  }),
  LOAD_LOCALSTORAGE: (state, action) => ({
    ...state,
    activeTenant: action.payload.activeTenant || null,
  }),
}, { current: null, activeTenant: null })

export default reducer
