import React from 'react'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const ApartmentRoomSizeItem = ({
  label,
  value,
  selected,
  onClick,
}) => (
  <button type="button" onClick={() => onClick(value)} className={modifyClassName('ApartmentRoomSizeItem', { selected })}>{label}</button>
)

ApartmentRoomSizeItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ApartmentRoomSizeItem
