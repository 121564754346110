import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ApartmentRoomSizeItem from 'components/apartment/ApartmentRoomSizeItem'
import { values as _values } from 'lodash'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import './style.less'

class RFApartmentRoomSizes extends Component {
  static propTypes = {
    room: PropTypes.shape({}).isRequired,
    roomSizes: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    input: PropTypes.shape({}).isRequired,
    meta: PropTypes.shape({}).isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    roomSizes: {},
    isLoading: false,
  }

  handleSizeItemClick = (roomSizeId) => {
    const { input } = this.props
    const { onChange } = input
    onChange(roomSizeId)
  }

  render() {
    const {
      room,
      roomSizes,
      input,
      isLoading,
    } = this.props
    const { value } = input
    return (
      <div className="RFApartmentRoomSizes">
        <div className="RFApartmentRoomSizes__Buttons">
          {isLoading && <FontAwesomeIcon className="RFApartmentRoomSizes__Spinner" icon={['fal', 'spinner']} spin />}
          {!isLoading && _values(roomSizes).map(roomSize => (
            <ApartmentRoomSizeItem
              key={`${String(room.id)}_${roomSize.id}`}
              label={roomSize.label}
              value={String(roomSize.id)}
              onClick={this.handleSizeItemClick}
              selected={String(roomSize.id) === String(value)}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default translate()(RFApartmentRoomSizes)
