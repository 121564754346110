
import { createAction } from 'redux-actions'
import * as api from 'api'
import connector from 'api/connector'

export const startUploading = createAction('START_UPLOADING')
export const receivedUploadResponse = createAction('RECEIVED_UPLOAD_RESPONSE')
export const uploadError = createAction('UPLOAD_ERROR')

export const importApartments = file => async (dispatch) => {
  dispatch(startUploading())

  try {
    const urlPayload = await api.requestUploadUrl('apartment')
    const { data: { url, key } } = urlPayload

    await connector.uploadToS3(url, file)
    const payload = await api.getImportedData(key)
    await dispatch(receivedUploadResponse(payload))
  } catch (error) {
    dispatch(uploadError())
    throw error
  }
}

export const importCatalog = (file, filename) => async (dispatch) => {
  dispatch(startUploading())

  try {
    const urlPayload = await api.requestUploadUrl('catalog')
    const { data: { url, key } } = urlPayload

    await connector.uploadToS3(url, file)
    const payload = await api.createCatalog(key, filename)
    await dispatch(receivedUploadResponse(payload))
  } catch (error) {
    dispatch(uploadError())
    throw error
  }
}
