import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import {
  reduxForm,
  Field,
  propTypes as reduxPropTypes,
} from 'redux-form'

import { PrimaryButton } from 'components/common/Button'
import { Title } from 'components/process/ProcessTitle'

import { RFUserDropDownInputWithHOC } from 'containers/input/RFUserDropDownInput'

import './style.less'

const FORM_NAME = 'ASSIGN_TECHNICIAN_FORM'

class AssignTechnicianState extends Component {
  static propTypes = {
    order: PropTypes.shape({}),
    onDispatchAction: PropTypes.func.isRequired,
    ...i18nPropTypes,
    ...reduxPropTypes,
  }

  static defaultProps = {
    order: null,
  }

  handleSubmitAction = async (values) => {
    const { order, onDispatchAction } = this.props
    onDispatchAction(order, { action: 'Assign', data: { userId: values.assignUser.id } })
  }

  render = () => (
    <>
      <Title>{this.props.t('process.assignTechnicianTitle')}</Title>
      <form className="AssignTechnicianState" onSubmit={this.props.handleSubmit(this.handleSubmitAction)}>
        <Field
          className="AssignTechnicianState__Field"
          name="assignUser"
          component={RFUserDropDownInputWithHOC}
          label={this.props.t('roles.technician')}
          displaySuggestionsOnTop
        />

        <PrimaryButton type="submit">
          {this.props.t('process.assignTechnician')}
        </PrimaryButton>
      </form>
    </>
  )
}

const form = {
  enableReinitialize: true,
  form: FORM_NAME,
}

export default reduxForm(form)(translate()(AssignTechnicianState))
