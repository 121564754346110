import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'
import { values as _values } from 'lodash'

import { getSelectedContractorId } from 'selectors/navigation'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'

import ContractorForm from 'containers/form/ContractorForm'
import { TopBar } from 'components/common/ToolBar'
import BackButton from 'components/common/BackButton'

import './style.less'


const createFormModel = (contractor) => {
  const result = {
    ...contractor,
    catalogs: {},
    tenant: contractor?.tenant?.id,
  }

  _values(contractor?.catalogs).forEach((catalog) => {
    result.catalogs[`${catalog.id}X`] = { isChecked: true, indexValue: catalog.index }
  })

  return result
}

const SettingsContractorDetail = ({ handleBack, contractor }) => (
  <div className="SettingsContractorDetail">
    <TopBar>
      <BackButton onClick={handleBack} />
    </TopBar>
    <ContractorForm initialValues={createFormModel(contractor)} onSuccess={handleBack} />
  </div>
)

SettingsContractorDetail.propTypes = {
  contractor: PropTypes.shape({}),
  handleBack: PropTypes.func.isRequired,
  ...i18nPropTypes,
}

SettingsContractorDetail.defaultProps = {
  contractor: {},
}

const mapDispatchToProps = dispatch => ({
  handleBack: () => dispatch(push('/settings/contractors')),
})

export const SettingsContractorDetailCreate = connect(null, mapDispatchToProps)(translate()(SettingsContractorDetail))

export const SettingsContractorDetailEdit = AsyncDataLoadingHOC({
  contractor: {
    idSelector: getSelectedContractorId,
    entityType: 'contractors',
  },
})(SettingsContractorDetailCreate)
