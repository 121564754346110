import React from 'react'
import { Field } from 'redux-form'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import RFTextInput from 'components/common/RFTextInput'
import RFCheckBox from 'components/common/RFCheckBox'

import './style.less'

const SharedUserFormFields = ({ t }) => (
  <div className="SharedUserFormFields">
    <Field name="firstName" className="SharedUserFormFields__Field" component={RFTextInput} label={t('table.firstName')} />
    <Field name="lastName" className="SharedUserFormFields__Field" component={RFTextInput} label={t('table.lastName')} />
    <Field name="isTenantAdmin" className="SharedUserFormFields__Field" component={RFCheckBox} label={t('table.isTenantAdmin')} />
    <Field name="isTenantAnalyst" className="SharedUserFormFields__Field" component={RFCheckBox} label={t('table.isTenantAnalyst')} />
  </div>
)

SharedUserFormFields.propTypes = {
  ...i18nPropTypes,
}

export default translate()(SharedUserFormFields)
