import * as api from 'api'
import { denormalize } from 'normalizr'

import { roomSchema } from 'api/schemas'
import { receiveEntities, removeEntity } from 'actions/entities'

export const createRoomAction = room => async (dispatch, getState) => {
  const payload = await api.createRoom(room)
  const apartment = getState()?.entities?.apartments[room.apartment]
  apartment.rooms.push(payload.result)
  const apartmentId = apartment.id
  payload.entities.apartments = { [apartmentId]: apartment }
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, roomSchema, getState().entities)
}

export const updateRoomAction = room => async (dispatch, getState) => {
  const payload = await api.updateRoom(room)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, roomSchema, getState().entities)
}

export const deleteRoomAction = room => async (dispatch, getState) => {
  await api.deleteRoom(room)
  const payload = {}
  dispatch(removeEntity({ entityType: 'rooms', entityId: room.id }))
  const apartment = getState()?.entities?.apartments[room.apartment]
  apartment.rooms = apartment.rooms.filter(roomId => roomId !== room.id)
  const apartmentId = apartment.id
  payload.entities = {}
  payload.entities.apartments = { [apartmentId]: apartment }
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, roomSchema, getState().entities)
}
