import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import RoomSizeSelectionForm from 'containers/form/RoomSizeSelectionForm'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import ApartmentSelector from 'selectors/apartment'
import { sortRooms } from 'helper/dataHelper'

import './style.less'

const OrderRoomSizeSelection = ({ apartment }) => {
  // TODO: not the best idea
  const sortedApartment = { ...apartment, rooms: sortRooms(apartment.rooms) }
  return (
    <div className="OrderRoomSizeSelection">
      <RoomSizeSelectionForm apartment={sortedApartment} initialValues={sortedApartment} />
    </div>
  )
}

OrderRoomSizeSelection.propTypes = {
  apartment: PropTypes.shape({}).isRequired,
  ...i18nPropTypes,
}

export default AsyncDataLoadingHOC({
  apartment: {
    entitySelector: ApartmentSelector.getSelectedApartmentFromOrderId,
    entityType: 'apartments',
  },
})(translate()(OrderRoomSizeSelection))
