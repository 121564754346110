/* global window */
import Raven from 'raven-js'

import createRavenMiddleware from 'raven-for-redux'

const RAVEN_ID = 'https://f73b24c51e7a442ca1b7fe9f5a17a7a8@sentry.io/1274467'

const MIDDLEWARE_WITH_RAVEN = () => next => (action) => {
  const { type, payload } = action

  if (type === 'LOGOUT' || (type === 'CHANGE_AUTH' && payload === 'NOT_AUTHENTICATED')) {
    (async () => {
      Raven.setUserContext({})
    })()
  } else if (type === 'CHANGE_AUTH' && payload === 'AUTHENTICATED') {
    (async () => {
      // const user = await cognitoGetUser()
      // Raven.setUserContext({ email: user.username })
    })()
  }

  try {
    return next(action)
  } catch (err) {
    Raven.captureException(err)
    throw err
  }
}

const MIDDLEWARE_ERROR_LOG = () => next => (action) => {
  try {
    return next(action)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    throw err
  }
}

if (process.env.NODE_ENV === 'production') {
  Raven.config(RAVEN_ID, {
    maxBreadcrumbs: 20,
    release: process.env.CI_PIPELINE_ID,
    environment: process.env.CI_ENVIRONMENT_NAME,
  }).install()
}


class RavenIntegration {
  constructor() {
    this.isProduction = process.env.NODE_ENV === 'production'

    if (this.isProduction) {
      window.onunhandledrejection = (evt) => {
        Raven.captureException(evt.reason)
      }
    }
  }

  getMiddlewares() {
    return this.isProduction ? [
      MIDDLEWARE_WITH_RAVEN,
      createRavenMiddleware(Raven, {
        stateTransformer: (state) => {
          // eslint-disable-next-line no-unused-vars
          const { entities, reference, notePaging, tag, ...newState } = state
          return newState
        },
      }),
    ] : [
      MIDDLEWARE_ERROR_LOG,
    ]
  }

  throwError(err) {
    if (this.isProduction) {
      Raven.captureException(err)
    }
  }
}

export default new RavenIntegration()
