import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { mobileVendor } from 'react-device-detect'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

class ScrollContainer extends Component {
  targetRef = React.createRef()

  static propTypes = {
    scrollVertical: PropTypes.bool,
    scrollHorizontal: PropTypes.bool,
    scrollToTarget: PropTypes.string,
    onScrollToTarget: PropTypes.func,
    children: PropTypes.node,
  }

  static defaultProps = {
    scrollVertical: false,
    scrollHorizontal: false,
    scrollToTarget: null,
    children: {},
  }

  getPlatformSpecificScrollCSS = () => (mobileVendor === 'iPad' ? 'scroll' : 'auto')

  getStyles = (scrollVertical, scrollHorizontal) => {
    const overflowY = scrollVertical ? this.getPlatformSpecificScrollCSS() : 'hidden'
    const overflowX = scrollHorizontal ? this.getPlatformSpecificScrollCSS() : 'hidden'
    return {
      overflowX,
      overflowY,
      WebkitOverflowScrolling: 'touch',
    }
  }

  componentDidMount = () => {
    disableBodyScroll(this.targetRef.current)
    setTimeout(() => {
      if (this.props.scrollToTarget && this.targetRef.current) {
        const containerOffsetTop = this.targetRef.current.offsetTop
        const elementOffsetTop = document.getElementById(this.props.scrollToTarget).offsetTop
        this.targetRef.current.scrollTop = elementOffsetTop - containerOffsetTop
        if (this.props.onScrollToTarget) {
          this.props.onScrollToTarget()
        }
      }
    }, 0)
  }

  componentWillUnmount = () => {
    clearAllBodyScrollLocks()
  }

  render() {
    const { scrollVertical, scrollHorizontal, scrollToTarget, onScrollToTarget, ...rest } = this.props
    return (
      <div {...rest} style={this.getStyles(scrollVertical, scrollHorizontal)} ref={this.targetRef}>
        {rest.children}
      </div>
    )
  }
}

export default ScrollContainer
