import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { connect } from 'react-redux'

import SideMenuHeader from 'components/common/SideMenuHeader'
import SideMenuButton from 'components/common/SideMenuButton'

import { splitAddress } from 'helper/viewHelper'

import ApartmentSelector from 'selectors/apartment'
import { getSelectedApartmentId } from 'selectors/navigation'

import './style.less'

const PATH_OVERVIEW = id => `/apartments/${id}`
const PATH_ROOMS = id => `/apartments/${id}/rooms`
const PATH_ORDERS = id => `/apartments/${id}/orders`

/* eslint-disable react/prefer-stateless-function */
class ApartmentDetailSideMenu extends Component {
  static propTypes = {
    apartmentId: PropTypes.string.isRequired,
    apartment: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  static defaultProps = {
    apartment: null,
  }

  render() {
    const { t, apartmentId, apartment } = this.props
    if (!apartment) return null

    return (
      <div className="ApartmentDetailSideMenu">
        <SideMenuHeader
          infoTitle={apartment.externalId || ''}
          infos={splitAddress(apartment.address)}
          goBackTo="/apartments"
        />
        <div className="ApartmentDetailSideMenu__Navigation">
          <SideMenuButton
            to={PATH_OVERVIEW(apartmentId)}
            icon="sliders-h"
          >
            {t('navigation.overview')}
          </SideMenuButton>
          <SideMenuButton
            to={PATH_ROOMS(apartmentId)}
            icon="cubes"
          >
            {t('navigation.rooms')}
          </SideMenuButton>
          <SideMenuButton
            to={PATH_ORDERS(apartmentId)}
            icon="clipboard-list"
          >
            {t('navigation.orders')}
          </SideMenuButton>
        </div>
        <div className="ApartmentDetailSideMenu__Footer" />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  apartmentId: getSelectedApartmentId(state),
  apartment: ApartmentSelector.getCurrentSelectedApartment(state),
})

export default connect(mapStateToProps, null)(translate()(ApartmentDetailSideMenu))
