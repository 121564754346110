import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import Price from 'components/common/Price'

import './style.less'


const PriceSummary = ({ requiredAmount, overallAmount, t }) => (
  <div className="PriceSummary">
    <div className="PriceSummary__CraftAmount">
      <div className="PriceSummary__Amount">
        <Price>{requiredAmount}</Price>
      </div>
      <div className="PriceSummary__AmountClass">
        {t('orderSummary.list.isSowieso')}
      </div>
    </div>
    <div className="PriceSummary__CraftAmount">
      <div className="PriceSummary__Amount">
        <Price>{overallAmount}</Price>
      </div>
      <div className="PriceSummary__AmountClass">
        {t('orderSummary.list.overall')}
      </div>
    </div>
  </div>
)

PriceSummary.propTypes = {
  overallAmount: PropTypes.number,
  requiredAmount: PropTypes.number,
  ...i18nPropTypes,
}

PriceSummary.defaultProps = {
  overallAmount: 0,
  requiredAmount: 0,
}

export default translate()(PriceSummary)
