import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push as pushAction } from 'connected-react-router'
import BackButton from 'components/common/BackButton'

import { createOrderAction } from 'actions/orders'

import SearchField from 'components/common/SearchField'

import TableDataView from 'containers/common/TableDataView'
import Modal from 'containers/modal/Modal'

import './style.less'

const VIEW_ID = 'SELECT_APARTMENTS'
const ENTITY_TYPE = 'apartments'

class SelectApartmentModal extends Component {
  static propTypes = {
    ...i18nPropTypes,
    push: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    createOrder: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
      isCreating: false,
    }
  }

  handleSelectEntity = async (apartment) => {
    const { createOrder, push, hide, t } = this.props
    const { isCreating } = this.state
    if (apartment && !isCreating) {
      this.setState({ isCreating: true })
      try {
        const order = await createOrder(apartment)
        push(`/orders/${order.id}`)
        hide()
      } catch (e) {
        if (e.status === 409 && e.data?.order_id) {
          if (confirm(t('orders.existingOrderError'))) {
            push(`/orders/${e.data?.order_id}`)
            hide()
          }
        } else {
          throw e
        }
      } finally {
        this.setState({ isCreating: false })
      }
    }
  }

  handleOnSubmit = (value) => {
    this.setState({
      searchValue: value,
    })
  }

  render = () => {
    const { t, hide } = this.props
    const { searchValue } = this.state

    const columns = [
      { header: t('table.address'), accessor: 'address' },
      { header: t('table.externalId'), accessor: 'externalId' },
      { header: t('table.numberOfRooms'), accessor: 'numberOfRooms' },
      { header: t('table.size'), accessor: 'size' },
    ]

    return (
      <div className="SelectApartmentModal">
        <div className="SelectApartmentModal__Header">
          <div className="SelectApartmentModal__TitleBar">
            <div className="SelectApartmentModal__BackButton">
              <BackButton onClick={hide} />
            </div>
            <div className="SelectApartmentModal__HeaderMiddle">
              <div className="SelectApartmentModal__Title">
                {t('apartment.selectApartment')}
              </div>
              <SearchField className="SelectApartmentModal__SearchField" onSubmit={this.handleOnSubmit} />
            </div>
          </div>
        </div>
        <TableDataView viewId={VIEW_ID} entityType={ENTITY_TYPE} columns={columns} onSelectEntity={this.handleSelectEntity} filters={{ search: searchValue }} />
      </div>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {
  push: pushAction,
  createOrder: createOrderAction,
}

export default Modal(connect(mapStateToProps, mapDispatchToProps)(translate()(SelectApartmentModal)))
