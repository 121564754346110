import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { showModal } from 'actions/modal'

import modifyClassName from 'helper/modifyClassName'
import DatePickerModal from 'containers/modal/DatePickerModal'

import './style.less'

const MODAL_ID = name => `DATE_PICKER_MODAL_${name}`

class RFDatePickerInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    input: PropTypes.shape({}).isRequired,
    meta: PropTypes.shape({}).isRequired,
    showModal: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    className: '',
  }

  handleDateSelected = (date) => {
    const { input } = this.props
    const { onChange } = input
    onChange(moment(date).format('YYYY-MM-DD'))
  }

  handleShowModal = () => {
    const { showModal: show, input: { name } } = this.props
    show(MODAL_ID(name))
  }

  render = () => {
    const { className, input, label, meta } = this.props
    const { value, name } = input
    const { error } = meta

    return (
      <div className={`RFDatePickerInput ${className}`}>
        <button type="button" className="RFDatePickerInput__Button" onClick={this.handleShowModal}>
          <div className={modifyClassName('RFDatePickerInput__Label', { hasValue: value !== null && value !== '' })}>
            {label}
          </div>
          {value && moment(value).format('ddd DD.MM.YYYY')}
        </button>
        {error && <div className="TextInput__FieldError">{error}</div>}
        <DatePickerModal id={MODAL_ID(name)} disableCloseButton dateSelected={this.handleDateSelected} />
      </div>
    )
  }
}

const mapDispatchToProps = {
  showModal,
}

export default connect(null, mapDispatchToProps)(translate()(RFDatePickerInput))
