import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'i18n'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import FilterButton from '../FilterButton'
import FilterGroup from '../FilterGroup'
import ReportSelector from 'selectors/report'
import { selectFiltersAction } from 'actions/report'

const FilterTeams = ({ teams, selectedFilters, selectFilters, teamsAccumulated, t }) => {

  const onClick = ({ id }) => {
    let { teams } = selectedFilters
    if (teams.includes(id)) {
      teams = teams.filter(s => s !== id)
    } else {
      teams.push(id)
    }
    selectFilters({
      ...selectedFilters,
      teams,
    })
  }

  const onClickTeamsAccumulated = () => {
    selectFilters({
      ...selectedFilters,
      teamsAccumulated: !selectedFilters.teamsAccumulated,
      teamsCount: teams.length,
    })
  }

  if (!teams || teams.length === 0) {
    return null
  }

  const color = {
    fill: '#333',
    fillOpacity: 1,
  }

  return <FilterGroup label={t(`teams.title`)}>
    {teams.map((team) => (
      <FilterButton key={team.id} active={selectedFilters.teams?.includes(team.id)} onClick={() => onClick(team)}>
        {team.name}
      </FilterButton>
    ))}
    <FilterButton active={selectedFilters.teamsAccumulated} onClick={() => onClickTeamsAccumulated()} color={color}>
      {t(`teams.accumulated`)}
    </FilterButton>
  </FilterGroup>
}

const mapStateToProps = (state, ownProps) => {
  const teams = ownProps.teams
    ? Object.keys(ownProps.teams)
      .map((key) => ownProps.teams[key])
      .sort((a, b) => a.name > b.name ? 1 : -1)
    : []
  return ({
    teams,
    selectedFilters: ReportSelector.getSelectedFilters(state),
  })
}

const mapDispatchToProps = {
  selectFilters: selectFiltersAction
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(translate()(FilterTeams))

export default AsyncDataLoadingHOC({
  teams: {
    entityType: 'teams',
  },
})(ConnectedComponent)