import React from 'react'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const ReportOverviewNumber = ({
  className,
  report,
  onClick,
  selected,
}) => (
  <button type="button" onClick={() => onClick(report)} className={`${modifyClassName('ReportOverviewNumber', { selected })} ${className}`}>
    <div className="ReportOverviewNumber__Name">{report.name}</div>
  </button>
)

ReportOverviewNumber.propTypes = {
  className: PropTypes.string,
  report: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}

ReportOverviewNumber.defaultProps = {
  className: '',
  onClick: () => {},
  selected: false,
}

export default ReportOverviewNumber
