import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BadgeCount from 'components/common/BadgeCount'

import './style.less'

const OrderCommentIcon = ({ badgeCount }) => (
  <div className="OrderCommentIcon">
    <FontAwesomeIcon icon={['fal', 'comment-plus']} />
    {(badgeCount > 0) && <div className="OrderCommentIcon__BadgeCount"><BadgeCount badgeCount={badgeCount} /></div>}
  </div>
)

OrderCommentIcon.propTypes = {
  badgeCount: PropTypes.number,
}

OrderCommentIcon.defaultProps = {
  badgeCount: null,
}

export default OrderCommentIcon
