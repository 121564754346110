import { createStore } from 'redux'

/**
 * Ensure that this file does not have any other dependencies
 * to prevent circular dependencies.
 */

let store

export const initializeStore = (...options) => {
  if (store) {
    throw new Error("The stores has already been initialized.")
  }
  store = createStore(...options)
  return store
}

export const getStore = () => {
  if (!store) {
    throw new Error("The store has not been initialized.")
  }
  return store
}
