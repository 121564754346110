import React from 'react'
import PropTypes from 'prop-types'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { processStateColors } from 'helper/viewHelper'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

const ProcessState = ({ className, order, t, isActive, processState: propProcessState, isInline }) => {
  const processState = order?.processState || propProcessState
  if (!processState) {
    return null
  }
  
  const modifier = { [processStateColors[processState] || 'other']: true, isActive, isInline }
  return (
    <div className={`${modifyClassName('ProcessState', modifier)} ${className || ''}`}>
      {t(`process.states.${processState}`)}
    </div>
  )
}

ProcessState.propTypes = {
  order: PropTypes.shape({}),
  processState: PropTypes.string,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  ...i18nPropTypes,
}

ProcessState.defaultProps = {
  order: null,
  processState: null,
  className: null,
  isActive: true,
}

export default translate()(ProcessState)
