import i18n from 'i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import XHR from 'i18next-xhr-backend'
import PropTypes from 'prop-types'
import { isNumber, isDate } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import 'moment/locale/de'

import { translate } from 'react-i18next'

export const supportedLanguages = ['de']

/**
 * Overriding standard DE locale, because it does not include
 * points ('.') as thousand separators.
 * Copied from https://github.com/adamwdraper/Numeral-js/blob/master/locales/de.js
 */
numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: () => '.',
  currency: {
    symbol: '€',
  },
})

const handleChangeLanguage = (lng) => {
  moment.locale(lng)
  numeral.locale(lng)
}

i18n
  .use(XHR)
  .use(intervalPlural)
  // .use(LanguageDetector)
  .init({
    fallbackLng: 'de',
    ns: ['translation'],
    defaultNS: 'translation',
    debug: false,
    interpolation: {
      format: (value, format) => {
        if (format && isNumber(value)) return numeral(value).format(format)
        if (format && isDate(value)) return moment(value).format(format)
        if (format && moment.isMoment(value)) return value.format(format)
        return value
      },
    },

    // react i18next special options (optional)
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  })
  .on('languageChanged', handleChangeLanguage)

handleChangeLanguage('de')

export default i18n

export const propTypes = {
  t: PropTypes.func,
}

translate.setI18n(i18n)
export { translate }

export { numeral }
