import * as api from 'api'
import { commentSchema } from 'api/schemas'
import { denormalize } from 'normalizr'
import { values } from 'lodash'
import { receiveEntities } from './entities'

/* eslint-disable import/prefer-default-export */
export const createCommentAction = ({ lineItemId, orderHeaderId, comment }) => async (dispatch, getState) => {
  const payload = await api.createComment({ lineItemId, orderHeaderId, comment })
  dispatch(receiveEntities({ results: payload }))

  values(payload?.entities?.comments).forEach((receivedComment) => {
    if (lineItemId) {
      const orderLineItem = getState()?.entities?.orderLineItems[receivedComment.orderLineItem]
      orderLineItem.comments.push(receivedComment.id)
      dispatch(receiveEntities({
        results: {
          entities: {
            orderLineItems: {
              [orderLineItem.id]: orderLineItem,
            },
          },
        },
      }))
    } else if (orderHeaderId) {
      const orderHeader = getState()?.entities?.orders[receivedComment.orderHeader]
      orderHeader.comments.push(receivedComment.id)

      dispatch(receiveEntities({
        results: {
          entities: {
            orders: {
              [orderHeader.id]: orderHeader,
            },
          },
        },
      }))
    }
  })
  return denormalize(payload.result, commentSchema, getState().entities)
}
