import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { upsertPackageProductAction } from 'actions/packages'

import TextInput from 'components/common/TextInput'
import OrderLineItemInfos from 'components/common/OrderLineItemInfos'

import modifyClassName from 'helper/modifyClassName'


import './style.less'

class ProductPackageProduct extends Component {
  static propTypes = {
    productPackage: PropTypes.shape({}),
    product: PropTypes.shape({}),
    upsertPackageProduct: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    productPackage: null,
    product: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      qty: null,
      loading: false,
    }
  }

  handleQtyChange = async (event) => {
    const qty = event.target?.value?.replace(/[^0-9]/g, '') || ''
    this.setState({ qty: Number.parseInt(qty, 10) || 0 })
  }

  handleUpdatePackageProduct = async () => {
    const { upsertPackageProduct, productPackage, product } = this.props
    this.setState({ loading: true })
    await upsertPackageProduct({
      id: product.packageProduct?.id,
      package: productPackage.id,
      product: product.id,
      qty: Number.parseInt(this.state.qty, 10) || 0,
    })
    this.setState({ loading: false })
  }

  render = () => {
    const { product, t } = this.props
    const { qty: stateQty, loading } = this.state
    const qty = stateQty != null ? stateQty : product?.packageProduct?.qty || ''
    const used = product?.packageProduct?.qty > 0
    return (
      <div className={modifyClassName('ProductPackageProduct', { used })}>
        <div className="ProductPackageProduct__Dimension">
          {product.externalId}
        </div>
        <div className="ProductPackageProduct__Dimension">
          {product.roomType?.label || t('orders.allRooms')}
        </div>
        <div className="ProductPackageProduct__Dimension">
          {product.roomSize?.label}
        </div>
        <div className="ProductPackageProduct__Dimension">
          {product.grade?.label}
        </div>
        <div className="ProductPackageProduct__Dimension">
          {!loading && (
            <TextInput
              label={t('common.qty')}
              onChange={this.handleQtyChange}
              onBlur={this.handleUpdatePackageProduct}
              value={qty}
              type="number"
            />)}
          {loading && <FontAwesomeIcon className="" icon={['fal', 'spinner']} spin /> }
        </div>
        <OrderLineItemInfos className="ProductPackageProduct__Infos" price={product.price * qty} qty={qty || 0} unit={product.unit} unitPrice={product.price} />
      </div>
    )
  }
}


const mapDispatchToProps = {
  upsertPackageProduct: upsertPackageProductAction,
}

export default translate()(connect(null, mapDispatchToProps)(ProductPackageProduct))
