import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'
import { required } from 'helper/validators'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import RFTextInput, { NumberField } from 'components/common/RFTextInput'
import RFCheckBox from 'components/common/RFCheckBox'

import './style.less'

const ContractorCatalogField = ({ t, catalog, isChecked }) => (
  <div className="ContractorCatalogField">
    <Field name={`catalogs.${catalog.id}X.isChecked`} className={modifyClassName('ContractorCatalogField__Field', { isChecked })} component={RFCheckBox} label={catalog?.name} />
    <Field name={`catalogs.${catalog.id}X.indexValue`} className={modifyClassName('ContractorCatalogField__Field', { isChecked })} component={NumberField} label={t('table.indexValue')} disabled={!isChecked} type="number" validate={isChecked ? required : null} />
  </div>
)

ContractorCatalogField.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  catalog: PropTypes.shape({}).isRequired,
  ...i18nPropTypes,
}

export default translate()(ContractorCatalogField)
