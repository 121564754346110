import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import { sortRooms } from 'helper/dataHelper'

import RoomSizeSelectionForm from 'containers/form/RoomSizeSelectionForm'
import { getSelectedApartmentId } from 'selectors/navigation'

import './style.less'

const ApartmentRoomSizeSelection = ({ apartment }) => {
  // TODO not the best idea
  const sortedApartment = { ...apartment, rooms: sortRooms(apartment.rooms) }
  return (
    <div className="ApartmentRoomSizeSelection">
      <div className="ApartmentRoomSizeSelection__Header" />
      <RoomSizeSelectionForm apartment={sortedApartment} initialValues={sortedApartment} />
    </div>
  )
}

ApartmentRoomSizeSelection.propTypes = {
  apartment: PropTypes.shape({}).isRequired,
  ...i18nPropTypes,
}

export default AsyncDataLoadingHOC({
  apartment: {
    idSelector: getSelectedApartmentId,
    entityType: 'apartments',
  },
})(translate()(ApartmentRoomSizeSelection))
