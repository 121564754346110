
import { activeTenantChanged } from 'actions/user'

export default store => next => (action) => {
  if (action.type === String(activeTenantChanged)) {
    const ret = next(action)
    const state = store.getState()
    try {
      // eslint-disable-next-line
      localStorage.setItem('stored_state', JSON.stringify({
        activeTenant: state.user.activeTenant,
      }))
    } catch (error) {
      console.log('Error while using localstorage.', error)
    }
    return ret
  }

  return next(action)
}
