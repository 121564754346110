import React from 'react'
import './style.less'

const FilterStatus = (props) => {
  return <div className="FilterGroup">
    <div className="FilterGroup__Label">
      {props.label}
    </div>
    <div className="FilterGroup__Filters">
      {props.children}
    </div>
  </div>
}

export default FilterStatus