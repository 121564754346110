import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'
import CommentContainer from 'containers/common/CommentContainer'
import OrderCommentIcon from 'components/order/OrderCommentIcon'

import {
  reduxForm,
  Field,
  propTypes as reduxPropTypes,
  FieldArray,
  formValueSelector,
} from 'redux-form'

import { updateLineItemAction } from 'actions/orders'
import { deleteEntityAction, requestEntityAction } from 'actions/entities'

import { required } from 'helper/validators'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrimaryButton } from 'components/common/Button'
import RFTextInput, { CurrencyField } from 'components/common/RFTextInput'
import RFCheckBox from 'components/common/RFCheckBox'
import PriceSummary from 'components/common/PriceSummary'

import OrderSelector from 'selectors/order'

import './style.less'

class FreeTextLineItemForm extends Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    orderId: PropTypes.string.isRequired,
    roomId: PropTypes.string.isRequired,
    isInOverview: PropTypes.bool,
    summary: PropTypes.shape({
      overallAmount: PropTypes.number,
      requiredAmount: PropTypes.number,
    }),
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  static defaultProps = {
    isInOverview: false,
    summary: {
      overallAmount: 0,
      requiredAmount: 0,
    },
  }

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showCommentsFor: null,
      shouldAdjustScroll: false,
    }
  }

  componentDidUpdate() {
    const { shouldAdjustScroll } = this.state
    if (shouldAdjustScroll) {
      this.adjustScroll()
    }
  }

  adjustScroll = () => {
    this.buttonContainer.scrollIntoView({ block: 'end', behavior: 'smooth' })
    this.setState({
      shouldAdjustScroll: false,
    })
  }

  setButtonContainerRef = (ref) => {
    this.buttonContainer = ref
  }

  onSubmit = async (values) => {
    const {
      updateLineItem,
      orderId,
      roomId,
      requestEntity,
    } = this.props

    const { lineItems } = values

    await this.setState({ loading: true })
    await Promise.all(lineItems.map(lineItem => updateLineItem({
      ...lineItem,
      price: lineItem.price,
      orderHeader: orderId,
      room: roomId,
      qty: 1,
      tax: 19,
    }, false)))
    await requestEntity('orders', orderId)
    await this.setState({ loading: false })
  }

  handleDeleteLineItem = async (index, fields) => {
    const {
      // deleteEntity, 
      updateLineItem,
      lineItems,
    } = this.props
    const lineItemId = fields.get(index)?.id
    const lineItem = lineItems.find(l => l.id === lineItemId)
    // if (lineItemId) {
    if (lineItem) {
      await this.setState({ loading: true })
      await updateLineItem({
        ...lineItem,
        qty: 0,
      })
      // await deleteEntity('orderLineItems', lineItemId)
      await this.setState({ loading: false })
    }
    fields.remove(index)
  }

  handleAddLineItem = async (fields) => {
    fields.push({})
    this.setState({
      shouldAdjustScroll: true,
    })
  }

  renderLineItems = ({
    fields,
    loading,
    showCommentsFor,
    shouldShowButton,
  }) => (
    <>
      <ul className="FreeTextLineItemForm__LineItemList">
        {fields
          .map((member, index) => (
            <div key={member}>
              <li className={modifyClassName('FreeTextLineItemForm__FieldRow', { container: true })}>
                <Field
                  className="FreeTextLineItemForm__FieldDescription"
                  name={`${member}.description`}
                  label={this.props.t('orders.description')}
                  component={RFTextInput}
                  isLoading={loading}
                  validate={required}
                />
                <Field
                  className="FreeTextLineItemForm__FieldIsRequired"
                  name={`${member}.isRequired`}
                  component={RFCheckBox}
                  label={this.props.t('orders.isRequired')}
                />
                <Field
                  className="FreeTextLineItemForm__FieldPrice"
                  name={`${member}.price`}
                  component={CurrencyField}
                  label={this.props.t('orders.unitPrice')}
                />
                <button className="FreeTextLineItemForm__DeleteButton" onClick={() => this.handleDeleteLineItem(index, fields)} type="button" disabled={loading}>
                  <FontAwesomeIcon icon={['fal', loading ? 'spinner' : 'trash-alt']} spin={loading} />
                </button>
                {fields.get(index).id &&
                  <button
                    className="FreeTextLineItemForm__CommentButton"
                    type="button"
                    onClick={() => {
                      if (showCommentsFor === fields.get(index).id) {
                        this.setState({ showCommentsFor: null })
                      } else {
                        this.setState({ showCommentsFor: fields.get(index).id })
                      }
                    }}
                  >
                    <OrderCommentIcon badgeCount={fields.get(index).comments?.length} />
                  </button>
                }
              </li>
              {(showCommentsFor === fields.get(index).id) && (
                <div className="FreeTextLineItemForm__Comments">
                  <CommentContainer lineItem={fields.get(index)} />
                </div>
              )}
            </div>
          ))
        }
        <div className="FreeTextLineItemForm__Buttons" ref={this.setButtonContainerRef}>
          {!this.props.isInOverview && (
            <div className="FreeTextLineItemForm__AddButtonContainer">
              <PrimaryButton type="button" onClick={() => this.handleAddLineItem(fields)}>
                <FontAwesomeIcon className="FreeTextLineItemForm__AddButtonPlus" icon={['fal', 'plus']} />
                {this.props.t('orders.addFreeTextLineItem')}
              </PrimaryButton>
            </div>
          )}
          {shouldShowButton && (
            <div className="FreeTextLineItemForm__Footer">
              <PrimaryButton type="submit" onClick={this.handleCreateOrder} disabled={!this.props.dirty}>
                {loading ? <FontAwesomeIcon className="FreeTextLineItemForm__Spinner" icon={['fal', 'spinner']} spin /> : this.props.t('buttons.save')}
              </PrimaryButton>
            </div>
          )}
        </div>
      </ul>
    </>
  )

  render() {
    const {
      t,
      handleSubmit,
      dirty,
      isInOverview,
      lineItems,
      summary: { requiredAmount, overallAmount }
    } = this.props
    const { loading, showCommentsFor } = this.state
    let shouldShowButton = dirty || !isInOverview
    if (isInOverview && lineItems?.length === 0) {
      shouldShowButton = false
    }
    return (
      <form className="FreeTextLineItemForm" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="FreeTextLineItemForm__Header">
          <div className="FreeTextLineItemForm__Title">
            {t('orders.freeLineItems')}
          </div>
          <PriceSummary requiredAmount={requiredAmount} overallAmount={overallAmount} />
        </div>
        <div className="FreeTextLineItemForm__Fields">
          <FieldArray name="lineItems" component={this.renderLineItems} loading={loading} showCommentsFor={showCommentsFor} rerenderOnEveryChange shouldShowButton={shouldShowButton} />
        </div>
      </form>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: 'FREE_TEXT_LINE_ITEM_FORM',
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    lineItems: ownProps.initialValues?.filter(l => l.qty > 0) || OrderSelector.getFreeTextLineItems(state)?.filter(l => l.qty > 0),
    // todo: switch to show in overview
    // lineItems: ownProps.initialValues || OrderSelector.getFreeTextLineItems(state),
  },
  lineItems: formValueSelector(ownProps.form || form.form)(state, 'lineItems'),
  summary: OrderSelector.getFreeTextLineItemsSummary(state),
})

const mapDispatchToProps = {
  updateLineItem: updateLineItemAction,
  deleteEntity: deleteEntityAction,
  requestEntity: requestEntityAction,
}


const formComponent = reduxForm(form)(FreeTextLineItemForm)
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(formComponent)
const translatedComponent = translate()(connectedComponent)


export default translatedComponent
