
import { createAction } from 'redux-actions'
import * as api from 'api'
import { receiveEntities } from 'actions/entities'

export const taskIdsChanged = createAction('TASK_IDS_CHANGED')
export const orderTasksChangeAction = createAction('TASK_ORDER_CHANGED')

export const requestTasksAction = filter => async (dispatch) => {
  const payload = await api.requestEntities('orders', filter)
  const result = await dispatch(receiveEntities(payload))
  dispatch(taskIdsChanged(result.payload.results.result))
  return result
}
