import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { reduxForm, Field, propTypes as reduxPropTypes } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { updateTenantAction } from 'actions/tenants'
import { requestCatalogsAction } from 'actions/catalogs'
import { importCatalog } from 'actions/import'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import AuthenticationSelector from 'selectors/authentication'

import { PrimaryButton } from 'components/common/Button'
import FileDrop from 'components/common/FileDrop'
import RFTextInput from 'components/common/RFTextInput'
import { TopBar } from 'components/common/ToolBar'

import TableDataView from 'containers/common/TableDataView'
import { RFCatalogDropDownInputWithHOC } from 'containers/input/RFCatalogDropDownInput'
import { bytesToSize } from 'helper/utils'
import { required } from 'helper/validators'
import { localDateTime, userDisplayName } from 'helper/viewHelper'

import CatalogSelector from 'selectors/catalogs'
import PageSelector from 'selectors/page'

import './style.less'

const VIEW_ID = 'CATALOGS'
const ENTITY_TYPE = 'catalogs'

class SettingsCatalogImport extends Component {
  static propTypes = {
    catalogs: PropTypes.shape({}),
    requestCatalogsAction: PropTypes.func,
    updateTenant: PropTypes.func.isRequired,
    activeTenant: PropTypes.shape({}).isRequired,
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  static defaultProps = {
    catalogs: {},
    requestCatalogsAction: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      isUploading: false,
      errorMessage: null,
      file: null,
    }
  }

  onSubmit = async (values) => {
    this.handleCatalogImportUpload(values.name)
  }

  handleCatalogImportDrop = (files) => {
    const { t } = this.props
    this.setState({ errorMessage: null })

    if (files && files.length > 0) {
      const file = files[0]
      this.setState({
        file,
      })
    }
  }

  handleCatalogImportUpload = async (filename) => {
    this.setState({ errorMessage: null })

    const { isUploading, file } = this.state
    if (isUploading) return

    await this.setState({
      isUploading: true,
    })

    const { importCatalog: importC } = this.props
    try {
      await importC(file, filename)
    } catch (e) {
      this.setState({ errorMessage: e.message })
    }

    await this.setState({
      isUploading: false,
      file: null,
    })
  }

  handleDefaultCatalogChange = async (catalog) => {
    const { updateTenant, activeTenant } = this.props
    await this.setState({ loading: true })
    await updateTenant({ id: activeTenant.id, defaultCatalog: catalog.id })
    await this.setState({ loading: false })
  }

  renderDropZone = () => {
    const { t } = this.props
    return (
      <FileDrop
        onDrop={this.handleCatalogImportDrop}
        text={t('imports.dropzonetext')}
        textDrop={t('imports.dropzonedroptext')}
      />
    )
  }

  renderFileDetail = () => {
    const { t, handleSubmit } = this.props
    const { file, isUploading } = this.state

    return (
      <div className="SettingsCatalogImport__FileContainer">
        <div className="SettingsCatalogImport__File">
          <div>{t('imports.importedfile')}</div>
          <FontAwesomeIcon className="SettingsCatalogImport__Icon" icon={['fal', 'file-spreadsheet']} />
          <div className="SettingsCatalogImport__FileInfos">
            <div>{file.name}</div>
            <div>{bytesToSize(file.size)}</div>
          </div>
        </div>
        <form className="SettingsCatalogImport__Form" onSubmit={handleSubmit(this.onSubmit)}>
          <Field autoFocus name="name" component={RFTextInput} label="Katalogname" type="text" validate={required} />
          <div className="SettingsCatalogImport__Hint">{t('imports.startupload')}</div>
          <div className="SettingsCatalogImport__FileButtons">
            <PrimaryButton className="SettingsCatalogImport__SubmitButton" type="submit">
              {isUploading ? <FontAwesomeIcon className="SettingsCatalogImport__Spinner" icon={['fal', 'spinner']} spin /> : t('buttons.startImport')}
            </PrimaryButton>
          </div>
        </form>
      </div>
    )
  }

  render() {
    const { t, activeTenant } = this.props
    const { errorMessage, file, loading } = this.state

    const columns = [
      { header: t('table.catalogname'), accessor: 'name' },
      { header: t('common.user'), accessor: catalogImport => userDisplayName(catalogImport.importedBy) },
      { header: t('table.numberOfProducts'), accessor: 'productCount' },
      { header: t('table.importedOn'), accessor: catalogImport => localDateTime(catalogImport.created) },
    ]

    return (
      <div className="SettingsCatalogImport">
        <TopBar className="SettingsCatalogImport__TopBar">
          <RFCatalogDropDownInputWithHOC className="SettingsCatalogImport__DefaultCatalogDropDown" onChange={this.handleDefaultCatalogChange} label={t('imports.defaultCatalog')} isLoading={loading} value={activeTenant.defaultCatalog} />
        </TopBar>
        <div className="SettingsCatalogImport__ImportContainer">
          {file ? this.renderFileDetail() : this.renderDropZone()}
          {errorMessage && <div className="SettingsCatalogImport__Error">{errorMessage}</div>}
        </div>
        <div className="SettingsCatalogImport__CatalogImportContainer">
          <TableDataView viewId={VIEW_ID} entityType={ENTITY_TYPE} columns={columns} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  page: PageSelector.getCurrentCatalogsPage(state),
  catalogs: CatalogSelector.getCatalogsForCurrentPage(state),
})

const form = {
  enableReinitialize: true,
  form: 'IMPORT_CATALOG_FORM',
}

const connectedComponent = connect(mapStateToProps, {
  requestCatalogsAction,
  importCatalog,
  updateTenant: updateTenantAction,
})(SettingsCatalogImport)

const formComponent = reduxForm(form)(connectedComponent)
const translatedComponent = translate()(formComponent)

export default AsyncDataLoadingHOC({
  activeTenant: {
    idSelector: AuthenticationSelector.getActiveTenantId,
    entityType: 'tenants',
  },
})(translatedComponent)
