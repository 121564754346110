import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { connect } from 'react-redux'

import pathNameSelector from 'selectors/navigation'

import { Redirect } from 'react-router-dom'

import SideMenuHeader from 'components/common/SideMenuHeader'
import SideMenuButton from 'components/common/SideMenuButton'

import './style.less'

const PATH_USERS = '/settings/users'
const PATH_ORGANIZATION = '/settings/teams'
const PATH_CATALOG_IMPORT = '/settings/catalog-import'
const PATH_APARTMENT_IMPORT = '/settings/apartment-import'
const PATH_PROCESS = '/settings/process'
const PATH_CONTRATORS = '/settings/contractors'
const PATH_ROOM_SIZES = '/settings/roomsizes'
const PATH_PACKAGES = '/settings/packages'

const SettingsSideMenu = ({
  t,
  currentPath,
}) => (
  <div className="SettingsSideMenu">
    <SideMenuHeader
      infoTitle={t('settings.title')}
      goBackTo="/"
    />
    <div className="SettingsSideMenu__Navigation">
      <SideMenuButton
        to={PATH_USERS}
        icon="users"
        exact={false}
      >
        {t('navigation.users')}
      </SideMenuButton>
      <SideMenuButton
        to={PATH_ORGANIZATION}
        icon="building"
      >
        {t('navigation.organization')}
      </SideMenuButton>
      <SideMenuButton
        to={PATH_CATALOG_IMPORT}
        icon="cloud-upload"
      >
        {t('navigation.catalog-import')}
      </SideMenuButton>
      <SideMenuButton
        to={PATH_APARTMENT_IMPORT}
        icon="cloud-upload"
      >
        {t('navigation.apartment-import')}
      </SideMenuButton>
      {/*
      <SideMenuButton
        to={PATH_PROCESS}
        icon="project-diagram"
      >
        {t('navigation.process')}
      </SideMenuButton>
      */}
      <SideMenuButton
        to={PATH_ROOM_SIZES}
        icon="cubes"
      >
        {t('navigation.roomsizes')}
      </SideMenuButton>
      <SideMenuButton
        to={PATH_CONTRATORS}
        icon="address-card"
        exact={false}
      >
        {t('navigation.contractors')}
      </SideMenuButton>

      <SideMenuButton
        to={PATH_PACKAGES}
        icon="boxes"
        exact={false}
      >
        {t('navigation.packages')}
      </SideMenuButton>
      { currentPath === '/settings' && <Redirect to="/settings/users" /> }
    </div>
    <div className="SettingsSideMenu__Footer" />
  </div>
)

SettingsSideMenu.propTypes = {
  currentPath: PropTypes.string.isRequired,
  ...i18nPropTypes,
}

const mapStateToProps = state => ({
  currentPath: pathNameSelector(state),
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SettingsSideMenu))
