import * as _ from 'lodash'
import { createSelector } from 'reselect'

export const getAuth = state => state.authentication

// Needs to be implemented here to prevent circular imports
export const getActiveTenant = state => _.get(state, 'user.activeTenant', {})

const getActiveTenantId = createSelector(
  getActiveTenant,
  tenant => tenant.id,
)

export default {
  getAuth,
  getActiveTenant,
  getActiveTenantId,
}
