import React, { Component } from 'react'
import { values as _values } from 'lodash'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import CatalogSelector from 'selectors/catalogs'

import RFDropDownInput from 'components/common/RFDropDownInput'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'


class RFCatalogDropDownInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    catalogs: PropTypes.arrayOf(PropTypes.shape()),
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    displaySuggestionsOnTop: PropTypes.bool,
    ...i18nPropTypes,
  }

  static defaultProps = {
    className: '',
    input: {
      onChange: () => {},
    },
    meta: {},
    onChange: () => {},
    catalogs: undefined,
    isLoading: false,
    displaySuggestionsOnTop: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
    }
  }

  componentDidMount() {
    const { catalogs } = this.props
    if (catalogs) {
      this.setState({
        suggestions: catalogs,
      })
    }
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    const { catalogs, fetchedCatalogs } = this.props
    const source = catalogs ? _values(catalogs) : _values(fetchedCatalogs)
    let suggestions = null
    if (reason === 'input-changed') {
      suggestions = source
        .filter(catalog => catalog.name.toLowerCase().includes(value.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
        .slice(0, 5)
    } else {
      suggestions = source
        .sort((a, b) => a.name.localeCompare(b.name))
        .slice(0, 5)
    }
    this.setState({ suggestions })
  }

  getSuggestionValue = value => value?.name || 'nope'

  render = () => {
    const { isLoading } = this.props
    const { suggestions } = this.state
    return (
      <RFDropDownInput
        {...this.props}
        isLoading={isLoading}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        getSuggestionValue={this.getSuggestionValue}
      />
    )
  }
}

export default translate()(RFCatalogDropDownInput)

export const RFCatalogDropDownInputWithHOC = AsyncDataLoadingHOC({
  fetchedCatalogs: {
    entitySelector: CatalogSelector.getCatalogsAsArray,
    entityType: 'catalogs',
  },
})(translate()(RFCatalogDropDownInput))
