import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'


import { Manager, Reference } from 'react-popper'

import { showFlyout } from 'actions/flyout'

import ProcessState from 'components/process/ProcessState'
import ProcessStateFlyout from 'containers/process/ProcessStateFlyout'

import { metaDataSelector } from 'selectors/data-view'

import './style.less'

const PROCESS_STATE_FILTER_FLYOUT_ID = 'PROCESS_STATE_FILTER_FLYOUT_ID'


class ProcessStateFilter extends Component {
  static propTypes = {
    showFlyout: PropTypes.func.isRequired,
    viewId: PropTypes.string.isRequired,
    processStateFilter: PropTypes.string,
    ...i18nPropTypes,
  }

  static defaultProps = {
    processStateFilter: null,
  }

  showProcessStateFlyout = () => {
    const { showFlyout: dispatchShowFlyout } = this.props
    dispatchShowFlyout(PROCESS_STATE_FILTER_FLYOUT_ID)
  }

  render = () => {
    const { viewId, t, processStateFilter } = this.props
    return (
      <div>
        <Manager>
          <Reference>
            {({ ref }) => (
              <button className="ProcessStateFilter" ref={ref} type="button" onClick={this.showProcessStateFlyout}>
                <div className="ProcessStateFilter__Label">{t('orders.processState')} {t('common.filter')}</div>
                { processStateFilter && <ProcessState processState={processStateFilter} />}
                { !processStateFilter && <div className="ProcessStateFilter__AllStates">{t('orders.allStates')}</div>}
              </button>
            )}
          </Reference>
          <ProcessStateFlyout id={PROCESS_STATE_FILTER_FLYOUT_ID} viewId={viewId} />
        </Manager>
      </div>)
  }
}

const mapStateToProps = (state, props) => ({
  processStateFilter: metaDataSelector(state, props)?.filters?.processState,
})

const mapDispatchToProps = {
  showFlyout,
}


export default translate()(connect(mapStateToProps, mapDispatchToProps)(ProcessStateFilter))
