import * as api from 'api'
import { denormalize } from 'normalizr'

import { teamSchema } from 'api/schemas'
import { receiveEntities } from 'actions/entities'

export const createTeamAction = team => async (dispatch, getState) => {
  const payload = await api.createTeam(team)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, teamSchema, getState().entities)
}

export const updateTeamAction = team => async (dispatch, getState) => {
  const payload = await api.updateTeam(team)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, teamSchema, getState().entities)
}

export const requestTeamAction = teamId => async (dispatch) => {
  const payload = await api.requestEntity('teams', teamId)
  return dispatch(receiveEntities({ results: payload }))
}
