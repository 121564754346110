import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'i18n'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import FilterButton from '../FilterButton'
import FilterGroup from '../FilterGroup'
import ReportSelector from 'selectors/report'
import { selectFiltersAction } from 'actions/report'
import { getCraftColor } from '../../../helper/viewHelper'

const FilterCrafts = ({ crafts, selectedFilters, selectFilters, t }) => {

  const onClick = ({ id }) => {
    let { crafts } = selectedFilters
    if (crafts.includes(id)) {
      crafts = crafts.filter(s => s !== id)
    } else {
      crafts.push(id)
    }
    selectFilters({
      ...selectedFilters,
      crafts,
    })
  }

  if (!crafts || crafts.length === 0) {
    return null
  }

  return <FilterGroup label={t(`crafts.title`)}>
    {crafts.sort((a, b) => a.name > b.name ? 1 : -1).map((craft, index) => (
      <FilterButton
        key={craft.id}
        active={selectedFilters.crafts?.includes(craft.id)}
        onClick={() => onClick(craft)}
        color={getCraftColor(index)}
        >
        {craft.name}
      </FilterButton>
    ))}
  </FilterGroup>
}

const mapStateToProps = (state, ownProps) => {
  const crafts = ownProps.crafts
    ? Object.keys(ownProps.crafts)
      .map((key) => ownProps.crafts[key])
      .sort((a, b) => a.name > b.name ? 1 : -1)
    : []
  return ({
    crafts,
    selectedFilters: ReportSelector.getSelectedFilters(state),
  })
}

const mapDispatchToProps = {
  selectFilters: selectFiltersAction
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(translate()(FilterCrafts))

export default AsyncDataLoadingHOC({
  crafts: {
    entityType: 'crafts',
  },
})(ConnectedComponent)