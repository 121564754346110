import _ from 'lodash'
import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'


const getFormCatalog = (state, props) => formValueSelector(props?.meta?.form || '')(state, 'catalog')

const getContractors = state => _.get(state, 'entities.contractors', {})

const makeContractorsForCatalogInFormSelector = () => createSelector(
  [getFormCatalog, getContractors],
  (formCatalog, contractors) => (
    _.values(contractors)?.filter(contractor => contractor?.catalogs?.find(catalog => catalog?.id === formCatalog?.id)) || []
  ),
)
export default { makeContractorsForCatalogInFormSelector }
