import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import numeral from 'numeral'
import { values } from 'lodash'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { requestEntityAction, deleteEntityAction } from 'actions/entities'
import { downloadOrderSummaryAction } from 'actions/orders'

import SideMenuButton from 'components/common/SideMenuButton'
import SideMenuHeader from 'components/common/SideMenuHeader'
import ValueWithLabel from 'components/common/ValueWithLabel'
import ProcessState from 'components/process/ProcessState'
import { IconButton } from 'components/common/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Price from 'components/common/Price'

import { push } from 'connected-react-router'

import { splitAddress, roomIconMapping as iconMapping } from 'helper/viewHelper'

import getPath, { getSelectedOrderId } from 'selectors/navigation'
import OrderSelector from 'selectors/order'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

const roomPath = (order, room) => `/orders/${order.id}/rooms/${room.id}`

class OrderSideMenu extends Component {
  static propTypes = {
    ...i18nPropTypes,
    order: PropTypes.shape({}),
    path: PropTypes.string.isRequired,
    roomSummary: PropTypes.shape({}),
    rooms: PropTypes.arrayOf(PropTypes.shape({})),
  }

  static defaultProps = {
    order: null,
    roomSummary: {},
    rooms: [],
  }

  componentDidMount = async () => {
    const { orderId, requestEntity } = this.props
    // TODO should be handled by AsyncLoadingHOC
    const response = await requestEntity('orders', orderId)
    await requestEntity('catalogs', values(response.payload.results.entities.orders)[0].catalog)
  }

  handleOrderSummaryDownload = (e) => {
    e.stopPropagation()
    const { downloadOrderSummary, order } = this.props
    downloadOrderSummary(order)
  }

  handleDelete = async () => {
    const { t, deleteEntity, order, navigateTo } = this.props
    if (confirm(t('orders.confirmDelete'))) {
      await deleteEntity('orders', order.id)
      navigateTo('/orders')
    }
  }

  render = () => {
    const { order, t, path, roomSummary, rooms } = this.props
    const yieldInvestOnly = parseFloat(order?.numbers?.yieldInvestOnly || '0') * 100

    if (!order) return t('order.noOrderSelected')

    const summarySelected = path === `/orders/${order.id}`
    return (
      <div className="OrderSideMenu">
        <SideMenuHeader
          infoTitle={`#${order.id} | ${order.apartment.externalId}`}
          infos={splitAddress(order.apartment.address)}
          goBackTo="/orders"
        />

        <div className="SideMenuHeader__Infos" style={{ paddingTop: '1rem', textTransform: 'uppercase' }}>
          {t('orders.apartmentSize') + ' ' + ('' + order.apartment?.size).replace('.', ',') + ' m²'}
        </div>

        <ProcessState className="OrderSideMenu__ProcessState" order={order} />

        <div className="OrderSideMenu__OrderSummary">
          {yieldInvestOnly !== 0 && (
            <ValueWithLabel className="OrderSideMenu__Yield" labelClassName="OrderSideMenu__WhiteLabel" label={t('orderProfitability.yield')}>
              {`${numeral(yieldInvestOnly).format('0,0.0')} %`}
            </ValueWithLabel>
          )}
          <ValueWithLabel className="OrderSideMenu__AlignRight" labelClassName="OrderSideMenu__WhiteLabel" label={t('orders.totalPrice')}>
            <Price>{order.totalPrice}</Price>
          </ValueWithLabel>
        </div>

        <ScrollContainer className="OrderSideMenu__NavigationButtons" scrollVertical>
          <SideMenuButton to={`/orders/${order.id}`} icon="home-heart" selected={summarySelected}>
            {t('common.summary')}
          </SideMenuButton>
          {rooms?.map((room, i) => {
            if (room) {
              const { lineItemCount = 0, totalPrice = 0 } = roomSummary[room.id] || {}
              const formatedTotalPrice = numeral(totalPrice / 100.0).format('0,0.00')
              const subtitle = `${t('orders.orderLineItemCount', { count: lineItemCount })} | ${formatedTotalPrice} €`
              return (
                <SideMenuButton
                  key={room.id}
                  to={roomPath(order, room)}
                  icon={iconMapping[room?.roomType?.externalId] || 'cube'}
                  subtitle={totalPrice > 0 ? subtitle : null}
                >
                  {room.name || `Zimmer ${room.number}`}
                </SideMenuButton>
              )
            }
            return <div key={i} />
          })}
        </ScrollContainer>
        <div className="OrderSideMenu__DownloadOverView">
          <IconButton className="OrderSideMenu__DownloadButton" onClick={this.handleOrderSummaryDownload}>
            <FontAwesomeIcon icon={['fal', 'file-download']} />
            <div className="OrderSideMenu__DownloadLabel">
              {t('orders.downloadOverview')}
            </div>
          </IconButton>
          {order.processState === 'Draft' && (
            <IconButton className="OrderSideMenu__DownloadButton" onClick={this.handleDelete}>
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
              <div className="OrderSideMenu__DownloadLabel">
                {t('orders.delete')}
              </div>
            </IconButton>
          )}
        </div>
      </div>)
  }
}

const mapStateToProps = state => ({
  orderId: getSelectedOrderId(state),
  order: OrderSelector.getSelectedOrder(state),
  path: getPath(state),
  roomSummary: OrderSelector.getRoomSummary(state),
  rooms: OrderSelector.getRoomsOfSelectedOrder(state),
})

const mapDispatchToProps = {
  requestEntity: requestEntityAction,
  deleteEntity: deleteEntityAction,
  downloadOrderSummary: downloadOrderSummaryAction,
  navigateTo: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrderSideMenu))
