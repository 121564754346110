import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PrimaryButton } from 'components/common/Button'
import { Title } from 'components/process/ProcessTitle'

class DraftState extends Component {
  static propTypes = {
    order: PropTypes.shape({}),
    onDispatchAction: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    order: null,
  }

  handleSubmitAction = () => {
    const { order, onDispatchAction } = this.props
    onDispatchAction(order, { action: 'Submit' })
  }

  render = () => {
    const { order } = this.props
    let title = 'process.draftStateTitle'
    let buttonText = 'process.submitOrder'

    if (order.processTaskState === 'ConditionFalse') {
      title = 'process.draftStateTitleNotEligible'
      buttonText = 'process.submitDraftNotEligible'
    }

    return (
      <>
        <Title>{this.props.t(title)}</Title>
        <PrimaryButton onClick={this.handleSubmitAction}>
          {this.props.t(buttonText)}
        </PrimaryButton>
      </>
    )
  }
}

export default translate()(DraftState)
