// only import icons needed this makes bundle.js smaller
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTachometerAltFast, // ['fal', 'tachometer-alt-fast']
  faClipboardList, // ['fal', 'clipboard-list']
  faBuilding, // ['fal', 'building']
  faCogs, // ['fal', 'cogs']
  faSlidersH, // ['fal', 'sliders-h']
  faCubes, // ['fal', 'cubes'],
  faArrowLeft, // ['fal', 'arrow-left'],
  faUsers, // ['fal', 'users']
  faCloudUpload, // ['fal', 'cloud-upload']
  faProjectDiagram, // ['fal', 'project-diagram']
  faAddressCard, // ['fal', 'address-card']
  faSpinner, // ['fal', 'spinner']
  faArrowToBottom, // ['fal', 'arrow-to-bottom']
  faFileSpreadsheet, // ['fal', 'file-spreadsheet']
  faUtensils,
  faShower,
  faDoorOpen,
  faHomeHeart,
  faCube,
  faCheck,
  faLessThan,
  faTimesCircle, // ['fal', 'times-circle']
  faCheckCircle, // ['fal', 'check-circle']
  faChartLine,
  faBars,
  faRulerCombined, // ['fal', 'ruler-combined']
  faBolt, // ['fal', 'bolt']
  faCabinetFiling, // ['fal', 'cabinet-filing']
  faToolbox, // ['fal', 'toolbox']
  faBullseye, // ['fal', 'bullseye']
  faPaintRoller, // ['fal', 'paint-roller']
  faTh, // ['fal', 'th']
  faWrench, // ['fal', 'wrench']
  faScrewdriver, // ['fal', 'screwdriver']
  faTrashAlt, // ['fal', 'trash-alt']
  faPlus,
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faMinus,
  faCommentPlus,
  faPaperPlane,
  faSearch,
  faFileDownload,
  faBoxes,
  faAnalytics,
  faCommentDots,
  faPen,
  faPlusCircle,
  faLongArrowAltRight,
  faTrash,
  faFileCsv
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faTachometerAltFast,
  faClipboardList,
  faBuilding,
  faCogs,
  faSlidersH,
  faCubes,
  faArrowLeft,
  faUsers,
  faCloudUpload,
  faProjectDiagram,
  faAddressCard,
  faSpinner,
  faArrowToBottom,
  faFileSpreadsheet,
  faUtensils,
  faShower,
  faDoorOpen,
  faHomeHeart,
  faCube,
  faCheck,
  faLessThan,
  faTimesCircle,
  faCheckCircle,
  faChartLine,
  faBars,
  faRulerCombined,
  faBolt,
  faCabinetFiling,
  faToolbox,
  faBullseye,
  faPaintRoller,
  faTh,
  faWrench,
  faScrewdriver,
  faTrashAlt,
  faPlus,
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faMinus,
  faCommentPlus,
  faPaperPlane,
  faSearch,
  faFileDownload,
  faBoxes,
  faAnalytics,
  faCommentDots,
  faPen,
  faPlusCircle,
  faLongArrowAltRight,
  faTrash,
  faFileCsv
)
