import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'
import schemas from 'api/schemas'

export const metaDataSelector = (state, props) => {
  const view = state?.dataView[props.viewId]
  if (view) {
    const { paging: { page, pageSize }, data: { count } } = view
    return {
      ...view,
      paging: {
        ...view.paging,
        hasPrevious: page > 1,
        hasNext: Math.ceil(count / pageSize) > page,
        pageCount: Math.ceil(count / pageSize),
      },
    }
  }
  return view
}

const getEntities = state => state.entities

export const dataSelector = createSelector(
  [metaDataSelector, getEntities],
  (metaData, entities) => {
    if (!metaData || !entities) return []
    const ids = metaData?.data?.ids || []
    const schema = schemas[metaData.entityType]
    return denormalize(ids, [schema], entities)
  },
)
