import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push as pushAction } from 'connected-react-router'

import { createOrderAction } from 'actions/orders'
import { PrimaryButton } from 'components/common/Button'

import { TopBar } from 'components/common/ToolBar'
import Filter from 'components/common/Filter'

import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import OrdersTable from 'containers/order/OrdersTable'

import UserSelector from 'selectors/user'
import { getSelectedApartmentId } from 'selectors/navigation'

import './style.less'

const VIEW_ID = 'ORDERS_APARTMENT'

class ApartmentDetailOrders extends Component {
  static propTypes = {
    apartment: PropTypes.shape({}),
    createOrder: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    isCaseHandler: PropTypes.bool.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    apartment: null,
  }

  handleCreateOrder = async () => {
    const { apartment, createOrder, push } = this.props
    if (apartment) {
      const order = await createOrder(apartment)
      push(`/orders/${order.id}`)
    }
  }

  render() {
    const { t, isCaseHandler, apartment } = this.props
    return (
      <div className="ApartmentDetailOrders">
        <TopBar>
          <Filter />
          { isCaseHandler && <PrimaryButton onClick={this.handleCreateOrder}>{t('orders.create')}</PrimaryButton>}
        </TopBar>
        <OrdersTable viewId={VIEW_ID} filters={{ apartment__id: apartment.id }} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isCaseHandler: UserSelector.getRoles(state).includes('CASE_HANDLER'),
})

const mapDispatchToProps = {
  createOrder: createOrderAction,
  push: pushAction,
}

export default AsyncDataLoadingHOC({
  apartment: {
    idSelector: getSelectedApartmentId,
    entityType: 'apartments',
  },
})(connect(mapStateToProps, mapDispatchToProps)(translate()(ApartmentDetailOrders)))
