import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'
import { required } from 'helper/validators'
import {
  reduxForm,
  propTypes as reduxFormPropTypes,
  Field,
} from 'redux-form'
import UserSelector from 'selectors/user'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { upsertPackageAction } from 'actions/packages'
import { requestEntityAction } from 'actions/entities'

import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import { RFCatalogDropDownInputWithHOC } from 'containers/input/RFCatalogDropDownInput'
import ProductPackageProduct from 'containers/packages/ProductPackageProduct'

import { TopBar } from 'components/common/ToolBar'
import { PrimaryButton } from 'components/common/Button'
import BackButton from 'components/common/BackButton'
import RFTextInput from 'components/common/RFTextInput'

import { getSelectedProductPackageId, getIsInTenantContext } from 'selectors/navigation'
import OrderSelector from 'selectors/order'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class ProductPackageDetail extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    isInTenantContext: PropTypes.bool.isRequired,
    productPackage: PropTypes.shape({}),
    navigateTo: PropTypes.func.isRequired,
    upsertPackage: PropTypes.func.isRequired,
    requestEntity: PropTypes.func.isRequired,
    ...i18nPropTypes,
    ...reduxFormPropTypes,
  }

  static defaultProps = {
    productPackage: {},
  }

  constructor(props) {
    super(props)
    this.state = { loading: false }
  }

  componentDidMount() {
    const { productPackage } = this.props
    const showPackageProducts = productPackage?.id
    if (showPackageProducts) {
      this.fetchCatalog()
    }
  }

  fetchCatalog = async () => {
    const { productPackage, requestEntity } = this.props
    const newCatalogId = productPackage?.catalog?.id
    this.setState({ loading: true })
    await requestEntity('catalogs', newCatalogId)
    this.setState({ loading: false })
  }

  handleSubmit = async (productPackage) => {
    const {
      upsertPackage,
      navigateTo,
      isInTenantContext,
      currentUser,
    } = this.props
    const isCreate = !productPackage.id
    this.setState({ loading: true })
    const payload = await upsertPackage({ ...productPackage, owner: isInTenantContext ? null : currentUser.id })
    this.setState({ loading: false })
    if (isCreate) {
      navigateTo(this.props.isInTenantContext ? `/settings/packages/${payload.id}` : `/packages/${payload.id}`)
    }
  }

  handleBack = () => (this.props.isInTenantContext ? this.props.navigateTo('/settings/packages') : this.props.navigateTo('/packages'))

  renderPackageForm = () => {
    const {
      handleSubmit,
      t,
      dirty,
      productPackage,
    } = this.props
    const { loading } = this.state
    const renderForUpdate = productPackage?.id
    const buttonLabel = renderForUpdate ? t('buttons.save') : t('buttons.createAndAddProducts')
    return (
      <form className="ProductPackageDetail__Form" onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="ProductPackageDetail__FormRow">
          <Field
            name="name"
            className="ProductPackageDetail__FormName"
            component={RFTextInput}
            label={t('table.packageName')}
            validate={required}
          />
          {!renderForUpdate && (
            <Field
              name="catalog"
              className="ProductPackageDetail__FormDropDown"
              component={RFCatalogDropDownInputWithHOC}
              label={t('orderSummary.basedata.fields.catalog')}
              validate={required}
            />
          )}
          {renderForUpdate && (
          <div className="ProductPackageDetail__CatalogNameContainer">
            <div className="ProductPackageDetail__CatalogNameLabel">
              {t('orderSummary.basedata.fields.catalog')}
            </div>
            <div className="ProductPackageDetail__CatalogName">
              {productPackage?.catalog?.name}
            </div>
          </div>
          )}
        </div>
        <Field name="description" className="ProductPackageDetail__Description" component={RFTextInput} label={t('orders.description')} />
        <PrimaryButton
          type="submit"
          onClick={this.handleCreateOrder}
          disabled={!dirty}
          className="ProductPackageDetail__FormSubmit"
        >
          {loading ? <FontAwesomeIcon className="" icon={['fal', 'spinner']} spin /> : buttonLabel}
        </PrimaryButton>
      </form>
    )
  }

  renderProductGroup = products => (
    <div key={products[0].id}>
      { products?.length > 1 && (
        <div className="ProductPackageDetail__ProductGroupKey">
          {`${this.props.t('orders.productGroupKey')}: ${products[0].groupKey}`}
        </div>
      )}
      <div className="ProductPackageDetail__ProductGroupTitle">
        {products[0].title}
      </div>
      <div className="ProductPackageDetail__ProductGroup">
        { products.map(product => (
          <ProductPackageProduct
            key={product.id}
            productPackage={this.props.productPackage}
            product={product}
          />
        )) }
      </div>
    </div>
  )

  renderCraft = craft => (
    <div className="ProductPackageDetail__Craft" key={craft.name}>
      <div className="ProductPackageDetail__CraftName">
        <FontAwesomeIcon className="ProductPackageDetail__CraftIcon" icon={['fal', craft.icon]} />
        {craft.name}
      </div>
      { craft?.productsByGroupKey?.map(this.renderProductGroup)}
    </div>
  )

  render = () => {
    const { productPackageProductsByCraft, t, productPackage } = this.props
    const showPackageProducts = productPackage?.id
    const { loading } = this.state
    return (
      <div className="ProductPackageDetail">
        <TopBar>
          <BackButton onClick={this.handleBack} />
        </TopBar>
        <ScrollContainer className="ProductPackageDetail__Content" scrollVertical>
          <div className="ProductPackageDetail__Heading">{t('packages.packageInfo')}</div>
          {this.renderPackageForm()}
          { showPackageProducts && (
            <>
              <div className="ProductPackageDetail__Heading">{t('packages.packageProducts')}</div>
              <div className="ProductPackageDetail__Crafts">
                {loading ? <FontAwesomeIcon className="" icon={['fal', 'spinner']} spin /> : productPackageProductsByCraft?.map(this.renderCraft)}
              </div>
            </>
          )}
        </ScrollContainer>
      </div>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: 'PACKAGE_FORM',
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: UserSelector.getCurrentUser(state),
  isInTenantContext: getIsInTenantContext(state),
  initialValues: {
    ...ownProps?.productPackage || {},
  },
  productPackageProductsByCraft: OrderSelector.getProductPackageGroupsByCrafts(state),
})

const mapDispatchToProps = {
  navigateTo: push,
  upsertPackage: upsertPackageAction,
  requestEntity: requestEntityAction,
}

const ProductPackageDetailForm = reduxForm(form)(ProductPackageDetail)

export const ProductPackageDetailCreate = connect(mapStateToProps, mapDispatchToProps)(translate()(ProductPackageDetailForm))

export const ProductPackageDetailEdit = AsyncDataLoadingHOC({
  productPackage: {
    idSelector: getSelectedProductPackageId,
    entityType: 'packages',
  },
})(ProductPackageDetailCreate)
