import { normalize } from 'normalizr'
import humps from 'humps'
import connector from './connector'

import schemas, {
  apartmentImportSchema,
  catalogSchema,
  orderSchema,
  roomSchema,
  roomSizesSchema,
  userSchema,
  teamSchema,
  tenantSchema,
  commentSchema,
  contractorSchema,
  packageSchema,
  packageProductSchema,
} from './schemas'

// const changelogs = [
//   {
//     created: new Date().toISOString(),
//     // role: ['CASE_HANDLER'],
//     action: 'CREATE',
//     order_process_state: 'Draft',
//     attribute: null,
//     old_value: null,
//     new_value: null,
//   },
//   {
//     created: new Date().toISOString(),
//     // role: ['CASE_HANDLER'],
//     action: 'UPDATE',
//     order_process_state: 'Ordered',
//     attribute: 'qty',
//     old_value: 10,
//     new_value: 15,
//   },
//   {
//     created: new Date().toISOString(),
//     // role: null,
//     action: 'UPDATE',
//     order_process_state: 'AssignTechnician',
//     attribute: 'is_required',
//     old_value: false,
//     new_value: true,
//   },
//   {
//     created: new Date().toISOString(),
//     // role: ['BUSINESS_MANAGER'],
//     action: 'UPDATE',
//     order_process_state: 'Correct',
//     attribute: 'product',
//     old_value: 1552,
//     new_value: 1554,
//   },
//   {
//     created: new Date().toISOString(),
//     // role: ['BUSINESS_MANAGER'],
//     action: 'UPDATE',
//     order_process_state: 'ReviewOrder',
//     attribute: 'description',
//     old_value: 'Beschreibung',
//     new_value: 'Beschreibung Neu',
//   },
//   {
//     created: new Date().toISOString(),
//     // role: ['TECHNICAL_MANAGER'],
//     action: 'UPDATE',
//     order_process_state: 'ApproveDraft',
//     attribute: 'price',
//     old_value: 10000,
//     new_value: 9995,
//   },
//   {
//     created: new Date().toISOString(),
//     // role: ['TECHNICAL_MANAGER', 'TECHNICIAN'],
//     action: 'UPDATE',
//     order_process_state: 'CLOSED',
//     attribute: 'qty',
//     old_value: 15,
//     new_value: 0,
//   },
//   {
//     created: new Date().toISOString(),
//     // role: ['TECHNICAL_MANAGER', 'TECHNICIAN'],
//     action: 'UPDATE',
//     order_process_state: 'OrderedCaseHandler',
//     attribute: 'qty',
//     old_value: 0,
//     new_value: 5,
//   },
//   {
//     created: new Date().toISOString(),
//     // role: ['TECHNICAL_MANAGER', 'TECHNICIAN'],
//     action: 'UPDATE',
//     order_process_state: 'OrderedCaseHandler',
//     attribute: 'qty',
//     old_value: 5,
//     new_value: 10,
//   },
//   {
//     created: new Date().toISOString(),
//     // role: ['TECHNICAL_MANAGER', 'TECHNICIAN'],
//     action: 'UPDATE',
//     order_process_state: 'RequestTechnician',
//     attribute: 'product',
//     old_value: 1554,
//     new_value: 1553,
//   },
// ]

// const getRandomChangelogsItem = () => {
//   const index = Math.floor(Math.random() * changelogs.length)
//   const role = Math.random() > .5 ? 0 : 1
//   // console.log({index, role})
//   return {
//     ...changelogs[index],
//     role: ['CASE_HANDLER', 'TECHNICIAN'][role],
//   }
// }


/* eslint-disable prefer-destructuring */
const API_ROOT = process.env.API_ROOT

const camelizeNormalize = (data, normalizeSchemas) => normalize(humps.camelizeKeys(data), normalizeSchemas)

// https://stackoverflow.com/a/18116302
const getQueryString = queries => Object
  .keys(queries)
  .filter(k => queries[k] !== null && queries[k] != undefined)
  .map(k => `${k}=${encodeURIComponent(queries[k])}`).join('&')

export const requestEntities = async (entityType, filter) => {
  const queries = getQueryString(filter || {})
  const apiEntityType = humps.decamelize(entityType, { separator: '-' })
  const payload = await connector.request(`${API_ROOT}/${apiEntityType}?${queries || ''}`, 'GET', null)
  return { ...payload, results: camelizeNormalize(payload.results, [schemas[entityType]]) }
}

export const requestEntity = async (entityType, id, filter) => {
  const queries = getQueryString(filter || {})
  const apiEntityType = humps.decamelize(entityType, { separator: '-' })
  const payload = await connector.request(`${API_ROOT}/${apiEntityType}/${id}?${queries || ''}`, 'GET', null)
  // if (entityType === 'orders' && id == 125) {
  //   payload = {
  //     ...payload,
  //     order_line_items: payload.order_line_items.map(lineItem => ({
  //       ...lineItem,
  //       changelogs: [{
  //         id: payload.id * 100 + lineItem.id,
  //         ...getRandomChangelogsItem(),
  //         order_line_item: { ...payload },
  //         author: { ...payload.owner },
  //       }]
  //     }))
  //   }
  // }
  return camelizeNormalize(payload, schemas[entityType])
}

export const deleteEntity = async (entityType, id) => {
  const apiEntityType = humps.decamelize(entityType, { separator: '-' })
  const payload = await connector.request(`${API_ROOT}/${apiEntityType}/${id}`, 'DELETE', null)
  return payload
}

// Tenant
export const updateTenant = async (tenant) => {
  const apiTenant = humps.decamelizeKeys(tenant)
  const payload = await connector.request(`${API_ROOT}/tenants/${tenant.id}`, 'PATCH', apiTenant)
  return camelizeNormalize(payload, tenantSchema)
}

// ApartmentImport
export const requestApartmentImports = async (page) => {
  const payload = await connector.request(`${API_ROOT}/apartment-imports?page=${page || 1}`, 'GET', null)
  return { ...payload, results: camelizeNormalize(payload.results, [apartmentImportSchema]) }
}

// CatalogImport
export const requestCatalogs = async (page) => {
  const payload = await connector.request(`${API_ROOT}/catalogs?page=${page || 1}`, 'GET', null)
  return { ...payload, results: camelizeNormalize(payload.results, [catalogSchema]) }
}

// Order
export const dispatchOrderAction = async (order, action) => {
  const payload = await connector.request(`${API_ROOT}/orders/${order.id}/action`, 'POST', action)
  return camelizeNormalize(payload, orderSchema)
}

export const createOrder = async (apartment) => {
  const payload = await connector.request(`${API_ROOT}/orders`, 'POST', { apartmentId: apartment.id })
  return camelizeNormalize(payload, orderSchema)
}

export const updateOrder = async (order) => {
  const apiOrder = humps.decamelizeKeys(order)
  const payload = await connector.request(`${API_ROOT}/orders/${order.id}`, 'PATCH', apiOrder)
  return camelizeNormalize(payload, orderSchema)
}

// Order Line Item
export const createLineItem = async (lineItem) => {
  const apiLineItem = humps.decamelizeKeys(lineItem)
  const payload = await connector.request(`${API_ROOT}/order-line-items`, 'POST', apiLineItem)
  return camelizeNormalize(payload, orderSchema)
}

export const updateLineItem = async (lineItem) => {
  const lineItemCopy = { ...lineItem }
  delete lineItemCopy.comments // caused maximum callstack error on humps.decamelizeKeys
  const apiLineItem = humps.decamelizeKeys(lineItemCopy)
  const payload = await connector.request(`${API_ROOT}/order-line-items/${lineItem.id}`, 'PATCH', apiLineItem)
  return camelizeNormalize(payload, orderSchema)
}

export const downloadOrderSummary = async order => connector.downloadFile(`${API_ROOT}/orders/${order.id}/download`)

// User
export const userMe = async () => {
  const payload = await connector.request(`${API_ROOT}/users/me`, 'GET', null)
  return humps.camelizeKeys(payload)
}

// Users
export const createUser = async (user) => {
  const apiUser = humps.decamelizeKeys(user)
  const payload = await connector.request(`${API_ROOT}/users`, 'POST', apiUser)
  return camelizeNormalize(payload, userSchema)
}

export const updateUser = async (user) => {
  const apiUser = humps.decamelizeKeys(user)
  const payload = await connector.request(`${API_ROOT}/users/${user.id}`, 'PATCH', apiUser)
  return camelizeNormalize(payload, userSchema)
}

// Teams
export const createTeam = async (team) => {
  const apiTeam = humps.decamelizeKeys(team)
  const payload = await connector.request(`${API_ROOT}/teams`, 'POST', apiTeam)
  return camelizeNormalize(payload, teamSchema)
}

export const updateTeam = async (team) => {
  const apiTeam = humps.decamelizeKeys(team)
  const payload = await connector.request(`${API_ROOT}/teams/${team.id}`, 'PATCH', apiTeam)
  return camelizeNormalize(payload, teamSchema)
}

export const deleteTeam = async (team) => {
  const payload = await connector.request(`${API_ROOT}/teams/${team.id}`, 'DELETE', {})
  return payload
}

// Room
export const updateRoom = async (room) => {
  // Manual denormalization necessary to avoid stack overflows in the humps library.
  // TODO: Figure out why rooms and apartments are nested so deeply
  const roomWOApartment = { ...room, apartment: room.apartment.id, roomType: room.roomType?.id }
  const apiRoom = humps.decamelizeKeys(roomWOApartment)
  const payload = await connector.request(`${API_ROOT}/rooms/${room.id}`, 'PATCH', apiRoom)
  return camelizeNormalize(payload, roomSchema)
}

export const createRoom = async (room) => {
  const apiRoom = humps.decamelizeKeys(room)
  const payload = await connector.request(`${API_ROOT}/rooms`, 'POST', apiRoom)
  return camelizeNormalize(payload, roomSchema)
}

export const deleteRoom = async (room) => {
  const payload = await connector.request(`${API_ROOT}/rooms/${room.id}`, 'DELETE', {})
  return payload
}

// RoomSizes
export const createRoomSize = async () => {
  const payload = await connector.request(`${API_ROOT}/room-sizes`, 'POST', { label: 'label', externalId: 'import' })
  return camelizeNormalize(payload, roomSizesSchema)
}

export const updateRoomSize = async (roomsize) => {
  const apiRoomSize = humps.decamelizeKeys(roomsize)
  const payload = await connector.request(`${API_ROOT}/room-sizes/${roomsize.id}`, 'PATCH', apiRoomSize)
  return camelizeNormalize(payload, roomSizesSchema)
}

export const deleteRoomSize = async (roomsize) => {
  const payload = await connector.request(`${API_ROOT}/room-sizes/${roomsize.id}`, 'DELETE', {})
  return payload
}

// Upload
export const requestUploadUrl = async documentType => connector.request(`${API_ROOT}/presigned-upload-url?type=${documentType}`)

export const getImportedData = async fileId => connector.request(`${API_ROOT}/apartments`, 'POST', { fileId })

export const createCatalog = async (fileId, filename) => connector.request(`${API_ROOT}/catalogs`, 'POST', { fileId, name: filename })

// Comment
export const createComment = async ({ lineItemId, orderHeaderId, comment }) => {
  const payload = await connector.request(`${API_ROOT}/comments`, 'POST', { text: comment, orderLineItem: lineItemId, orderHeader: orderHeaderId })
  return camelizeNormalize(payload, commentSchema)
}

// Contractor
export const createContractor = async (contractor) => {
  const apiContractor = humps.decamelizeKeys(contractor)
  const payload = await connector.request(`${API_ROOT}/contractors`, 'POST', apiContractor)
  return camelizeNormalize(payload, contractorSchema)
}

export const updateContractor = async (contractor) => {
  const apiContractor = humps.decamelizeKeys(contractor)
  const payload = await connector.request(`${API_ROOT}/contractors/${contractor.id}`, 'PATCH', apiContractor)
  return camelizeNormalize(payload, contractorSchema)
}

// Packages
export const createPackage = async (productPackage) => {
  const apiProductPackage = { ...humps.decamelizeKeys(productPackage), catalog: productPackage.catalog?.id }
  const payload = await connector.request(`${API_ROOT}/packages`, 'POST', apiProductPackage)
  return camelizeNormalize(payload, packageSchema)
}

export const updatePackage = async (productPackage) => {
  const apiProductPackage = { ...humps.decamelizeKeys(productPackage), catalog: productPackage.catalog?.id }
  const payload = await connector.request(`${API_ROOT}/packages/${productPackage.id}`, 'PATCH', apiProductPackage)
  return camelizeNormalize(payload, packageSchema)
}

export const applyPackage = async (lineItemPackage, order) => {
  const payload = await connector.request(`${API_ROOT}/orders/${order.id}/apply-package`, 'POST', { package: lineItemPackage.id })
  return camelizeNormalize(payload, orderSchema)
}

// Package Product
export const createPackageProduct = async (packageProduct) => {
  const apiPackageProduct = humps.decamelizeKeys(packageProduct)
  const payload = await connector.request(`${API_ROOT}/package-products`, 'POST', apiPackageProduct)
  return camelizeNormalize(payload, packageProductSchema)
}

export const updatePackageProduct = async (packageProduct) => {
  const apiPackageProduct = humps.decamelizeKeys(packageProduct)
  const payload = await connector.request(`${API_ROOT}/package-products/${packageProduct.id}`, 'PATCH', apiPackageProduct)
  return camelizeNormalize(payload, packageProductSchema)
}

// Reports
export const downloadReport = async (url, filename = 'report.csv') => connector.downloadFile(`${API_ROOT}${url}`, filename)
