import React from 'react'

import {
  Route,
  Switch,
} from 'react-router-dom'

import TasksOverview from 'containers/task/TasksOverview'

import ApartmentDetail from 'containers/apartment/ApartmentDetail'
import ApartmentRoomSizeSelection from 'containers/apartment/ApartmentRoomSizeSelection'
import ApartmentDetailOrders from 'components/apartment/ApartmentDetailOrders'
import ApartmentList from 'containers/apartment/ApartmentList'

import ProductPackageList from 'containers/packages/ProductPackageList'
import { ProductPackageDetailCreate, ProductPackageDetailEdit } from 'containers/packages/ProductPackageDetail'

import OrdersList from 'containers/order/OrdersList'
import OrderLineItemsList from 'containers/order/OrderLineItemsList'
import OrderSummary from 'containers/order/OrderSummary'
import OrderProductRooms from 'containers/order/OrderProductRooms'
import ReportOverview from 'containers/report/ReportOverview'

import SettingsUser from 'containers/settings/SettingsUser'
import SettingsUserInvite from 'containers/settings/SettingsUserInvite'
import SettingsUserDetail from 'containers/settings/SettingsUserDetail'

import SettingsTeam from 'containers/settings/SettingsTeam'
import { SettingsTeamDetailCreate, SettingsTeamDetailEdit } from 'containers/settings/SettingsTeamDetail'
import { SettingsContractorDetailCreate, SettingsContractorDetailEdit } from 'containers/settings/SettingsContractorDetail'

import SettingsCatalogImport from 'components/settings/SettingsCatalogImport'
import SettingsApartmentImport from 'components/settings/SettingsApartmentImport'
import SettingsProcess from 'components/settings/SettingsProcess'
import SettingsContractors from 'components/settings/SettingsContractors'

import SettingsRoomSizeConfiguration from 'containers/settings/SettingsRoomSizeConfiguration'

const Page = () => (
  <Switch>
    <Route path="/orders/:id/apply/:id" component={OrderProductRooms} />
    <Route path="/orders/:id/rooms/:id" component={OrderLineItemsList} />
    <Route path="/orders/:id" component={OrderSummary} />
    <Route path="/orders" component={OrdersList} />

    <Route path="/apartments/:id/rooms" component={ApartmentRoomSizeSelection} />
    <Route path="/apartments/:id/orders" component={ApartmentDetailOrders} />
    <Route path="/apartments/:id" component={ApartmentDetail} />
    <Route path="/apartments" component={ApartmentList} />

    <Route path="/packages/create" component={ProductPackageDetailCreate} />
    <Route path="/packages/:id" component={ProductPackageDetailEdit} />
    <Route path="/packages" component={ProductPackageList} />

    <Route path="/reports" component={ReportOverview} />

    <Route path="/settings/packages/create" component={ProductPackageDetailCreate} />
    <Route path="/settings/packages/:id" component={ProductPackageDetailEdit} />
    <Route path="/settings/packages" component={ProductPackageList} />

    <Route path="/settings/users/invite" component={SettingsUserInvite} />
    <Route path="/settings/users/:id" component={SettingsUserDetail} />
    <Route path="/settings/users" component={SettingsUser} />

    <Route path="/settings/teams/create" component={SettingsTeamDetailCreate} />
    <Route path="/settings/teams/:id" component={SettingsTeamDetailEdit} />
    <Route path="/settings/teams" component={SettingsTeam} />

    <Route path="/settings/catalog-import" component={SettingsCatalogImport} />

    <Route path="/settings/apartment-import" component={SettingsApartmentImport} />

    <Route path="/settings/process" component={SettingsProcess} />

    <Route path="/settings/contractors/create" component={SettingsContractorDetailCreate} />
    <Route path="/settings/contractors/:id" component={SettingsContractorDetailEdit} />
    <Route path="/settings/contractors" component={SettingsContractors} />

    <Route path="/settings/roomsizes" component={SettingsRoomSizeConfiguration} />
    <Route path="/" component={TasksOverview} />
  </Switch>
)

export default Page
