import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import ReportSelector from 'selectors/report'
import { getFromToDatesForTimeframe } from 'helper/timeframes'
import StackedBarChartReport from 'containers/report/StackedBarChartReport'
import DownloadCsvReport from 'containers/report/DownloadCsvReport'
import { selectTimeframeAction } from 'actions/report'
import ReportsOverviewContainer from '../ReportsOverviewContainer'
import FilterTimeframe from '../FilterTimeframe'
import FilterTeam from '../FilterTeam'
import FilterStatus from '../FilterStatus'
import FilterCraft from '../FilterCraft'
import FilterSegments from '../FilterSegments'
import { selectReportAction } from 'actions/report'

import './style.less'
// import { values } from 'lodash'

class ReportOverview extends Component {
  static propTypes = {
    selectedReport: PropTypes.shape({}),
    selectedTimeframe: PropTypes.string.isRequired,
    selectedTimeframeDates: PropTypes.shape({}).isRequired,
    selectTimeframe: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    selectedReport: null,
  }

  handleTimeframeChange = (timeframe) => {
    const { selectTimeframe } = this.props
    selectTimeframe(timeframe)
  }

  render() {
    const { selectedFilters, selectedTimeframeDates, selectedReport, reports } = this.props

    const { segments } = selectedFilters

    const hasTimeframeFilter = selectedReport?.filters?.timeframe === true
    const hasTeamsFilter = selectedReport?.filters?.teams === true
    const hasSegmentsFilter = selectedReport?.filters?.segments?.length > 1
    const hasStatusFilter = segments === 'status' && selectedReport?.filters?.status === true
    const hasCraftsFilter = selectedReport?.filters?.crafts === true

    let filter = {
      from: selectedTimeframeDates.from,
      to: selectedTimeframeDates.to,
    }
    if (hasTeamsFilter && selectedFilters.teams?.length > 0) {
      filter.teams = selectedFilters.teams.join(',')
    }
    if (hasSegmentsFilter && segments !== null) {
      filter.segments = segments
    }
    if (hasStatusFilter && selectedFilters.status?.length > 0) {
      filter.status = selectedFilters.status.join(',')
    }
    if (hasCraftsFilter && selectedFilters.crafts?.length > 0) {
      filter.crafts = selectedFilters.crafts.join(',')
    }

    return (
      <div className="ReportOverview">
        <div className="ReportOverview__LeftColumn">
          <div className="ReportOverview__ReportContainer">
            <ReportsOverviewContainer />
          </div>
        </div>
        <div className="ReportOverview__RightColumn">
          <div className="ReportOverview__Filters">
            {hasTimeframeFilter && <FilterTimeframe />}
            {hasTeamsFilter && <FilterTeam />}
            {hasSegmentsFilter && <FilterSegments segments={selectedReport?.filters?.segments} />}
            {hasStatusFilter && <FilterStatus />}
            {hasCraftsFilter && <FilterCraft />}
          </div>
          <div className="ReportOverview__ReportDetailContainer">
            {selectedReport?.detailChartType === 'STACKED_BAR' ? <StackedBarChartReport filter={filter} /> : null}
            {selectedReport?.detailChartType === 'DOWNLOAD_CSV' ? <DownloadCsvReport filter={filter} /> : null}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // const id = ownProps.match?.params?.id
  // console.log(id, state.entities.reports)
  // const selectedReport = id && state.entities.reports[id]
  //   ? state.entities.reports[id]
  //     : null
  return {
    reports: state.entities.reports,
    // selectedReport,
    selectedReport: ReportSelector.getSelectedReport(state),
    selectedTimeframe: ReportSelector.getSelectedTimeframe(state),
    selectedTimeframeDates: getFromToDatesForTimeframe(ReportSelector.getSelectedTimeframe(state)),
    selectedFilters: ReportSelector.getSelectedFilters(state),
  }
}

export default connect(mapStateToProps, {
  selectTimeframe: selectTimeframeAction,
  selectReport: selectReportAction,
})(translate()(ReportOverview))
