import React from 'react'
import PropTypes from 'prop-types'
import Textarea from 'react-textarea-autosize'

import './style.less'

const TextAreaInput = ({
  className = '',
  placeholder,
  onChange,
  value,
}) => <Textarea className={`FormTextareaInput ${className}`} placeholder={placeholder} onChange={onChange} value={value} />

TextAreaInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

TextAreaInput.defaultProps = {
  onChange: () => {},
  value: null,
  className: '',
  placeholder: '',
}

export default TextAreaInput
