
import { handleActions } from 'redux-actions'
import { selectTimeframeAction, selectReportAction, selectFiltersAction } from 'actions/report'

const reducer = handleActions({
  [selectTimeframeAction]: (state, action) => ({
    ...state,
    selectedTimeframe: action.payload,
  }),
  [selectReportAction]: (state, action) => ({
    ...state,
    selectedReport: action.payload,
  }),
  [selectFiltersAction]: (state, action) => ({
    ...state,
    selectedFilters: action.payload,
  }),
}, {
  selectedTimeframe: 'THIS_MONTH',
  selectedReport: null,
  selectedFilters: {
    timeframe: 'THIS_MONTH',
    teams: [],
    teamsAccumulated: false,
    teamsCount: 0,
    status: [],
    crafts: [],
    segments: null, // null | numbers | absolute | status | users
  }
})

export default reducer
