import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { TopBar } from 'components/common/ToolBar'
import BackButton from 'components/common/BackButton'
import UserEditForm from 'containers/form/UserEditForm'

import { getSelectedUserId } from 'selectors/navigation'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'

import './style.less'

class SettingsUserDetail extends Component {
  static propTypes = {
    user: PropTypes.shape({}).isRequired,
    ...i18nPropTypes,
  }

  handleBackButtonClick = () => {
    const { goBackTo } = this.props
    goBackTo('/settings/users')
  }

  render() {
    const { user } = this.props

    return (
      <div className="SettingsUserDetail">
        <TopBar>
          <BackButton onClick={this.handleBackButtonClick} />
        </TopBar>
        <UserEditForm initialValues={user} />
      </div>
    )
  }
}

export default AsyncDataLoadingHOC({
  user: {
    idSelector: getSelectedUserId,
    entityType: 'users',
  },
})(connect(null, { goBackTo: push })(translate()(SettingsUserDetail)))
