import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TopBar } from 'components/common/ToolBar'
import BackButton from 'components/common/BackButton'
import UserInviteForm from 'containers/form/UserInviteForm'

import './style.less'

class SettingsUserInvite extends Component {
  static propTypes = {
    ...i18nPropTypes,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  handleBackButtonClick = () => {
    this.props.goBackTo('/settings/users')
  }

  render() {
    const { loading } = this.state

    return (
      <div className="SettingsUserInvite">
        <TopBar>
          <BackButton onClick={this.handleBackButtonClick} />
        </TopBar>
        {loading && <FontAwesomeIcon className="OrderBaseData__Spinner" icon={['fal', 'spinner']} spin />}
        {!loading && (
          <UserInviteForm />
        )}
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  goBackTo: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SettingsUserInvite))
