import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'i18n'
import FilterButton from '../FilterButton'
import FilterGroup from '../FilterGroup'
import ReportSelector from 'selectors/report'
import { selectFiltersAction } from 'actions/report'
import { processStateOptions, statusColors } from '../../../helper/viewHelper'

const FilterStatus = ({ selectedFilters, selectFilters, t }) => {

  const options = processStateOptions

  const onClick = (option) => {
    let { status } = selectedFilters
    if (status.includes(option)) {
      status = status.filter(s => s !== option)
    } else {
      status.push(option)
    }
    selectFilters({
      ...selectedFilters,
      status,
    })
  }

  return <FilterGroup label={t(`status.title`)}>
    {options.map((option) => (
      <FilterButton
        key={option}
        active={selectedFilters.status?.includes(option)}
        onClick={() => onClick(option)}
        color={statusColors[option]}
      >
        {t(`process.states.${option}`)}
      </FilterButton>
    ))}
  </FilterGroup>
}

const mapStateToProps = (state) => ({
  selectedFilters: ReportSelector.getSelectedFilters(state),
})

const mapDispatchToProps = {
  selectFilters: selectFiltersAction
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FilterStatus))