import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PrimaryButton } from 'components/common/Button'
import { TopBar } from 'components/common/ToolBar'
import Filter from 'components/common/Filter'
import SearchField from 'components/common/SearchField'
import ProcessStateFilter from 'containers/process/ProcessStateFilter'

import { showModal } from 'actions/modal'

import SelectApartmentModal from 'containers/modal/SelectApartmentModal'

import OrdersTable from 'containers/order/OrdersTable'

import UserSelector from 'selectors/user'

import './style.less'

const VIEW_ID = 'ORDERS'
const MODAL_ID = 'ORDERS_CREATE_APARTMENT_MODAL'

class OrdersList extends Component {
  static propTypes = {
    ...i18nPropTypes,
    showModal: PropTypes.func.isRequired,
    isCaseHandler: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
    }
  }

  handleShowModal = () => this.props.showModal(MODAL_ID)

  handleOnSubmit = (value) => {
    this.setState({
      searchValue: value,
    })
  }

  render() {
    const { t, isCaseHandler } = this.props
    const { searchValue } = this.state

    return (
      <div className="Orders">
        <TopBar>
          <Filter>
            <ProcessStateFilter viewId={VIEW_ID} />
          </Filter>
          <SearchField className="Orders__SearchField" onSubmit={this.handleOnSubmit} />
          { isCaseHandler && <PrimaryButton onClick={this.handleShowModal}>{t('orders.create')}</PrimaryButton>}
        </TopBar>
        <OrdersTable viewId={VIEW_ID} filters={{ search: searchValue }} />
        <SelectApartmentModal id={MODAL_ID} disableCloseButton />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isCaseHandler: UserSelector.getRoles(state).includes('CASE_HANDLER'),
})

const mapDispatchToProps = {
  showModal,
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrdersList))
