import React from 'react'

const ReportLabel = (props) => {
  const { x, y, width } = props

  return (
    <>
      <g transform={`translate(${x + width / 2},${y + 15})`}>
        <rect x={0} y={-15} width={1} height={10} fill='#000' />
        <g transform={`rotate(-45)`}>
          {/* <rect x={0} y={-6} width={100} height={12} fill='#f00' /> */}
          <text fontSize={12} y={6} textAnchor="end">{props.label}</text>
        </g>
      </g>
    </>
  )
}

export default ReportLabel