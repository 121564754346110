import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import modifyClassName from 'helper/modifyClassName'
import OrderCommentIcon from 'components/order/OrderCommentIcon'
import OrderLineItemInfos from 'components/common/OrderLineItemInfos'

import './style.less'

class Product extends Component {
  static propTypes = {
    product: PropTypes.shape({}),
    craftName: PropTypes.string,
    craft: PropTypes.shape({}),
    lineItem: PropTypes.shape({}),
    expanded: PropTypes.bool,
    onSelect: PropTypes.func,
    isInOverview: PropTypes.bool.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    expanded: false,
    craft: null,
    lineItem: null,
    product: null,
    onSelect: null,
    craftName: '',
  }

  handleSelectProduct = () => {
    const { product, onSelect } = this.props
    onSelect?.(product)
  }

  render = () => {
    const {
      product,
      lineItem,
      expanded,
      craftName,
      isInOverview,
    } = this.props

    const price = (lineItem?.price * lineItem?.qty) || 0
    const unitPrice = lineItem?.price || product.price
    const changelogsSummary = lineItem?.changelogs
      ? lineItem.changelogs?.reduce((acc, item) => {
        if (item.orderProcessState === 'Draft') {
          return acc
        }
        // if (item.role?.includes('TECHNICIAN')) {
        acc.updatedByTechnican = true

        if (item.action === 'CREATE') {
          acc.addedByTechnican = true
        } else if (item.attribute === 'qty') {
          const oldValue = parseFloat(item.oldValue)
          const newValue = parseFloat(item.newValue)
          if (oldValue === 0 && newValue > 0) {
            acc.addedByTechnican = true
          } else if (oldValue > 0 && newValue === 0) {
            acc.removedByTechnican = true
          }
        }
        // }
        return acc
      }, {})
      : {}

    const used = lineItem?.qty > 0
    let mod = used
      ? changelogsSummary.addedByTechnican
        ? { addedByTechnican: true }
        : changelogsSummary.removedByTechnican
          ? { removedByTechnican: true }
          : changelogsSummary.updatedByTechnican
            ? { updatedByTechnican: true }
            : { used }
      : changelogsSummary.removedByTechnican
        ? { removedByTechnican: true }
        : {}
    return (
      <button className={modifyClassName('Product', mod)} onClick={this.handleSelectProduct} type="button">
        <div className="Product__ExternalId">{product.externalId}</div>
        <div className={modifyClassName('Product__Title', { expanded })}>
          <div>
            {product.title}
          </div>
          {isInOverview && (
            <div className="Product__SubTitle">
              {craftName}
            </div>
          )}
        </div>
        {(lineItem?.qty > 0 || expanded) && (
          <OrderLineItemInfos
            price={price}
            qty={lineItem?.qty || 0}
            unit={product.unit}
            unitPrice={unitPrice}
            isRequired={lineItem?.isRequired}
          />)}
        {(lineItem?.qty > 0 || lineItem?.comments?.length > 0) &&
          <OrderCommentIcon badgeCount={lineItem?.comments?.length} />
        }
      </button>)
  }
}

export default translate()(Product)
