import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'
import { updateTenantAction } from 'actions/tenants'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import AuthenticationSelector from 'selectors/authentication'
import { TopBar } from 'components/common/ToolBar'
import Filter from 'components/common/Filter'
import { PrimaryButton } from 'components/common/Button'
import TableDataView from 'containers/common/TableDataView'
import { userDisplayName } from 'helper/viewHelper'
import BoolValue from 'components/common/BoolValue'
import TableDataViewDeleteButton from 'containers/common/TableDataViewDeleteButton'
import { RFUserDropDownInputWithHOC } from 'containers/input/RFUserDropDownInput'

import './style.less'

const VIEW_ID = 'TEAMS'
const ENTITY_TYPE = 'teams'

class SettingsTeam extends Component {
  static propTypes = {
    updateTenant: PropTypes.func.isRequired,
    activeTenant: PropTypes.shape({}).isRequired,
    ...i18nPropTypes,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  handleCreateTeam = () => this.props.push('/settings/teams/create')

  handleSelectEntity = entity => this.props.push(`/settings/teams/${entity.id}`)

  handleDepartmentHeadChange = async (user) => {
    const { updateTenant, activeTenant } = this.props
    await this.setState({ loading: true })
    await updateTenant({ id: activeTenant.id, departmentHead: user.id })
    await this.setState({ loading: false })
  }

  render() {
    const { t, activeTenant } = this.props
    const { loading } = this.state

    const columns = [
      { header: t('table.teamName'), accessor: 'name' },
      { header: t('table.isCaseHandler'), accessor: team => <BoolValue value={team.isCaseHandler} /> },
      { header: t('table.isTechnician'), accessor: team => <BoolValue value={team.isTechnician} /> },
      { header: t('table.manager'), accessor: team => userDisplayName(team.manager) },
      { header: t('buttons.delete'), accessor: team => <TableDataViewDeleteButton entity={team} entityType={ENTITY_TYPE} /> },
    ]
    return (
      <div className="SettingsOrganisation">
        <TopBar>
          <RFUserDropDownInputWithHOC onChange={this.handleDepartmentHeadChange} label={t('settings.organization.departmentHead')} isLoading={loading} value={activeTenant.departmentHead} />
          <Filter />
          <PrimaryButton onClick={this.handleCreateTeam}>{t('buttons.createTeam')}</PrimaryButton>
        </TopBar>
        <TableDataView
          viewId={VIEW_ID}
          entityType={ENTITY_TYPE}
          columns={columns}
          onSelectEntity={this.handleSelectEntity}
        />
      </div>
    )
  }
}

const mapDispatchToProps = {
  push,
  updateTenant: updateTenantAction,
}

const connectedComponent = connect(null, mapDispatchToProps)(translate()(SettingsTeam))

export default AsyncDataLoadingHOC({
  activeTenant: {
    idSelector: AuthenticationSelector.getActiveTenantId,
    entityType: 'tenants',
  },
})(connectedComponent)
