import React, { Component } from 'react'
import PropTypes from 'prop-types'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

class TextInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    suffix: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    type: 'text',
    label: '',
    errorMessage: null,
    onChange: null,
    onBlur: null,
    value: null,
    suffix: null,
    disabled: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  handleFocus = () => {
    this.setState({
      focused: true,
    })
  }

  handleBlur = () => {
    this.setState({
      focused: false,
    })
  }

  setRef = (ref) => {
    this.input = ref
  }

  render() {
    const {
      className,
      type,
      label,
      value,
      onChange,
      errorMessage,
      suffix,
      disabled,
      onBlur,
    } = this.props

    const { focused } = this.state
    const isFocused = focused || (value !== null && value !== '')

    return (
      <div className={`TextInput ${className}`}>
        <div className={modifyClassName('TextInput__InputContainer', { focused: isFocused })}>
          <input className="TextInput__Input" ref={this.setRef} type={type} onFocus={this.handleFocus} onChange={onChange} onBlur={onBlur} value={value} disabled={disabled} />
          <span className={modifyClassName('TextInput__Label', { focused: isFocused })}>
            {label}
          </span>
          {suffix && <div className={modifyClassName('TextInput__Suffix', { focused: isFocused })}>{suffix}</div>}
        </div>
        {errorMessage && <div className="TextInput__FieldError">{errorMessage}</div>}
      </div>
    )
  }
}

export default TextInput
