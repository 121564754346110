// https://stackoverflow.com/a/901144
// modified to pass linter
const getParamValueByName = (name, url) => {
  const paramName = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return null
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default getParamValueByName
