import React from 'react'

import Page from 'containers/Page'
import SideMenu from 'containers/SideMenu'

import { DragDropContext } from 'react-dnd'
import { shouldUseTouch } from 'helper/deviceFeatures'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'

import './style.less'

const App = () => (
  <div className="App">
    <div className="App__ViewPort">
      <SideMenu />
      <div className="App__PageWrapper">
        <Page />
      </div>
    </div>
  </div>
)

/*
<div className="App__BottomText">
  <div className="App__BottomTextLeft">{t('app.version', { version: process.env.VERSION_LABEL })}</div>
</div>
*/

export default DragDropContext(
  shouldUseTouch() ? TouchBackend : HTML5Backend,
)(App)
