import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'

import { getSelectedTeamId } from 'selectors/navigation'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'

import TeamForm from 'containers/form/TeamForm'
import { TopBar } from 'components/common/ToolBar'
import BackButton from 'components/common/BackButton'

import './style.less'

const SettingsTeamDetail = ({ handleBack, team }) => (
  <div className="SettingsTeamDetail">
    <TopBar>
      <BackButton onClick={handleBack} />
    </TopBar>
    <TeamForm initialValues={team} onSuccess={handleBack} />
  </div>
)

SettingsTeamDetail.propTypes = {
  team: PropTypes.shape({}),
  handleBack: PropTypes.func.isRequired,
  ...i18nPropTypes,
}

SettingsTeamDetail.defaultProps = {
  team: {},
}

const mapDispatchToProps = dispatch => ({
  handleBack: () => dispatch(push('/settings/teams')),
})

export const SettingsTeamDetailCreate = connect(null, mapDispatchToProps)(translate()(SettingsTeamDetail))

export const SettingsTeamDetailEdit = AsyncDataLoadingHOC({
  team: {
    idSelector: getSelectedTeamId,
    entityType: 'teams',
  },
})(SettingsTeamDetailCreate)
