import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import {
  Field,
  reduxForm,
  propTypes as reduxPropTypes,
  getFormValues,
} from 'redux-form'
import { values as _values, keys } from 'lodash'
import { required } from 'helper/validators'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'

import { updateContractorAction, createContractorAction } from 'actions/contractor'

import { PrimaryButton } from 'components/common/Button'
import RFTextInput from 'components/common/RFTextInput'
import ContractorCatalogField from 'components/common/ContractorCatalogField'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class ContractorForm extends Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    updateContractor: PropTypes.func.isRequired,
    createContractor: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    formValues: PropTypes.shape({}),
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  /* eslint-disable react/default-props-match-prop-types */
  /*
    it is a false positive by the linter since it does not
    look into the destructured props (...reduxPropTypes)
  */
  static defaultProps = {
    onSuccess: () => {},
    formValues: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  createApiModel = (values) => {
    const result = {
      ...values,
      catalogs: keys(values?.catalogs).map((catalogId) => {
        if (values.catalogs[catalogId]?.isChecked) {
          return { id: catalogId.slice(0, -1), index: values.catalogs[catalogId]?.indexValue }
        }
        return null
      }).filter(catalog => catalog),
    }
    return result
  }

  onSubmit = async (values) => {
    const {
      updateContractor,
      createContractor,
      initialValues,
      onSuccess,
    } = this.props

    const id = initialValues?.id
    await this.setState({ loading: true })
    if (id !== null && id !== undefined) {
      await updateContractor(this.createApiModel(values))
    } else {
      await createContractor(this.createApiModel(values))
    }
    await this.setState({ loading: false })
    onSuccess()
  }

  render() {
    const {
      t,
      handleSubmit,
      dirty,
      initialValues,
      catalogs,
      formValues,
    } = this.props

    const { loading } = this.state
    const id = initialValues?.id || null
    
    const buttonLabel = id !== null ? t('buttons.save') : t('buttons.createContractor')
    const formCatalogs = formValues?.catalogs || []

    return (
      <form className="ContractorForm" onSubmit={handleSubmit(this.onSubmit)}>
        <ScrollContainer className="ContractorForm__Fields" scrollVertical>
          <Field name="name" className="ContractorForm__Field" component={RFTextInput} label={t('table.contractorName')} validate={required} />
          <Field name="addressStreetFirst" className="ContractorForm__Field" component={RFTextInput} label={t('table.address')} validate={required} />
          <Field name="addressStreetSecond" className="ContractorForm__Field" component={RFTextInput} label={t('table.addressSecond')} />
          <Field name="addressPostcode" className="ContractorForm__Field" component={RFTextInput} label={t('table.postalcode')} validate={required} />
          <Field name="addressCity" className="ContractorForm__Field" component={RFTextInput} label={t('table.city')} validate={required} />

          {_values(catalogs).map(catalog => <ContractorCatalogField key={catalog.id} catalog={catalog} isChecked={formCatalogs[`${catalog.id}X`]?.isChecked || false} />)}
        </ScrollContainer>
        <div className="ContractorForm__Footer">
          <PrimaryButton type="submit" onClick={this.handleCreateOrder} disabled={!dirty}>
            {loading ? <FontAwesomeIcon className="ContractorForm__Spinner" icon={['fal', 'spinner']} spin /> : buttonLabel}
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: 'CONTRACTOR_FORM',
}

const mapStateToProps = state => ({
  formValues: getFormValues('CONTRACTOR_FORM')(state),
})

const formComponent = reduxForm(form)(ContractorForm)
const connectedComponent = connect(mapStateToProps, {
  updateContractor: updateContractorAction,
  createContractor: createContractorAction,
})(formComponent)

const translatedComponent = translate()(connectedComponent)

export default AsyncDataLoadingHOC({
  catalogs: {
    entityType: 'catalogs',
  },
})(translate()(translatedComponent))
