/* global window, document, DocumentTouch, navigator */

const touchEvents = ('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)

export const shouldUseTouch = () => {
  const forceMouse = window.location.search.includes('force=mouse')
  const forceTouch = window.location.search.includes('force=touch')
  return !forceMouse && (touchEvents || forceTouch)
}

export const isUnsupportedBrowser = () => {
  const userAgent = (navigator.userAgent || '').toLowerCase()
  const appVersion = (navigator.userAgent || '').toLowerCase()
  return userAgent.indexOf('msie') > -1
    || appVersion.indexOf('trident/') > -1
    || (userAgent.indexOf('safari') > -1 && appVersion.indexOf('version/10.') > -1)
}
