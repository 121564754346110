import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, propTypes as reduxPropTypes } from 'redux-form'
import { push } from 'connected-react-router'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { createUserAction } from 'actions/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import RFTextInput from 'components/common/RFTextInput'
import SharedUserFormFields from 'components/common/SharedUserFormFields'
import { PrimaryButton } from 'components/common/Button'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class UserInviteForm extends Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    createUserAction: PropTypes.func.isRequired,
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errorMessage: null,
    }
  }

  onSubmit = async (values) => {
    const {
      createUserAction: createUser,
      goBackTo,
    } = this.props

    await this.setState({ loading: true })
    try {
      await createUser(values)
      goBackTo('/settings/users')
    } catch (e) {
      this.setState({ errorMessage: e.message })
    }
    await this.setState({ loading: false })
  }

  render() {
    const {
      t,
      handleSubmit,
      dirty,
    } = this.props

    const { loading, errorMessage } = this.state

    return (
      <form className="UserInviteForm" onSubmit={handleSubmit(this.onSubmit)}>
        <ScrollContainer className="UserInviteForm__Fields" scrollVertical>
          <Field name="email" className="SharedUserFormFields__Field" component={RFTextInput} label={t('table.email')} />
          <SharedUserFormFields />
          {errorMessage && <div className="UserInviteForm__Error">{errorMessage}</div>}
        </ScrollContainer>
        <div className="UserInviteForm__Footer">
          <PrimaryButton type="submit" onClick={this.handleCreateOrder} disabled={!dirty}>
            {loading ? <FontAwesomeIcon className="UserInviteForm__Spinner" icon={['fal', 'spinner']} spin /> : t('buttons.invite')}
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: 'USER_INVITE_FORM',
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  createUserAction,
  goBackTo: push,
}

const formComponent = reduxForm(form)(UserInviteForm)
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(formComponent)
const translatedComponent = translate()(connectedComponent)

export default translatedComponent
