import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'i18n'
import './style.less'

export const ReportSummary = ({ data, valueType, t }) => {
  if (!data) {
    return null
  }

  return (
    <div className="ReportSummary">
      {Object.keys(data).map(key => {
        let value = data[key]
        switch (key) {
          case 'ordersProfitable':
          case 'ordersUnprofitable':
          case 'ordersTotal':
            valueType = 'NUMBER'
            break
          case 'ordersRentability':
            valueType = 'PERCENT'
            value = Math.round(value * 100)
            break
        }
        return (
          <div key={key} className="ReportSummary__Item">
            <div className="ReportSummary__ItemData">
              {value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
              })} {t(`reports.overview.valueType.${valueType}`)}
            </div>
            <div className="ReportSummary__ItemLabel">
              {t(`reports.overview.${key}`)}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ReportSummary))