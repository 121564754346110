import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { isEmpty, values } from 'lodash'

import { applyPackageAction } from 'actions/packages'

import OrderLineItemProductGroup from 'components/order/OrderLineItemProductGroup'
import FreeTextLineItemForm from 'containers/form/FreeTextLineItemForm'
import CommentContainer from 'containers/common/CommentContainer'

import { getSelectedOrderId } from 'selectors/navigation'
import ProductPackageSelector from 'selectors/packages'

import OrderSelector from 'selectors/order'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'

import ToolBarItem from 'components/common/ToolBarItem'
import PackageItem from 'components/common/PackageItem'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class OrderProductSummary extends Component {
  static propTypes = {
    ...i18nPropTypes,
    order: PropTypes.shape({}),
    packages: PropTypes.shape({}),
    groupedLineItems: PropTypes.arrayOf(PropTypes.shape({})),
    applyPackage: PropTypes.func.isRequired,
    freeLineItems: PropTypes.shape({}).isRequired,
  }

  static defaultProps = {
    packages: [],
    order: null,
    groupedLineItems: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      expandedProductGroupKey: null,
      loadingId: null,
    }
  }

  handleSelectProduct = (productGroupKey) => {
    const { expandedProductGroupKey } = this.state
    this.setState({ expandedProductGroupKey: expandedProductGroupKey === productGroupKey ? null : productGroupKey })
  }

  handleOnPackageSelect = async (lineItemPackage) => {
    const { applyPackage, order } = this.props
    this.setState({
      loadingId: lineItemPackage.id,
    })
    await applyPackage(lineItemPackage, order)
    this.setState({
      loadingId: null,
    })
  }

  renderProduct = (productGroup, room) => {
    const { order } = this.props
    const { id } = order
    if (!productGroup) return null
    return (
      <OrderLineItemProductGroup
        key={productGroup.productGroupKey}
        productGroup={productGroup}
        expanded={this.state.expandedProductGroupKey === productGroup.productGroupKey}
        onSelectProduct={this.handleSelectProduct}
        room={room}
        orderId={String(id)}
        isInOverview
      />)
  }

  renderCraft = ({
    name,
    slugifiedName,
    productGroups,
  },
  room) => (
    <div className="OrderProductSummary__Craft" key={name}>
      <div id={slugifiedName} />
      <div>
        { productGroups.map(group => this.renderProduct(group, room)) }
      </div>
    </div>
  )

  renderTopBarIcon = (orderId, roomId, craft) => (
    <ToolBarItem
      key={craft.name}
      to={`#${craft.slugifiedName}`}
      label={craft.name}
      icon={craft.icon}
      badgeCount={craft.badgeCount || 0}
    />
  )

  renderRoomLineItems = (room) => {
    const { freeLineItems, order } = this.props
    return (
      <div className="OrderProductSummary__Room" key={room.id}>
        <div className="OrderProductSummary__RoomName">{room.name}</div>
        { room.lineItemsByProduct.map(craft => this.renderCraft(craft, room)) }
        { !isEmpty(freeLineItems[room.id]) && (
          <FreeTextLineItemForm
            form={`FreeTextLineItemForm-${room.id}`}
            key={room.id}
            initialValues={values(freeLineItems[room.id])}
            orderId={String(order.id)}
            roomId={String(room.id)}
            isInOverview
          />
        )}
      </div>
    )
  }

  renderProductPackages = () => {
    const { packages, t } = this.props
    const { loadingId } = this.state

    if (values(packages).length === 0) {
      return null
    }

    return (
      <div className="OrderProductSummary__PackagesContainer">
        <div className="OrderProductSummary__RoomName">
          {t('orderSummary.overview.packagesTitle')}
        </div>
        {values(packages).map(lineItemPackage => (
          <PackageItem
            key={lineItemPackage.id}
            lineItemPackage={lineItemPackage}
            onClick={this.handleOnPackageSelect}
            loading={loadingId === lineItemPackage.id}
            loadingMessage={t('orderSummary.overview.applyPackage')}
          />
        ))}
      </div>
    )
  }

  renderOrderHeaderComments = () => {
    const { order, t } = this.props
    return (
      <div className="OrderProductSummary__PackagesContainer">
        <div className="OrderProductSummary__RoomName">
          {t('orderSummary.overview.orderComments')}
        </div>
        <CommentContainer orderHeader={order} />
      </div>
    )
  }

  render = () => {
    const {
      groupedLineItems,
      t,
    } = this.props
    return (
      <div className="OrderProductSummary">
        <ScrollContainer className="OrderProductSummary__ScrollContainer" scrollVertical>
          {this.renderProductPackages()}
          {this.renderOrderHeaderComments()}
          { !groupedLineItems || isEmpty(groupedLineItems) ? (
            <div className="OrderProductSummary__NoLineItems">
              {t('orderComment.noLineItemsSelected')}
            </div>
          ) : (
            groupedLineItems.map(room => this.renderRoomLineItems(room))
          )}
        </ScrollContainer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  groupedLineItems: OrderSelector.getProductGroupsByRooms(state),
  freeLineItems: OrderSelector.getFreeOrderLineItemsByRooms(state),
})

export default AsyncDataLoadingHOC({
  order: {
    idSelector: getSelectedOrderId,
    entityType: 'orders',
  },
  packages: {
    entitySelector: ProductPackageSelector.getPackagesForSelectedOrder,
    entityType: 'packages',
  },
})(connect(mapStateToProps, { applyPackage: applyPackageAction })(translate()(OrderProductSummary)))
