/* global document, window */
import Raven from 'raven-js'
import 'whatwg-fetch'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import Authentication from 'containers/auth/Authentication'

import Callback from 'components/auth/Callback'

import { I18nextProvider } from 'react-i18next'

import 'less/style.less'

import './fontawesome'

import { getHistory, initializeApp } from 'redux-app'
import { getStore } from 'store'
import i18n from 'i18n' // initialized i18next instance

import Auth from 'integrations/Auth'

// import { makeServer } from './server'

// if (process.env.NODE_ENV !== 'production') {
//   makeServer({
//     environment: process.env.NODE_ENV,
//   })
// }

initializeApp()

const rootElement = document.getElementById('root')

const auth0 = new Auth({ store: getStore() })

const handleAuthentication = (nextState) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth0.handleAuthentication()
  }
}

const handleLogout = () => {
  auth0.logout()
}

Raven.context(() => {
  render(
    <Provider store={getStore()}>
      <I18nextProvider i18n={i18n}>
        <ConnectedRouter history={getHistory()}>
          <Switch>
            <Route
              exact
              path="/callback"
              render={(props) => {
                handleAuthentication(props)
                return <Callback {...props} />
              }
              }
            />
            <Route
              exact
              path="/logout"
              render={(props) => {
                handleLogout(props)
                return <Callback {...props} />
              }
              }
            />
            <Route path="/" render={() => <Authentication auth0={auth0} />} />
          </Switch>
        </ConnectedRouter>
      </I18nextProvider>
    </Provider>,
    rootElement,
  )
})
