import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import moment from 'moment'

import {
  reduxForm,
  Field,
  propTypes as reduxPropTypes,
} from 'redux-form'

import { PrimaryButton, LinkButton } from 'components/common/Button'
import { Title } from 'components/process/ProcessTitle'

import RFDatePickerInput from 'containers/input/RFDatePickerInput'

import './style.less'

const FORM_NAME = 'CLOSE_ORDER_ACTION_FORM'

const OrderedStateHOC = ({ enableBackButton }) => {

  class OrderedState extends Component {
    static propTypes = {
      order: PropTypes.shape({}),
      onDispatchAction: PropTypes.func.isRequired,
      ...i18nPropTypes,
      ...reduxPropTypes,
    }

    static defaultProps = {
      order: null,
    }

    handleSubmitAction = async (values) => {
      const { order, onDispatchAction } = this.props
      onDispatchAction(order, { action: 'Close', data: { closedDate: values.closedDate } })
    }

    handleBack = async () => {
      const { order, onDispatchAction } = this.props
      onDispatchAction(order, { action: 'Back' })
    }

    render = () => (
      <>
        {!enableBackButton && <Title>{this.props.t('process.orderedStateTitle')}</Title>}
        {enableBackButton && (
          <LinkButton type="button" onClick={this.handleBack} className="OrderedState__BackButton">
            {this.props.t('process.backToDraft')}
          </LinkButton>
        )}
        <form className="OrderedState" onSubmit={this.props.handleSubmit(this.handleSubmitAction)}>
          <Field
            className="OrderedState__Field"
            name="closedDate"
            component={RFDatePickerInput}
            label={this.props.t('process.closedDate')}
          />
          <PrimaryButton type="submit">
            {this.props.t('process.closeOrder')}
          </PrimaryButton>
        </form>
      </>
    )
  }

  const form = {
    enableReinitialize: true,
    form: FORM_NAME,
  }
  
  const mapStateToProps = () => ({
    initialValues: {
      closedDate: moment().format('YYYY-MM-DD'),
    },
  })
  return connect(mapStateToProps)(reduxForm(form)(translate()(OrderedState)))
}

export const OrderedState = OrderedStateHOC({ enableBackButton: false })
export const OrderedStateCaseHandler = OrderedStateHOC({ enableBackButton: true })
