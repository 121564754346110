import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'

import Filter from 'components/common/Filter'
import SearchField from 'components/common/SearchField'
import { TopBar } from 'components/common/ToolBar'

import TableDataView from 'containers/common/TableDataView'

import './style.less'

const VIEW_ID = 'APARTMENTS'
const ENTITY_TYPE = 'apartments'


class ApartmentList extends Component {
  static propTypes = {
    ...i18nPropTypes,
    push: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
    }
  }

  handleOnSubmit = (value) => {
    this.setState({
      searchValue: value,
    })
  }

  handleSelectEntity = entity => this.props.push(`/apartments/${entity.id}`)

  render() {
    const { t } = this.props
    const { searchValue } = this.state

    const columns = [
      { header: t('table.address'), accessor: 'address' },
      { header: t('table.externalId'), accessor: 'externalId' },
      { header: t('table.numberOfRooms'), accessor: apartment => apartment.numberOfRooms },
      { header: t('table.size'), accessor: apartment => `${apartment.size?.toFixed(2)}m²`, style: { textAlign: 'right' } },
    ]

    return (
      <div className="ApartmentList">
        <TopBar>
          <Filter>
            <div>
              <SearchField onSubmit={this.handleOnSubmit} />
            </div>
          </Filter>
        </TopBar>
        <TableDataView viewId={VIEW_ID} entityType={ENTITY_TYPE} columns={columns} onSelectEntity={this.handleSelectEntity} filters={{ search: searchValue }} />
      </div>
    )
  }
}

const mapDispatchToProps = {
  push,
}

export default connect(null, mapDispatchToProps)(translate()(ApartmentList))
