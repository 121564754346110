import React, { Component } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { isString } from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import ValueWithLabel from 'components/common/ValueWithLabel'
import ProcessStateContainer from 'containers/process/ProcessStateContainer'
import { NavLink } from 'react-router-dom'
import { PrimaryButton } from 'components/common/Button'
import './style.less'

const NUMBERS = [
  { key: 'yieldInvestOnly', label: 'yieldWithoutSowieso', type: 'PERCENT' },
  { key: 'amortization', label: 'amortisationInYears', type: '' },
  { key: 'priceTotal', label: 'remediationOverall', type: 'CURRENCY' },
  { key: 'priceNotRequired', label: 'thereOfInvestment', type: 'CURRENCY' },
  { key: 'priceRequired', label: 'thereOfSowieso', type: 'CURRENCY' },
]

const renderLabel = (t, number, value) => {
  let v = isString(value) ? parseFloat(value) : value
  if (number.type === 'CURRENCY') {
    v = `${numeral(value / 100).format('0,0.00')} €`
  } else if (number.type === 'PERCENT') {
    v = `${numeral(value * 100).format('0,0.0')} %`
  } else {
    v = numeral(v).format('0,0.0')
  }
  return (
    <ValueWithLabel
      key={number.key}
      className="OrderProfitability__KPI"
      valueClassName="OrderProfitability__KPIValue"
      label={t(`orderProfitability.${number.label}`)}
      value={v}
    />
  )
}

class OrderProfitability extends Component {
  static propTypes = {
    order: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  static defaultProps = {
    order: null,
  }

  canBeCalculated = () => {
    const { order } = this.props
    if (order === null) return false
    const { rentWOrder, rentWoOrder } = order
    return order !== null && rentWOrder !== null && rentWoOrder !== null
  }

  renderCanNotCaluclateError = () => (
    <div className="OrderProfitability__Container">
      <div className="OrderProfitability__ErrorMessage">
        {this.props.t('errors.canNotCalculateYield')}
      </div>
      <NavLink className="OrderProfitability__GoToButton" to={`/orders/${this.props?.order?.id}/basedata`}>
        <PrimaryButton>
          {this.props.t('buttons.goToOrderBaseData')}
        </PrimaryButton>
      </NavLink>
    </div>
  )

  showError = () => alert(this.props.order?.numbers?.yieldError)

  renderError = () => (
    <div className="OrderProfitability__Container">
      <button className="OrderProfitability__ErrorMessage" onClick={this.showError} type="button">
        {this.props.t('errors.yieldError')}
      </button>
    </div>
  )

  render = () => {
    const { order, t } = this.props
    const error = order?.numbers?.yieldError
    const canBeCalculated = this.canBeCalculated()
    return (
      <div className="OrderProfitability">
        {order && (
          <>
            {error && this.renderError()}
            {(!error && canBeCalculated) && (
              <div className="OrderProfitability__Row">
                {NUMBERS.slice(0, 2).map(num => renderLabel(t, num, order.numbers?.[num.key]))}
              </div>
            )}
            {(!error && !canBeCalculated) && this.renderCanNotCaluclateError()}
            <div className="OrderProfitability__Row">
              {NUMBERS.slice(2, 5).map(num => renderLabel(t, num, order.numbers?.[num.key]))}
            </div>
          </>
        )}
        <div className="OrderProfitability__Footer">
          <ProcessStateContainer order={order} />
        </div>
      </div>
    )
  }
}

export default translate()(OrderProfitability)
