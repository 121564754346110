import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const ToolBar = ({ borderLineModifier, children, className }) => (
  <div className={`ToolBar ToolBar--${borderLineModifier}`}>
    <div className={`ToolBar__Container ${className}`}>
      {children}
    </div>
  </div>
)

ToolBar.propTypes = {
  borderLineModifier: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

ToolBar.defaultProps = {
  className: '',
  borderLineModifier: '',
  children: [],
}

export default ToolBar

export const TopBar = props => <ToolBar {...props} borderLineModifier="bottom" />
export const BottomBar = props => <ToolBar {...props} borderLineModifier="top" />
