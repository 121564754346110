import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import Checkbox from 'components/common/Checkbox'
import DecimalInput from 'components/common/DecimalInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.less'

class ProductQty extends Component {
  static propTypes = {
    ...i18nPropTypes,
    loading: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    qty: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
    description: PropTypes.string,
    // roomType: PropTypes.shape({}),
  }

  static defaultProps = {
    loading: false,
    isReadOnly: false,
  }

  handleChangeQty = change => async () => {
    const { qty, onQtyChange } = this.props
    onQtyChange(qty + change)
  }

  handleApplyChanged = async (apply) => {
    const { onQtyChange } = this.props
    onQtyChange(apply ? 1 : 0)
  }

  renderButtons = () => {
    const { loading, isReadOnly, qty, unit, onQtyChange } = this.props
    return (
      <div className="ProductQty">
        <button type="button" onClick={this.handleChangeQty(-1)} disabled={loading || isReadOnly || qty === 0}>
          <div className="ProductQty__QtyButton">
            <FontAwesomeIcon icon={['fal', 'minus']} />
          </div>
        </button>
        {loading && <FontAwesomeIcon icon={['fal', 'spinner']} spin />}
        {!loading && <DecimalInput className="ProductQty__QtyInput" value={qty} onChange={onQtyChange} unit={unit} />}
        <button
          type="button"
          onClick={this.handleChangeQty(1)}
          disabled={loading || isReadOnly || (unit === 'psch' && qty > 0)}
        >
          <div className="ProductQty__QtyButton">
            <FontAwesomeIcon icon={['fal', 'plus']} />
          </div>
        </button>
      </div>
    )
  }

  renderCheckbox = () => {
    const { loading, qty, t } = this.props
    return (
      <div className="ProductQty">
        <Checkbox
          label={t('orders.applyItem')}
          checked={qty > 0}
          onChange={this.handleApplyChanged}
          loading={loading}
        />
      </div>
    )
  }

  render = () => {
    if (this.props.unit === 'psch') {
      return this.renderCheckbox()
    }
    // if (this.props.unit === 'm²' && this.props.roomType?.label?.toLowerCase() === 'sonstiges Wohnung') {
    //   return this.renderCheckbox()
    // }
    return this.renderButtons()
  }
}

export default translate()(ProductQty)
