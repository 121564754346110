import { createSelector } from 'reselect'
import { getSelectedOrderId } from 'selectors/navigation'
import { denormalize } from 'normalizr'
import { packageSchema } from 'api/schemas'
import { fromPairs, values } from 'lodash'

const entitySelector = state => state?.entities || {}

const getPackagesForSelectedOrder = createSelector(
  entitySelector,
  getSelectedOrderId,
  (entities, selectedOrderId) => {
    if (!entities.packages) return null
    const selectedOrder = entities?.orders[selectedOrderId]
    if (!selectedOrder) return null
    const productPackages = values(entities.packages).filter(productPackage => productPackage.catalog === selectedOrder.catalog)
    const denormalizedProductPackages = fromPairs(productPackages.map(productPackage => [productPackage.id, denormalize(productPackage, packageSchema, entities)]))
    return denormalizedProductPackages
  },
)

export default {
  getPackagesForSelectedOrder,
}
