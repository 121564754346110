import { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

class BoolValue extends Component {
  static propTypes = {
    ...i18nPropTypes,
    value: PropTypes.bool.isRequired,
  }

  static defaultProps = {

  }

  render() {
    const { t, value } = this.props

    if (value) {
      return t('common.true')
    }
    return t('common.false')
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { })(translate()(BoolValue))
