import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { SmallUserNameCircle } from 'components/common/UserNameCircle'
import { IconButton } from 'components/common/Button'
import TextAreaInput from 'components/common/TextAreaInput'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.less'

class CommentInput extends Component {
  static propTypes = {
    user: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    fetching: PropTypes.bool,
    ...i18nPropTypes,
  }

  static defaultProps = {
    fetching: false,
    user: {},
    onSubmit: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  handleTextAreaInputChange = (e) => {
    this.setState({
      value: e.target.value,
    })
  }

  handleOnSubmitPress = async () => {
    const { onSubmit } = this.props
    const { value } = this.state
    await onSubmit(value)
    this.reset()
  }

  reset = () => {
    this.setState({
      value: '',
    })
  }

  render() {
    const { user, fetching, t } = this.props
    const { value } = this.state
    return (
      <div className="CommentInput">
        {/* <SmallUserNameCircle className="CommentInput__UserAvatar" user={user} /> */}
        <TextAreaInput className="CommentInput__TextArea" placeholder={t('orderComment.inputPlaceholder')} onChange={this.handleTextAreaInputChange} value={value} />
        {fetching && (
          <IconButton className="CommentInput__SubmitButton" disabled>
            <FontAwesomeIcon icon={['fal', 'spinner']} spin />
          </IconButton>
        )}
        {!fetching && (
          <IconButton className="CommentInput__SubmitButton" onClick={this.handleOnSubmitPress}>
            <FontAwesomeIcon icon={['fal', 'paper-plane']} />
          </IconButton>
        )}
      </div>
    )
  }
}

export default translate()(CommentInput)
