import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { requestEntityAction, requestEntitiesAction } from 'actions/entities'
import { PrimaryButton } from 'components/common/Button'
import { denormalize } from 'normalizr'
import { merge, isEqual, isEmpty } from 'lodash'
import schemas from 'api/schemas'
import './style.less'

const getEntityValue = (state, options) => {
  if (options.entitySelector) {
    return options.entitySelector(state)
  }
  const entities = state?.entities
  if (entities[options.entityType]) {
    if (options.idSelector) {
      return denormalize(options.idSelector?.(state), schemas[options.entityType], entities)
    }
    return state?.entities?.[options.entityType]
  }
  return undefined
}

const mapStateToProps = (state, config) => {
  const props = {
    fetchOperations: {},
  }
  Object.entries(config).forEach(([entity, options]) => {
    props.fetchOperations[options.entityType] = { id: options?.idSelector?.(state), filter: options?.filter }
    props[entity] = getEntityValue(state, options)
  })
  return props
}

const mapDispatchToProps = {
  requestEntity: requestEntityAction,
  requestEntities: requestEntitiesAction,
}

const AsyncDataLoadingHOC = config => (WrappedComponent) => {
  class AsyncDataLoading extends Component {
    static propTypes = {
      filter: PropTypes.shape({}),
    }

    static defaultProps = {
      filter: {},
    }

    constructor(props) {
      super(props)
      this.state = {
        fetching: true,
        error: null,
      }
    }

    componentDidMount() {
      this.fetch()
    }

    componentDidUpdate(prevProps) {
      const { filter: prevFilter, fetchOperations: prevFetchOperations } = prevProps
      const { filter: newFilter, fetchOperations: newFetchOperations } = this.props
      if (!isEqual(newFetchOperations, prevFetchOperations)
          || (!isEmpty(prevFilter) && !isEmpty(newFilter) && !isEqual(prevFilter, newFilter))) {
        this.fetch()
      }
    }

    fetch = async () => {
      await this.setState({ fetching: true, error: null })
      const {
        fetchOperations,
        requestEntity,
        requestEntities,
        filter,
      } = this.props
      Object.entries(fetchOperations).forEach(async ([entityType, options]) => {
        const mergedFilter = merge(options?.filter || {}, filter)
        if (options?.id !== undefined) {
          try {
            await requestEntity(entityType, options.id, mergedFilter)
            await this.setState({ fetching: false })
          } catch (e) {
            console.error(e)
            await this.setState({ error: e })
          }
        } else {
          try {
            await requestEntities(entityType, mergedFilter)
            await this.setState({ fetching: false })
          } catch (e) {
            console.error(e)
            await this.setState({ error: e })
          }
        }
      })
    }

    renderError = error => (
      <div className="AsyncDataLoading">
        <div>
          {`Error ${error.status}`}
        </div>
        <PrimaryButton className="AsyncDataLoading__RetryButton" onClick={this.fetch}>
          Retry
        </PrimaryButton>
      </div>
    )

    renderLoading = () => (
      <div className="AsyncDataLoading">
        <FontAwesomeIcon icon={['fal', 'spinner']} spin />
      </div>
    )

    render() {
      const { fetching, error } = this.state
      if (error) return this.renderError(error)
      if (fetching) return this.renderLoading()
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }
  return connect(state => mapStateToProps(state, config), mapDispatchToProps)(AsyncDataLoading)
}

export default AsyncDataLoadingHOC
