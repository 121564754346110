import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import modifyClassName from 'helper/modifyClassName'

import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/de'

import Modal from 'containers/modal/Modal'

import './style.less'

class DatePickerModal extends Component {
  static propTypes = {
    hide: PropTypes.func.isRequired,
    dateSelected: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  constructor() {
    super()

    this.state = {
      selectedDate: null,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (event.target.className === 'DatePickerModal') {
      const { hide } = this.props
      hide()
    }
  }

  handleSelectDate = (date) => {
    this.setState({
      selectedDate: date,
    })
  }

  handleSaveDate = () => {
    const { selectedDate } = this.state
    const { dateSelected, hide } = this.props
    dateSelected(selectedDate)
    hide()
  }

  render = () => {
    const { t, hide } = this.props
    const { selectedDate } = this.state

    return (
      <div className="DatePickerModal">
        <div className="DatePickerModal__PickerContainer">
          <div className="DatePickerModal__Picker">
            <DayPicker onDayClick={this.handleSelectDate} selectedDays={selectedDate} localeUtils={MomentLocaleUtils} locale="de" />
          </div>
          <div className="DatePickerModal__Buttons">
            <button className="DatePickerModal__Button" type="button" onClick={hide}>
              <FontAwesomeIcon className="DatePickerModal__Icon" icon={['fal', 'times-circle']} />
              {t('buttons.discard')}
            </button>
            <button className={modifyClassName('DatePickerModal__Button', { inactive: selectedDate === null })} type="button" onClick={this.handleSaveDate} disabled={selectedDate === null}>
              <FontAwesomeIcon className={modifyClassName('DatePickerModal__Icon', { save: true, inactive: selectedDate === null })} icon={['fal', 'check-circle']} />
              {t('buttons.apply')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal(translate()(DatePickerModal))
