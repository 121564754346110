import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'
import { connect } from 'react-redux'

import pathNameSelector from 'selectors/navigation'
import UserSelector from 'selectors/user'
import AuthenticationSelector from 'selectors/authentication'
import { BigUserNameCircle } from 'components/common/UserNameCircle'
import { userDisplayName } from 'helper/viewHelper'
import { LOGOUT_URL } from 'helper/constants'
import SideMenuButton from 'components/common/SideMenuButton'

import './style.less'

const PATH_TASKS = '/'
const PATH_ORDERS = '/orders'
const PATH_APARTMENTS = '/apartments'
const PATH_PACKAGES = '/packages'
const PATH_REPORTS = '/reports'
const PATH_SETTINGS = '/settings'

const isSmall = currentPath => currentPath === PATH_REPORTS

const MainSideMenu = ({
  t,
  currentUser,
  currentPath,
  activeTenant: { isAdmin, isAnalyst },
}) => (
  <div className={modifyClassName('MainSideMenu', { small: isSmall(currentPath) })}>
    <div className="MainSideMenu__Header">
      <BigUserNameCircle user={currentUser} />
      {!isSmall(currentPath) && (
        <>
          <div className="MainSideMenu__Username">{userDisplayName(currentUser)}</div>
          <div className="MainSideMenu__Date">{moment().format('ddd DD.MM.YYYY')}</div>
        </>
      )}
    </div>
    <div className="MainSideMenu__Navigation">
      <SideMenuButton to={PATH_TASKS} icon="tachometer-alt-fast" selected={currentPath === PATH_TASKS} small={isSmall(currentPath)}>
        {t('navigation.tasks')}
      </SideMenuButton>
      <SideMenuButton to={PATH_ORDERS} icon="clipboard-list" selected={currentPath === PATH_ORDERS} small={isSmall(currentPath)}>
        {t('navigation.allOrders')}
      </SideMenuButton>
      <SideMenuButton to={PATH_APARTMENTS} icon="building" selected={currentPath === PATH_APARTMENTS} small={isSmall(currentPath)}>
        {t('navigation.apartments')}
      </SideMenuButton>
      <SideMenuButton to={PATH_PACKAGES} icon="boxes" selected={currentPath === PATH_PACKAGES} small={isSmall(currentPath)}>
        {t('navigation.packages')}
      </SideMenuButton>
      {isAnalyst && (
        <SideMenuButton to={PATH_REPORTS} icon="analytics" selected={currentPath === PATH_REPORTS} small={isSmall(currentPath)}>
          {t('navigation.reports')}
        </SideMenuButton>
      )}
      {isAdmin && (
        <SideMenuButton to={PATH_SETTINGS} icon="cogs" selected={currentPath === PATH_SETTINGS} small={isSmall(currentPath)}>
          {t('navigation.settings')}
        </SideMenuButton>
      )}
    </div>
    <div className="MainSideMenu__Footer">
      {!isSmall(currentPath) &&
        <>
          <a target="_blank" rel="noopener noreferrer" href={LOGOUT_URL}>{t('buttons.logout')}</a>
          <div className="MainSideMenu__Version">{process.env.VERSION_LABEL || 'Local Version'}</div>
        </>
      }
    </div>
  </div>
)

MainSideMenu.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  activeTenant: PropTypes.shape({}).isRequired,
  currentPath: PropTypes.string.isRequired,
  ...i18nPropTypes,
}

const mapStateToProps = state => ({
  currentUser: UserSelector.getCurrentUser(state),
  activeTenant: AuthenticationSelector.getActiveTenant(state),
  currentPath: pathNameSelector(state),
})

export default connect(mapStateToProps, null)(translate()(MainSideMenu))
