import _ from 'lodash'
import { createSelector } from 'reselect'

import PageSelector from './page'

const apartmentImportsSelector = state => _.get(state, 'entities.apartmentImports', {})

/* eslint-disable no-return-assign */
const getApartmentImportsForCurrentPage = createSelector(
  apartmentImportsSelector,
  PageSelector.getApartmentImportsIdsForCurrentPage,
  (apartments, currentPageIds) => {
    const result = {}
    currentPageIds.forEach(id => (result[id] = apartments[id]))
    return result
  },
)

export default {
  getApartmentImportsForCurrentPage,
}
