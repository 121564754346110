import _ from 'lodash'
import { createSelector } from 'reselect'

const apartmentPageSelector = state => _.get(state, 'pages.apartments.active', {})
const catalogPageSelector = state => _.get(state, 'pages.catalogs.active', {})
const apartmentImportPageSelector = state => _.get(state, 'pages.apartmentImports.active', {})

const getApartmentIdsForCurrentPage = createSelector(
  apartmentPageSelector,
  page => page?.ids || [],
)

const getCurrentApartmentPage = createSelector(
  apartmentPageSelector,
  page => page,
)

const getCurrentCatalogsPage = createSelector(
  catalogPageSelector,
  page => page,
)

const getApartmentImportsIdsForCurrentPage = createSelector(
  apartmentImportPageSelector,
  page => page?.ids || [],
)

const getCatalogIdsForCurrentPage = createSelector(
  catalogPageSelector,
  page => page?.ids || [],
)

const getCurrentApartmentImportsPage = createSelector(
  apartmentImportPageSelector,
  page => page,
)

export default {
  getApartmentIdsForCurrentPage,
  getCurrentApartmentPage,
  getCurrentCatalogsPage,
  getCatalogIdsForCurrentPage,
  getApartmentImportsIdsForCurrentPage,
  getCurrentApartmentImportsPage,
}
