import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { NativeTypes } from 'react-dnd-html5-backend'
import { DropTarget } from 'react-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.less'


class FileDrop extends Component {
  setRefs = (ref) => {
    this.fileInputEl = ref
  }

  handleClick = () => {
    this.fileInputEl.click()
  }

  handleOnChange = (event) => {
    const files = event.target.files
    this.props.onDrop([...files])
  }

  render() {
    const {
      connectDropTarget,
      isOver,
      text,
      textDrop,
    } = this.props
    return connectDropTarget(
      <button type="button" className="FileDrop" onClick={this.handleClick}>
        <input className="FileDrop__Input" type="file" ref={this.setRefs} onChange={this.handleOnChange} />
        <div className="FileDrop__Hint">
          <FontAwesomeIcon className="FileDrop__Icon" icon={['fal', isOver ? 'arrow-to-bottom' : 'cloud-upload']} />
          <div className="FileDrop__Text">{isOver ? textDrop : text}</div>
        </div>
      </button>,
    )
  }
}

const fileTarget = {
  drop(props, monitor) {
    if (!props.isLoading) {
      props.onDrop(monitor.getItem().files)
    }
  },
}


FileDrop.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  textDrop: PropTypes.string.isRequired,
}

export default DropTarget(NativeTypes.FILE, fileTarget, (con, monitor) => ({
  connectDropTarget: con.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(FileDrop)
