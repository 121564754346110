import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import {
  reduxForm,
  Field,
  propTypes as reduxPropTypes,
  FieldArray,
} from 'redux-form'

import { values as _values } from 'lodash'
import RoomTypeSelector from 'selectors/room-types'
import { updateRoomAction, createRoomAction, deleteRoomAction } from 'actions/rooms'
import { required } from 'helper/validators'
import { roomIconMapping as iconMapping } from 'helper/viewHelper'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RFApartmentRoomSizes from 'components/apartment/RFApartmentRoomSizes'
import { PrimaryButton, PrimaryCallbackButton, SimpleCallbackButton } from 'components/common/Button'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import RFTextInput from 'components/common/RFTextInput'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class RoomSizeSelectionForm extends Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    updateRoom: PropTypes.func.isRequired,
    createRoom: PropTypes.func.isRequired,
    deleteRoom: PropTypes.func.isRequired,
    apartment: PropTypes.shape({}).isRequired,
    roomSizes: PropTypes.shape({}).isRequired,
    roomTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showRoomTypeButtons: false,
    }
  }

  handleCreateRoomButtonClicked = () => {
    this.setState({
      showRoomTypeButtons: true,
    })
  }

  handleDeleteRoomButtonClicked = (room) => {
    this.deleteRoom(room)
  }

  handleRoomTypeButtonClicked = (roomType) => {
    this.createRoom(roomType)
  }

  createRoom = async (roomType) => {
    const { apartment, createRoom } = this.props
    const room = {
      tenant: roomType.tenant,
      apartment: apartment.id,
      name: roomType.externalId,
      roomType: roomType.id,
    }
    this.setState({
      showRoomTypeButtons: false,
      loading: true,
    })
    await createRoom(room)
    this.setState({ loading: false })
  }

  deleteRoom = async (room) => {
    const { deleteRoom } = this.props
    this.setState({ loading: true })
    await deleteRoom(room)
    this.setState({ loading: false })
  }

  onSubmit = async (values) => {
    const {
      updateRoom,
    } = this.props

    const { rooms } = values

    this.setState({ loading: true })
    await Promise.all(_values(rooms).map(room => updateRoom(room)))
    this.setState({ loading: false })
  }

  renderRooms = ({
    fields,
    roomSizes,
  }) => (
    <ul>
      {fields
        .map((member, index) => {
          const { t } = this.props
          const { loading } = this.state
          const room = fields.get(index)
          if (room === undefined) return null
          return (
            (
              <li className="RoomSizeSelectionForm__FieldRow" key={member}>
                <div className="RoomSizeSelectionForm__Icon">
                  <FontAwesomeIcon icon={['fal', iconMapping[room?.roomType?.externalId] || 'cube']} />
                </div>
                <div className="RoomSizeSelectionForm__Label">
                  {room?.roomType?.label}
                </div>
                <Field
                  className="RoomSizeSelectionForm__Field"
                  name={`${member}.name`}
                  label={t('table.roomName')}
                  component={RFTextInput}
                  room={room}
                  roomSizes={roomSizes}
                  isLoading={loading}
                  validate={required}
                />
                {room?.roomType?.isDefault && (
                  <Field
                    name={`${member}.roomSize`}
                    component={RFApartmentRoomSizes}
                    room={room}
                    roomSizes={roomSizes}
                    isLoading={loading}
                  />
                )}
                <SimpleCallbackButton onClick={this.handleDeleteRoomButtonClicked} callbackValue={room} disabled={loading}>
                  <FontAwesomeIcon className="RoomSizeSelectionForm__DeleteRoomButton" icon={['fal', loading ? 'spinner' : 'trash-alt']} spin={loading} />
                </SimpleCallbackButton>
              </li>
            )
          )
        })
    }
    </ul>
  )

  render() {
    const {
      t,
      apartment,
      handleSubmit,
      roomSizes,
      dirty,
      roomTypes,
    } = this.props
    if (!apartment || !roomSizes) return null
    const { loading, showRoomTypeButtons } = this.state
    return (
      <form className="RoomSizeSelectionForm" onSubmit={handleSubmit(this.onSubmit)}>
        <ScrollContainer className="RoomSizeSelectionForm__Fields" scrollVertical>
          <FieldArray name="rooms" component={this.renderRooms} loading={loading} roomSizes={roomSizes} roomTypes={roomTypes} rerenderOnEveryChange />
          <div className="RoomSizeSelectionForm__AddRoom">
            {showRoomTypeButtons ? (
              roomTypes.map(roomType => (
                <PrimaryCallbackButton className="RoomSizeSelectionForm__AddRoomButton" key={roomType.id} onClick={this.handleRoomTypeButtonClicked} callbackValue={roomType}>
                  <div className="RoomSizeSelectionForm__Icon">
                    <FontAwesomeIcon icon={['fal', iconMapping[roomType.externalId] || 'cube']} />
                  </div>
                  {roomType.externalId}
                </PrimaryCallbackButton>
              ))
            ) : (
              <PrimaryButton className="RoomSizeSelectionForm__AddRoomButton" type="button" onClick={this.handleCreateRoomButtonClicked} disabled={loading}>
                <FontAwesomeIcon className="RoomSizeSelectionForm__AddRoomPlus" icon={['fal', loading ? 'spinner' : 'plus']} spin={loading} />
                {t('orderSummary.addRoom')}
              </PrimaryButton>
            )}
          </div>
        </ScrollContainer>
        <div className="RoomSizeSelectionForm__Footer">
          <PrimaryButton type="submit" disabled={!dirty}>
            {loading ? <FontAwesomeIcon className="RoomSizeSelectionForm__Spinner" icon={['fal', 'spinner']} spin /> : t('buttons.save')}
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: 'ROOM_SIZE_SELECTION_FORM',
}

const formComponent = reduxForm(form)(RoomSizeSelectionForm)
const connectedComponent = connect(null, {
  updateRoom: updateRoomAction,
  createRoom: createRoomAction,
  deleteRoom: deleteRoomAction,
})(formComponent)
const translatedComponent = translate()(connectedComponent)


export default AsyncDataLoadingHOC({
  roomSizes: {
    entityType: 'roomSizes',
  },
  roomTypes: {
    entitySelector: RoomTypeSelector.getRoomTypes,
    entityType: 'roomTypes',
  },
})(translate()(translatedComponent))
