import { get, groupBy } from 'lodash'
import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'
import { orderSchema } from 'api/schemas'
import moment from 'moment'

export const getTaskData = state => get(state, 'tasks')
const entitySelector = state => state?.entities || {}

const activeTenantSelector = state => state?.user?.activeTenant

const sorts = {
  orderByUpdateNewest: tasks => tasks?.sort((t1, t2) => moment.utc(t2.modified).diff(moment.utc(t1.modified))),
  orderByUpdateOldest: tasks => tasks?.sort((t1, t2) => moment.utc(t1.modified).diff(moment.utc(t2.modified))),
  orderByGreatestVolume: tasks => tasks?.sort((t1, t2) => t2.totalPrice - t1.totalPrice),
}

// TODO: Refactor this to include the filter selection into the state.
const getTasks = filter => createSelector(
  getTaskData,
  entitySelector,
  activeTenantSelector,
  (data, entities, activeTenant) => {
    const {
      order,
      ids,
    } = data

    const { responsibleTasks = [], allTasks = [] } = activeTenant
    const taskNames = filter === 'me' ? responsibleTasks : allTasks

    const tasks = ids
      .map(id => denormalize(id, orderSchema, entities))
      .filter(task => taskNames.includes(task.processState))

    const sortedTasks = sorts[order]?.(tasks)
    const groupedTasks = groupBy(sortedTasks, task => (task.processState))

    return taskNames.map(name => ({ name, tasks: groupedTasks[name] }))
  },
)

const getAllTaskNames = createSelector(
  activeTenantSelector,
  (activeTenant) => {
    const { allTasks = [] } = activeTenant
    return [...allTasks, 'CLOSED']
  },
)

export default {
  getTaskData,
  getTasks,
  getAllTaskNames,
}
