import * as api from 'api'
import { denormalize } from 'normalizr'

import { orderSchema } from 'api/schemas'
import { receiveEntities, requestEntityAction, deleteEntityAction } from './entities'

export const createOrderAction = apartment => async (dispatch, getState) => {
  const payload = await api.createOrder(apartment)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, orderSchema, getState().entities)
}

export const updateOrderAction = order => async (dispatch, getState) => {
  await api.updateOrder(order)
  // Second API call necessary, because the API does not return detailed data in PATCH requests
  const payload = await api.requestEntity('orders', order.id)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, orderSchema, getState().entities)
}

export const updateLineItemAction = (lineItem, requestOrder = true) => async (dispatch) => {
  const apiCall = lineItem.id ? api.updateLineItem : api.createLineItem
  const payload = await apiCall(lineItem)
  dispatch(receiveEntities({ results: payload }))
  // note: do not load extra data here in actions. it is out of scope, no loading/progress is trackable
  if (requestOrder) {
    dispatch(requestEntityAction('orders', lineItem.orderHeader))
  }
}

export const deleteLineItemAction = lineItem => async (dispatch) => {
  await dispatch(deleteEntityAction('orderLineItems', lineItem.id))
  dispatch(requestEntityAction('orders', lineItem.orderHeader))
}

export const dispatchOrderActionAction = (order, action) => async (dispatch, getState) => {
  const payload = await api.dispatchOrderAction(order, action)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, orderSchema, getState().entities)
}

export const downloadOrderSummaryAction = order => async () => api.downloadOrderSummary(order)
