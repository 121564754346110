import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import Price from 'components/common/Price'
import './style.less'

const OrderLineItemInfos = ({
  className,
  price,
  unit,
  qty,
  unitPrice,
  isRequired,
  t,
}) => (
  <div className={`OrderLineItemInfos ${className}`}>
    <div className="OrderLineItemInfos__LineItemPrice">
      {isRequired && 'Sowieso '}
      <Price>{price}</Price>
    </div>
    <div className="OrderLineItemInfos__LineItemQty">
      {t('orders.qty', { qty: qty || 0, unit })}
      <Price>{unitPrice}</Price>
    </div>
  </div>
)

OrderLineItemInfos.propTypes = {
  className: PropTypes.string,
  price: PropTypes.number,
  unit: PropTypes.string,
  unitPrice: PropTypes.number,
  qty: PropTypes.number,
  isRequired: PropTypes.bool,
  ...i18nPropTypes,
}

OrderLineItemInfos.defaultProps = {
  className: '',
  price: 0,
  unitPrice: 0,
  unit: '$',
  qty: 0,
  isRequired: false,
}

export default translate()(OrderLineItemInfos)
