import React, { Component } from 'react'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import './style.less'

/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
class SettingsProcess extends Component {
  static propTypes = {
    ...i18nPropTypes,
  }

  static defaultProps = {

  }

  render() {
    const { t } = this.props
    return (
      <div className="SettingsProcess">
        SettingsProcess
      </div>
    )
  }
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { })(translate()(SettingsProcess))
