import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import authenticationReducer from './AuthenticationReducer'
import entityReducer from './EntityReducer'
import pagedDataViewReducer from './PagedDataViewReducer'
import userReducer from './UserReducer'
import dataViewReducer from './DataViewReducer'
import modalReducer from './ModalReducer'
import taskReducer from './TaskReducer'
import flyoutReducer from './FlyoutReducer'
import reportReducer from './ReportReducer'
import scrollToProduct from './ScrollToProductReducer'

const reducers = combineReducers({
  form: formReducer,
  authentication: authenticationReducer,
  user: userReducer,
  entities: entityReducer,
  pagedDataViews: pagedDataViewReducer,
  dataView: dataViewReducer,
  modal: modalReducer,
  tasks: taskReducer,
  flyout: flyoutReducer,
  report: reportReducer,
  scrollToProduct,
})

const rootReducer = (state, action) => reducers(state, action)

export default rootReducer
