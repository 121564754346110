import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.less'

const BackButton = ({
  onClick,
}) => (
  <div className="BackButton">
    <button className="BackButton__BackButton" type="button" onClick={onClick}>
      <FontAwesomeIcon className="BackButton__Icon" icon={['fal', 'arrow-left']} />
    </button>
  </div>
)

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BackButton)
