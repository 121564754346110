import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { activeTenantChanged } from 'actions/user'
import UserSelector from 'selectors/user'

import { PrimaryButton } from 'components/common/Button'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class TenantSelect extends Component {
  static propTypes = {
    activeTenantChanged: PropTypes.func.isRequired,
    onTenantSelect: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({}).isRequired,
    ...i18nPropTypes,
  }

  handleTenantSelected = (tenant) => {
    const { activeTenantChanged: changeActiveTenant, onTenantSelect } = this.props
    changeActiveTenant(tenant)
    onTenantSelect()
  }

  render() {
    const { t, currentUser } = this.props
    const { tenants } = currentUser
    return (
      <div className="TenantSelect">
        <div className="TenantSelect__Title">{t('auth.activeTenant.title')}</div>
        <ScrollContainer className="TenantSelect__ButtonContainer" scrollVertical>
          {tenants.map(tenant => <PrimaryButton className="TenantSelect__Button" key={tenant.id} onClick={() => this.handleTenantSelected(tenant)}>{tenant.name}</PrimaryButton>)}
        </ScrollContainer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: UserSelector.getCurrentUser(state),
})

export default connect(mapStateToProps, { activeTenantChanged })(translate()(TenantSelect))
