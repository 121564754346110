import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import HashLink from 'components/common/HashLink'
import BadgeCount from 'components/common/BadgeCount'

import './style.less'

const ToolBarItem = ({
  to,
  label,
  icon,
  badgeCount,
}) => {
  const Element = (to && to.charAt(0) === '#') ? HashLink : NavLink
  return (
    <Element
      to={to}
      className="ToolBarItem"
      activeClassName="ToolBarItem--selected"
      exact
    >
      <div className="ToolBarItem__IconContainer">
        {badgeCount > 0 && <BadgeCount badgeCount={badgeCount} />}
        <FontAwesomeIcon className="ToolBarItem__Icon" icon={['fal', icon]} />
      </div>
      <div className="ToolBarItem__Label">{label}</div>
    </Element>
  )
}

ToolBarItem.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  badgeCount: PropTypes.number,
}

ToolBarItem.defaultProps = {
  badgeCount: 0,
}

export default ToolBarItem
