import { authChanged } from 'actions/authentication'

export default store => next => (action) => {
  const { type } = action

  if (type !== 'INIT') return next(action)

  try {
    const accessToken = window.localStorage.getItem('access_token') || null
    const idToken = window.localStorage.getItem('id_token') || null
    const expiresAt = window.localStorage.getItem('expires_at') || null

    if (new Date().getTime() < expiresAt) {
      // token still valid
      store.dispatch(authChanged({
        accessToken,
        idToken,
        expiresAt,
      }))
    } else {
      // Clear Access Token and ID Token from local storage
      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('id_token')
      window.localStorage.removeItem('expires_at')
    }
    
    const storedState = JSON.parse(
      window.localStorage.getItem('stored_state'),
    )
    if (storedState) {
      store.dispatch({
        type: 'LOAD_LOCALSTORAGE',
        payload: storedState,
      })
    }

    return next(action)
  } catch (e) {
    // Unable to load or parse stored state, proceed as usual
    return null
  }
}
