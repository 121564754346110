import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { numeral } from 'i18n'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

class DecimalInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    unit: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    value: null,
    unit: '',
  }

  static format = '0,0.00'

  constructor(props) {
    super(props)
    const { value } = props
    this.state = { strValue: numeral(value || 0).format(DecimalInput.format), isValid: true }
  }


  handleOnChange = (e) => {
    const strValue = e.target?.value || ''
    this.setState({ strValue, isValid: numeral(strValue).value() !== null })
  }

  handleOnBlur = (e) => {
    const { onChange } = this.props
    const { strValue } = this.state
    const newValue = numeral(strValue).value()
    onChange(newValue)
    this.setState({ strValue})
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleOnBlur(e)
    }
  }

  componentDidUpdate = (prevProps) => {
    const prevValue = prevProps?.value
    const value = this.props?.value
    const { strValue } = this.state

    if (prevValue !== value && value !== numeral(strValue).value()) {
      this.setState({ strValue: numeral(value || 0).format(DecimalInput.format) })
    }
  }


  render = () => {
    const { className, value, disabled, onChange, unit} = this.props
    const { strValue, isValid } = this.state
    return (
      <div className={`${modifyClassName('DecimalInput', { invalid: !isValid })} ${className}`}>
        <input
          className="DecimalInput__Input"
          type='text'
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onKeyPress={this.handleKeyPress}
          value={strValue}
          disabled={disabled}
        />
        <div className="DecimalInput__Unit">{unit}</div>
      </div>
    )
  }
}

export default DecimalInput