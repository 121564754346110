import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { values } from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import ReportSelector from 'selectors/report'
import ReportOverviewNumber from 'components/report/ReportOverviewNumber'
import { selectReportAction } from 'actions/report'

import './style.less'

class ReportsOverviewContainer extends Component {
  static propTypes = {
    selectedReport: PropTypes.shape({}),
    selectReport: PropTypes.func.isRequired,
    reports: PropTypes.shape({}).isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    selectedReport: null,
  }

  componentDidMount() {
    const { selectedReport, reports, selectReport } = this.props
    if (selectedReport === null) {
      selectReport(values(reports)[0])
    }
  }

  handleSelectReport = report => () => {
    const { selectReport } = this.props
    selectReport(report)
  }

  render() {
    const { reports, selectedReport, t } = this.props
    return (
      <div className="ReportsOverviewContainer">
        <h2 className="ReportsOverviewContainer__Title">{t('navigation.reports')}</h2>
        {values(reports).map((report) => (
          <ReportOverviewNumber
            key={report.id}
            report={report}
            onClick={this.handleSelectReport(report)}
            selected={selectedReport?.id === report.id}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedReport: ReportSelector.getSelectedReport(state),
})

const connectedComponent = connect(mapStateToProps, {
  selectReport: selectReportAction,
})(translate()(ReportsOverviewContainer))

export default AsyncDataLoadingHOC({
  reports: {
    entityType: 'reports',
  },
})(translate()(connectedComponent))
