import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { changeFilterAction } from 'actions/data-view'

import ProcessState from 'components/process/ProcessState'
import Flyout from 'containers/common/Flyout'

import TaskSelector from 'selectors/task'
import { metaDataSelector } from 'selectors/data-view'

import './style.less'

class ProcessStateFlyout extends Component {
  static propTypes = {
    allTaskNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    changeFilter: PropTypes.func.isRequired,
    viewId: PropTypes.string.isRequired,
    processStateFilter: PropTypes.string,
  }

  static defaultProps = {
    processStateFilter: null,
  }

  handleStateChange = processState => () => {
    const { changeFilter, viewId, processStateFilter } = this.props
    const newStateFilter = processState === processStateFilter ? undefined : processState
    changeFilter(viewId, { processState: newStateFilter })
  }

  render = () => {
    const { allTaskNames, processStateFilter } = this.props
    return (
      <div className="ProcessStateFlyout">
        { allTaskNames.map(taskName => (
          <button type="button" onClick={this.handleStateChange(taskName)} key={taskName}>
            <ProcessState
              className="ProcessStateFlyout__ProcessState"
              processState={taskName}
              isActive={processStateFilter === taskName}
            />
          </button>
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  allTaskNames: TaskSelector.getAllTaskNames(state) || [],
  processStateFilter: metaDataSelector(state, props)?.filters?.processState,
})

const mapDispatchToProps = {
  changeFilter: changeFilterAction,
}

export default Flyout(connect(mapStateToProps, mapDispatchToProps)(ProcessStateFlyout))
