import React from 'react'
import PropTypes from 'prop-types'
import { numeral } from 'i18n'


const Price = ({ className, currency, children, format }) => (
  <span className={className}>{`${currency} ${numeral(children / 100.00).format(format)}`}</span>
)

Price.propTypes = {
  format: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.number,
  currency: PropTypes.string,
}

Price.defaultProps = {
  format: '0,0.00',
  className: '',
  currency: '€',
  children: 0,
}

export default Price
