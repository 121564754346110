import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { push as pushAction } from 'connected-react-router'
import { deleteLineItemAction } from 'actions/orders'
import OrderLineItemProductGroup from 'components/order/OrderLineItemProductGroup'

import { getSelectedOrderId } from 'selectors/navigation'
import OrderSelector from 'selectors/order'

import { PrimaryButton } from 'components/common/Button'
import PriceSummary from 'components/common/PriceSummary'
import Price from 'components/common/Price'
import { TopBar } from 'components/common/ToolBar'
import ToolBarItem from 'components/common/ToolBarItem'
import FreeTextLineItemForm from 'containers/form/FreeTextLineItemForm'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'
import { unsetScrollToProduct } from '../../../actions/scrolltoproduct'

class OrderLineItemsList extends Component {
  static propTypes = {
    ...i18nPropTypes,
    orderId: PropTypes.string.isRequired,
    room: PropTypes.shape({}),
    crafts: PropTypes.arrayOf(PropTypes.shape({})),
    roomSizeMissing: PropTypes.bool,
  }

  static defaultProps = {
    room: null,
    crafts: null,
    roomSizeMissing: false,
  }

  constructor(props) {
    super(props)
    this.state = { expandedProductGroupKey: null }
  }

  handleSelectProduct = (productGroupKey) => {
    const { expandedProductGroupKey } = this.state
    this.setState({ expandedProductGroupKey: expandedProductGroupKey === productGroupKey ? null : productGroupKey })
  }

  handleShowRooms = () => {
    const { push, orderId } = this.props
    push(`/orders/${orderId}/rooms`)
  }

  handleDeleteLineItem = (lineItem) => () => {
    const { deleteLineItem } = this.props
    deleteLineItem(lineItem)
  }

  onScrollToTarget = () => {
    this.props.unsetScrollToProduct()
  }

  renderProduct = (productGroup) => {
    const { room, orderId } = this.props
    if (!productGroup) return null
    const productId = productGroup.products.length > 0
      ? productGroup.products[0].id
      : null
    return (
      <div id={`product-${productId}`} key={productGroup.productGroupKey}>
        <OrderLineItemProductGroup
          productGroup={productGroup}
          expanded={this.state.expandedProductGroupKey === productGroup.productGroupKey}
          onSelectProduct={this.handleSelectProduct}
          room={room}
          orderId={orderId}
        />
      </div>
    )
  }

  renderCraft = ({
    name,
    slugifiedName,
    requiredAmount,
    overallAmount,
    productGroups,
  }) => {
    const { t } = this.props
    return (
      <div className="OrderLineItemsList__Craft" key={name}>
        <div className="OrderLineItemsList__CraftHeader">
          <div className="OrderLineItemsList__CraftNameContainer">
            <div className="OrderLineItemsList__CraftName">
              {name}
            </div>
            <div className="OrderLineItemsList__SubTitle">
              {t('orderSummary.list.selectedProducts')}
            </div>
          </div>
          <PriceSummary requiredAmount={requiredAmount} overallAmount={overallAmount} />
        </div>
        <div id={slugifiedName} />
        <div>
          {productGroups.map(this.renderProduct)}
        </div>
      </div>
    )
  }

  renderTopBarIcon = (orderId, roomId, craft) => (
    <ToolBarItem
      key={craft.name}
      to={`#${craft.slugifiedName}`}
      label={craft.name}
      icon={craft.icon}
      badgeCount={craft.badgeCount || 0}
    />
  )

  renderRoomSizeMissing = () => (
    <div className="OrderLineItemsList__PlaceholderContainer">
      <div>{this.props.t('orderSummary.roomSizeMissingText')}</div>
      <PrimaryButton className="OrderLineItemsList__ShowRoomsButton" onClick={this.handleShowRooms}>
        {this.props.t('orderSummary.roomSizeMissingButton')}
      </PrimaryButton>
    </div>
  )

  renderLineItemsWithDifferentRoomSize = () => {
    const { lineItemsWithDifferentRoomSize: lineItems, t } = this.props
    return (
      <div>
        {lineItems && lineItems.length > 0 && (
          <div className="OrderLineItemsList__InvalidLineItemHeader">
            {t('orders.invalidLineItems')}
          </div>
        )}
        {lineItems.map(lineItem => this.renderInvalidLineItem(lineItem))}
      </div>
    )
  }

  renderInvalidLineItem = (lineItem) => (
    <div className="OrderLineItemsList__InvalidLineItem" key={lineItem.id}>
      <div className="OrderLineItemsList__InvalidLineItemExternalId">{lineItem.product.externalId}</div>
      <div className="OrderLineItemsList__InvalidLineItemProductTitle">{lineItem.product.title}</div>
      <div className="OrderLineItemsList__InvalidLineItemRoomSize">({lineItem.product.roomSize.label})</div>
      <div className="OrderLineItemsList__InvalidLineItemProductQty">{lineItem.qty}</div>
      <div className="OrderLineItemsList__InvalidLineItemProductQty">{lineItem.product.unit}</div>
      <Price className="OrderLineItemsList__InvalidLineItemProductPrice">{lineItem.qty * lineItem.price}</Price>
      <button className="OrderLineItemsList__InvalidLineItemRemove" onClick={this.handleDeleteLineItem(lineItem)}>
        {this.props.t('common.remove')}
      </button>
    </div>
  )

  render = () => {
    const {
      crafts,
      orderId,
      room,
      roomSizeMissing,
      scrollToProductId,
    } = this.props

    if (roomSizeMissing) {
      return this.renderRoomSizeMissing()
    }

    if (!crafts || isEmpty(crafts)) {
      return <div className="OrderLineItemsList__PlaceholderContainer"><FontAwesomeIcon icon={['fal', 'spinner']} spin /></div>
    }

    const scrollToTarget = scrollToProductId
      ? `product-${scrollToProductId}`
      : null

    return (
      <div className="OrderLineItemsList">
        <TopBar>
          {crafts.map(craft => this.renderTopBarIcon(orderId, room?.id, craft))}
        </TopBar>
        <ScrollContainer className="OrderLineItemsList__ScrollContainer" scrollVertical scrollToTarget={scrollToTarget} onScrollToTarget={this.onScrollToTarget}>
          {this.renderLineItemsWithDifferentRoomSize()}
          {crafts.map(craft => (craft.productGroups.length > 0 ? this.renderCraft(craft) : null))}
          <FreeTextLineItemForm orderId={orderId} roomId={String(room?.id)} />
        </ScrollContainer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  orderId: getSelectedOrderId(state),
  room: OrderSelector.getSelectedRoom(state),
  roomSizeMissing: OrderSelector.roomSizeMissing(state),
  crafts: OrderSelector.getCrafts(state),
  lineItemsWithDifferentRoomSize: OrderSelector.getLineItemsWithDifferentRoomSize(state),
  scrollToProductId: state.scrollToProduct?.productId,
})

const mapDispatchToProps = {
  push: pushAction,
  deleteLineItem: deleteLineItemAction,
  unsetScrollToProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrderLineItemsList))
