import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PrimaryButton, SecondaryButton } from 'components/common/Button'
import { Title } from 'components/process/ProcessTitle'


import './style.less'

const ApproveDraftStateHOC = ({
  translationKey,
  yesAction = 'ConditionTrue',
  noAction = 'ConditionFalse',
}) => translate()(class ApproveDraftState extends Component {
  static propTypes = {
    order: PropTypes.shape({}),
    onDispatchAction: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    order: null,
  }

  handleYes = async () => {
    const { order, onDispatchAction } = this.props
    onDispatchAction(order, { action: yesAction })
  }

  handleNo = async () => {
    const { order, onDispatchAction } = this.props
    onDispatchAction(order, { action: noAction })
  }

  handleResetToDraft = async () => {
    const { order, onDispatchAction } = this.props
    onDispatchAction(order, { action: 'Draft' })
  }

  render = () => (
    <>
      <Title>{this.props.t(`process.${translationKey}.title`)}</Title>
      <div className="DecisionState">
        <SecondaryButton type="submit" onClick={this.handleResetToDraft} className="DecisionState__Button">
          {this.props.t(`process.${translationKey}.resetToDraft`)}
        </SecondaryButton>
        <SecondaryButton type="submit" onClick={this.handleNo} className="DecisionState__Button">
          {this.props.t(`process.${translationKey}.no`)}
        </SecondaryButton>
        <PrimaryButton type="submit" onClick={this.handleYes} className="DecisionState__Button">
          {this.props.t(`process.${translationKey}.yes`)}
        </PrimaryButton>
      </div>
    </>
  )
})


export default ApproveDraftStateHOC
