import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import {
  Field,
  FieldArray,
  reduxForm,
  propTypes as reduxPropTypes,
} from 'redux-form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { updateTeamAction, createTeamAction } from 'actions/teams'
import { requestUsersAction } from 'actions/users'
import UserSelector from 'selectors/user'

import { PrimaryButton } from 'components/common/Button'
import RFTextInput from 'components/common/RFTextInput'
import RFCheckBox from 'components/common/RFCheckBox'
import RFUserDropDownInput from 'containers/input/RFUserDropDownInput'
import SimpleUserRow from 'components/common/SimpleUserRow'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class TeamForm extends Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    updateTeam: PropTypes.func.isRequired,
    createTeam: PropTypes.func.isRequired,
    requestUsers: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  /* eslint-disable react/default-props-match-prop-types */
  /*
    it is a false positive by the linter since it does not
    look into the destructured props (...reduxPropTypes)
  */
  static defaultProps = {
    onSuccess: () => {},
    initialValues: {
      users: [],
      isCaseHandler: false,
      isTechnician: false,
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    this.fetchUser()
  }

  fetchUser = async () => {
    const { requestUsers } = this.props
    await this.setState({ loading: true })
    await requestUsers()
    await this.setState({ loading: false })
  }

  mapIds = values => ({
    ...values,
    manager: values.manager?.id,
    users: values.users?.map(value => value.id),
    isTechnician: values.isTechnician !== undefined ? values.isTechnician : false,
    isCaseHandler: values.isCaseHandler !== undefined ? values.isCaseHandler : false,
  })

  onSubmit = async (values) => {
    const {
      updateTeam,
      createTeam,
      initialValues,
      onSuccess,
    } = this.props

    const id = initialValues?.id
    await this.setState({ loading: true })
    if (id !== null && id !== undefined) {
      await updateTeam(this.mapIds(values))
    } else {
      await createTeam(this.mapIds(values))
    }
    await this.setState({ loading: false })
    onSuccess()
  }

  renderUsers = ({ fields, t, users }) => (
    <ul className="TeamForm__UserList">
      <li>
        <RFUserDropDownInput onChange={user => fields.push(user)} label={t('settings.organization.addMember')} users={users} />
        <SimpleUserRow isHeader />
      </li>
      {fields.map((member, index) => (
        <SimpleUserRow key={member} user={fields.get(index)} onRemoveClick={() => fields.remove(index)} />
      ))}
    </ul>
  )

  render() {
    const {
      t,
      handleSubmit,
      dirty,
      initialValues,
      users,
    } = this.props

    const { loading } = this.state
    const id = initialValues?.id
    const buttonLabel = id !== null ? t('buttons.save') : t('buttons.create')

    return (
      <form className="TeamForm" onSubmit={handleSubmit(this.onSubmit)}>
        <ScrollContainer className="TeamForm__Fields" scrollVertical>
          <Field name="name" className="TeamForm__Field" component={RFTextInput} label={t('table.teamName')} />
          <Field name="isCaseHandler" className="TeamForm__Field" component={RFCheckBox} label={t('table.isCaseHandler')} />
          <Field name="isTechnician" className="TeamForm__Field" component={RFCheckBox} label={t('table.isTechnician')} />
          <Field name="manager" className="TeamForm__Field" component={RFUserDropDownInput} label={t('table.manager')} users={users} />
          <FieldArray name="users" component={this.renderUsers} t={t} users={users} />
        </ScrollContainer>
        <div className="TeamForm__Footer">
          <PrimaryButton type="submit" onClick={this.handleCreateOrder} disabled={!dirty}>
            {loading ? <FontAwesomeIcon className="TeamForm__Spinner" icon={['fal', 'spinner']} spin /> : buttonLabel}
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: 'TEAM_FORM',
}

const mapStateToProps = state => ({
  users: UserSelector.getUsersAsArray(state),
})

const formComponent = reduxForm(form)(TeamForm)
const connectedComponent = connect(mapStateToProps, {
  updateTeam: updateTeamAction,
  createTeam: createTeamAction,
  requestUsers: requestUsersAction,
})(formComponent)

const translatedComponent = translate()(connectedComponent)

export default translatedComponent
