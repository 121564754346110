import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import moment from 'moment'

import {
  reduxForm,
  propTypes as reduxPropTypes,
  Field,
} from 'redux-form'

import { updateUserAction } from 'actions/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SharedUserFormFields from 'components/common/SharedUserFormFields'
import { PrimaryButton } from 'components/common/Button'
import ValueWithLabel from 'components/common/ValueWithLabel'
import RFCheckBox from 'components/common/RFCheckBox'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class UserEditForm extends Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    updateUserAction: PropTypes.func.isRequired,
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  onSubmit = async (values) => {
    const {
      updateUserAction: updateUser,
    } = this.props

    await this.setState({ loading: true })
    await updateUser(values)
    await this.setState({ loading: false })
  }

  render() {
    const {
      t,
      handleSubmit,
      dirty,
      initialValues,
    } = this.props

    const { loading } = this.state

    return (
      <form className="UserEditForm" onSubmit={handleSubmit(this.onSubmit)}>
        <ScrollContainer className="UserEditForm__Fields" scrollVertical>
          <ValueWithLabel className="UserEditForm__Value" label={t('table.dateJoined')}>
            {moment(initialValues.dateJoined).format('DD.MM.YYYY')}
          </ValueWithLabel>
          <ValueWithLabel className="UserEditForm__Value" label={t('table.email')}>
            {initialValues.email}
          </ValueWithLabel>
          <SharedUserFormFields />
          <Field name="isEnabled" className="SharedUserFormFields__Field" component={RFCheckBox} label={t('table.isEnabled')} />
        </ScrollContainer>
        <div className="UserEditForm__Footer">
          <PrimaryButton type="submit" onClick={this.handleCreateOrder} disabled={!dirty}>
            {loading ? <FontAwesomeIcon className="UserEditForm__Spinner" icon={['fal', 'spinner']} spin /> : t('buttons.save')}
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: 'USER_EDIT_FORM',
}

const formComponent = reduxForm(form)(UserEditForm)
const connectedComponent = connect(null, { updateUserAction })(formComponent)
const translatedComponent = translate()(connectedComponent)

export default translatedComponent
