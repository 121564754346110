import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'i18n'
import FilterButton from '../FilterButton'
import FilterGroup from '../FilterGroup'
import ReportSelector from 'selectors/report'
import { selectFiltersAction } from 'actions/report'

const FilterTimeframe = ({ selectedFilters, selectFilters, t }) => {

  const options = [
    'THIS_MONTH',
    'LAST_MONTH',
    'THIS_QUARTER',
    'LAST_QUARTER',
    'THIS_YEAR',
    'LAST_YEAR',
  ]

  const onClick = (timeframe) => {
    selectFilters({
      ...selectedFilters,
      timeframe,
    })
  }

  return <FilterGroup label={t(`timeframes.title`)}>
    {options.map((option) => (
      <FilterButton key={option} active={selectedFilters.timeframe === option} onClick={() => onClick(option)}>
        {t(`timeframes.${option}`)}
      </FilterButton>
    ))}
  </FilterGroup>
}

const mapStateToProps = (state) => ({
  selectedFilters: ReportSelector.getSelectedFilters(state),
})

const mapDispatchToProps = {
  selectFilters: selectFiltersAction
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FilterTimeframe))