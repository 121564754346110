/* global fetch, Headers, FormData */

import humps from 'humps'
import { getStore } from 'store'
import { getAuth, getActiveTenant } from 'selectors/authentication'
import FileSaver from 'file-saver'


class APIException extends Error {
  constructor(message, status, data) {
    super(message)
    this.message = message
    this.name = 'APIException'
    this.data = data
    this.status = status
  }
}

class Connector {
  async request(url, method, data) {
    const state = getStore().getState()

    const authentication = getAuth(state)
    const { accessToken } = authentication

    let tenantId = ''
    const activeTenant = getActiveTenant(state)
    if (activeTenant) {
      tenantId = activeTenant.id
    }

    const headers = new Headers({
      'content-type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${accessToken}`,
      tenant: tenantId,
    })

    const options = {
      method: method || 'GET',
      headers,
    }

    const decamelizedData = humps.decamelizeKeys(data)

    if ((['POST', 'PATCH', 'PUT'].indexOf(method) >= 0) && data) options.body = JSON.stringify(decamelizedData)
    const resp = await fetch(url, options)

    if (resp.status === 204) return null

    const json = await resp.json()

    if (!resp.ok) {
      const message = json.error || 'Unknown error'
      throw new APIException(`API request failed (status=${resp.status}): ${message}`, resp.status, json)
    }

    return json
  }

  async uploadToS3(url, file) {
    const headers = new Headers({
      Accept: 'application/octet-stream',
    })

    const resp = await fetch(url, {
      method: 'put',
      credentials: 'same-origin',
      body: file,
      headers,
    })

    if (!resp.ok) {
      throw new APIException(`API request failed (status=${resp.status}): Unknown error`, resp.status, null)
    }
  }

  async downloadFile(url, filename = 'order_summary.pdf') {
    const state = getStore().getState()

    const authentication = getAuth(state)
    const { accessToken } = authentication

    let tenantId = ''
    const activeTenant = getActiveTenant(state)
    if (activeTenant) {
      tenantId = activeTenant.id
    }

    const headers = new Headers({
      Accept: 'application/pdf',
      'content-type': 'application/pdf',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${accessToken}`,
      tenant: tenantId,
    })

    const options = {
      method: 'GET',
      headers,
    }

    const resp = await fetch(url, options)

    if (!resp.ok) {
      let message
      let json
      try {
        json = await resp.json()
        message = json.error || 'Unknown error'
      } catch (err) {
        message = 'Unknown error'
      }

      throw new APIException(`Download request failed (status=${resp.status}): ${message}`, resp.status, json.data)
    }
    console.log(filename)
    console.log(resp.headers)
    const contentDisposition = resp.headers.get('content-disposition')
    console.log({ contentDisposition })
    const downloadedFileName = contentDisposition ? contentDisposition.substr(contentDisposition.indexOf('=') + 1) : filename
    console.log({ downloadedFileName })

    const blob = await resp.blob()
    
    FileSaver.saveAs(blob, downloadedFileName)
  }
}

export default new Connector()
