import { handleActions } from 'redux-actions'
import {
  keys, values,
} from 'lodash'
import humps from 'humps'

import { receiveEntities } from 'actions/entities'
import { nextPageAction, previousPageAction, sortChangeAction } from 'actions/queries'

import { logout } from 'actions/authentication'
import getParamValueByName from 'helper/getParamValueByName'

const elementsPerSite = 100

/* eslint-disable no-return-assign */
const getNewMetaData = (state, viewId, newQueriesList) => {
  const metaData = state[viewId]
  const { queries } = metaData
  const newQueries = { ...queries }
  keys(newQueriesList).forEach(key => newQueries[key] = newQueriesList[key])
  const newMetaData = { ...metaData, queries: newQueries }
  const result = { ...state }
  result[viewId] = newMetaData
  return result
}

const reducer = handleActions({
  [receiveEntities]: (state, action) => {
    const result = {}
    const {
      count,
      next,
      previous,
      results,
      viewId,
    } = action.payload

    let previousPage = parseInt(getParamValueByName('page', previous), 10) || null
    if (previousPage === null && previous !== null) previousPage = 1

    let current = 1
    if (previousPage !== null) current = previousPage + 1
    const nextPage = parseInt(getParamValueByName('page', next), 10) || null

    keys(results.entities).forEach((entityTypeKey) => {
      const ids = values(results.entities[entityTypeKey]).map(entity => entity.id)
      const metaData = state[viewId] || {}
      const { queries } = metaData
      result[viewId] = {
        page: {
          count,
          pageCount: Math.floor(count / elementsPerSite) + 1,
          next: nextPage,
          current,
          previous: previousPage,
          ids,
        },
        queries: {
          ...queries,
          page: current,
        },
      }
    })
    return result
  },
  [nextPageAction]: (state, action) => {
    const metaData = state[action.payload]
    const newPage = metaData.page.next
    return getNewMetaData(state, action.payload, { page: newPage })
  },
  [previousPageAction]: (state, action) => {
    const metaData = state[action.payload]
    const newPage = metaData.page.previous
    return getNewMetaData(state, action.payload, { page: newPage })
  },
  [sortChangeAction]: (state, action) => {
    const { viewId, fieldname, desc } = action.payload
    return getNewMetaData(state, viewId, { ordering: `${desc ? '-' : ''}${humps.decamelize(fieldname)}`, page: 1 })
  },
  [logout]: () => null,
}, {})


export default reducer
