import React from 'react'
import './style.less'

const FilterButton = (props) => {
  let className = ['FilterButton']
  if (props.round) {
    className.push('FilterButton--round')
  }
  if (props.active) {
    className.push('FilterButton--active')
  }
  let style = {}
  if (props.color) {
    style.borderColor = props.color.fill
    style.color = props.color.fill
    style.opacity = props.color.fillOpacity || 1
    style.borderRadius = '2rem'

    if (props.active) {
      style.color = '#ffffff'
      style.background = props.color.fill
    }
  }
  return <button className={className.join(' ')} onClick={props.onClick} style={style}>
    {props.children}
  </button>
}

export default FilterButton