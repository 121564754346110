import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { push } from 'connected-react-router'

import './style.less'

const SideMenuHeader = ({
  infoTitle,
  infos = null,
  goBackTo,
  handleBack,
}) => (
  <div className="SideMenuHeader">
    <div className="SideMenuHeader__Header">
      <div className="SideMenuHeader__BackContainer">
        <button onClick={handleBack} type="button">
          <FontAwesomeIcon className="SideMenuHeader__Icon" icon={['fal', 'arrow-left']} />
        </button>
      </div>
      <div className="SideMenuHeader__InfosContainer">
        <div className="SideMenuHeader__InfosTitle">{infoTitle}</div>
        <div className="SideMenuHeader__Infos">{infos}</div>
      </div>
    </div>
  </div>
)

SideMenuHeader.propTypes = {
  infoTitle: PropTypes.string.isRequired,
  infos: PropTypes.any,
  goBackTo: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
}

SideMenuHeader.defaultProps = {
  infos: null,
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleBack: () => dispatch(push(ownProps.goBackTo)),
})

export default connect(null, mapDispatchToProps)(SideMenuHeader)
