import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { requestApartmentImportsAction } from 'actions/apartmentimports'
import { importApartments } from 'actions/import'

import { bytesToSize } from 'helper/utils'
import { localDateTime, userDisplayName } from 'helper/viewHelper'

import { PrimaryButton } from 'components/common/Button'
import FileDrop from 'components/common/FileDrop'

import TableDataView from 'containers/common/TableDataView'

import ApartmentImportSelector from 'selectors/apartmentimports'
import PageSelector from 'selectors/page'

import './style.less'

const VIEW_ID = 'APARTMENT_IMPORT'
const ENTITY_TYPE = 'apartmentImports'

class SettingsApartmentImport extends Component {
  static propTypes = {
    apartmentImports: PropTypes.shape({}),
    requestApartmentImportsAction: PropTypes.func,
    ...i18nPropTypes,
  }

  static defaultProps = {
    apartmentImports: {},
    requestApartmentImportsAction: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      isUploading: false,
      errorMessage: null,
      file: null,
    }
  }

  fetch = async (page) => {
    const { requestApartmentImportsAction: requestApartmentImports } = this.props
    await requestApartmentImports(page)
  }

  handleApartmentImportDrop = (files) => {
    const { t } = this.props
    this.setState({ errorMessage: null })

    if (files && files.length > 0) {
      const file = files[0]

      this.setState({
        file,
      })
    }
  }

  handleApartmentImportUpload = async () => {
    this.setState({ errorMessage: null })

    const { isUploading, file } = this.state
    if (isUploading) return

    await this.setState({
      isUploading: true,
    })

    const { importApartments: importA } = this.props
    try {
      await importA(file)
      await this.fetch(1)
    } catch (e) {
      this.setState({ errorMessage: e.message })
    }

    await this.setState({
      isUploading: false,
      file: null,
    })
  }

  renderDropZone = () => {
    const { t } = this.props
    return (
      <FileDrop
        onDrop={this.handleApartmentImportDrop}
        text={t('imports.dropzonetext')}
        textDrop={t('imports.dropzonedroptext')}
      />
    )
  }

  renderFileDetail = () => {
    const { t } = this.props
    const { file, isUploading } = this.state

    return (
      <div className="SettingsApartmentImport__FileContainer">
        <div className="SettingsApartmentImport__File">
          <div>{t('imports.importedfile')}</div>
          <FontAwesomeIcon className="SettingsApartmentImport__Icon" icon={['fal', 'file-spreadsheet']} />
          <div className="SettingsApartmentImport__FileInfos">
            <div>{file.name}</div>
            <div>{bytesToSize(file.size)}</div>
          </div>
        </div>
        <div className="SettingsApartmentImport__Hint">{t('imports.startupload')}</div>
        <div className="SettingsApartmentImport__FileButtons">
          <PrimaryButton className="SettingsApartmentImport__SubmitButton" onClick={this.handleApartmentImportUpload} type="button">
            {isUploading ? <FontAwesomeIcon className="SettingsApartmentImport__Spinner" icon={['fal', 'spinner']} spin /> : t('buttons.startImport')}
          </PrimaryButton>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props
    const { errorMessage, file } = this.state

    const columns = [
      { header: t('table.completed'), accessor: apartmentImport => localDateTime(apartmentImport.completed) },
      { header: t('common.user'), accessor: apartmentImport => userDisplayName(apartmentImport.user) },
      { header: t('table.importedApartmentsCount'), accessor: 'importedApartmentsCount' },
      { header: t('table.updatedApartmentsCount'), accessor: 'updatedApartmentsCount' },
    ]

    return (
      <div className="SettingsApartmentImport">
        <div className="SettingsApartmentImport__ImportContainer">
          {file ? this.renderFileDetail() : this.renderDropZone()}
          {errorMessage && <div className="SettingsApartmentImport__Error">{errorMessage}</div>}
        </div>
        <div className="SettingsApartmentImport__ApartmentImportContainer">
          <TableDataView viewId={VIEW_ID} entityType={ENTITY_TYPE} columns={columns} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  page: PageSelector.getCurrentApartmentImportsPage(state),
  apartmentImports: ApartmentImportSelector.getApartmentImportsForCurrentPage(state),
})

export default connect(mapStateToProps, { requestApartmentImportsAction, importApartments })(translate()(SettingsApartmentImport))
