import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

// eslint-disable-next-line
const SideMenuButton = ({ to, children, icon, selected, exact, small, subtitle }) => (
  <NavLink
    to={to}
    className={modifyClassName('SideMenuButton', { selected, small })}
    activeClassName={selected === null ? 'SideMenuButton--selected' : undefined}
    exact={exact}
  >
    <div className={modifyClassName('SideMenuButton__Icon', { small })}><FontAwesomeIcon icon={['fal', icon]} /></div>
    <div className="SideMenuButton__Children">
      {!small && children}
      { subtitle && <div className="SideMenuButton__Subtitle">{subtitle}</div>}
    </div>
  </NavLink>)

SideMenuButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  exact: PropTypes.bool,
  small: PropTypes.bool,
  subtitle: PropTypes.string,
}

SideMenuButton.defaultProps = {
  selected: null,
  exact: true,
  small: false,
  subtitle: null,
}

export default SideMenuButton
