import { handleActions } from 'redux-actions'
import {
  keys, values, set, get,
} from 'lodash'
import schema from 'api/schemas'

import { receiveEntities, removeEntity } from 'actions/entities'
import { logout } from 'actions/authentication'

const initial = {
}
Object.keys(schema).forEach((key) => { initial[key] = {} })

const reducer = handleActions({
  [receiveEntities]: (state, action) => {
    const result = { ...state }

    keys(action.payload.results.entities).forEach((entityTypeKey) => {
      const entityType = get(state, `['${entityTypeKey}']`, {})
      result[entityTypeKey] = { ...entityType }
      values(action.payload.results.entities[entityTypeKey]).forEach((entity) => {
        set(result, `${entityTypeKey}.${entity.id}`, { ...entityType?.[entity.id], ...entity })
      })
    })
    return result
  },
  [removeEntity]: (state, action) => {
    const { [`${action.payload.entityId}`]: removed, ...rest } = state[action.payload.entityType]
    return {
      ...state,
      [action.payload.entityType]: { ...rest },
    }
  },
  [logout]: () => null,
}, initial)


export default reducer
