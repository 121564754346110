/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

// taken from: https://stackoverflow.com/a/46181
export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const required = (value, allValues, props) => (value ? undefined : props.t('errors.required'))
