import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { translate } from 'i18n'
import FilterButton from '../FilterButton'
import FilterGroup from '../FilterGroup'
import ReportSelector from 'selectors/report'
import { selectFiltersAction } from 'actions/report'

const FilterSegments = ({ segments, selectedFilters, selectFilters, t }) => {

  const onClick = (s) => {
    if (segments.indexOf(s) === 0) {
      segments = null
    }
    selectFilters({
      ...selectedFilters,
      segments: s,
    })
  }

  useEffect(() => {
    if (!segments.includes(selectFilters.segments)) {
      onClick(segments[0])
    }
  }, [segments.join(',')])

  return <FilterGroup label={t('segments.title')}>
    {segments.map((segment, index) => (
      <FilterButton key={segment} active={selectedFilters.segments === segment || index === 0 && !selectedFilters.segments} onClick={() => onClick(segment)}>
        {t(`segments.${segment}`)}
      </FilterButton>
    ))}
  </FilterGroup>
}

const mapStateToProps = (state) => ({
  selectedFilters: ReportSelector.getSelectedFilters(state),
})

const mapDispatchToProps = {
  selectFilters: selectFiltersAction
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FilterSegments))