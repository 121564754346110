/* eslint-disable import/prefer-default-export */
import { numeral } from 'i18n'

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return `${Math.round(bytes / (1024 ** i), 2)} ${sizes[i]}`
}

export const currencyToCent = currency => (currency ? numeral(currency).value() * 100 : null)

export const centToCurrencyString = cent => (cent ? numeral(cent / 100.00).format('0[.][00]') : null)

export const formatReportValue = (value, valueType) => {
  switch (valueType) {
    case 'CURRENCY':
      return `€ ${numeral(value / 100.0).format('0,0.00')}`
    case 'PERCENT':
      return `${numeral(value * 100.0).format('0,0.0')}%`
    default:
      return value
  }
}
