import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import {
  get,
  isFunction,
  isString,
  isEqual,
} from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { intializeViewAction, changePageAction, changeSortAction } from 'actions/data-view'
import { metaDataSelector, dataSelector } from 'selectors/data-view'

import ScrollContainer from 'components/common/ScrollContainer'

import modifyClassName from 'helper/modifyClassName'

import './style.less'

class TableDataView extends Component {
  static propTypes = {
    ...i18nPropTypes,
    viewId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    intializeView: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changeSort: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    metaData: PropTypes.shape({
      status: PropTypes.string,
    }),
    filters: PropTypes.shape({}),
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSelectEntity: PropTypes.func,
  }

  static defaultProps = {
    data: [],
    metaData: {},
    onSelectEntity: null,
    filters: null,
  }

  componentDidMount = () => {
    this.triggerIntializeView()
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props
    if (!isEqual(prevProps.filters, filters)) {
      this.triggerIntializeView()
    }
  }

  triggerIntializeView = () => {
    const {
      intializeView,
      viewId,
      entityType,
      filters,
    } = this.props
    intializeView(viewId, entityType, filters)
  }

  handleSort = ({ accessor, ordering }) => () => {
    const { viewId, changeSort } = this.props
    if (ordering || isString(accessor)) {
      changeSort(viewId, ordering || accessor)
    }
  }

  handleSelectEntity = entity => () => this.props.onSelectEntity?.(entity)

  renderLoading = () => (
    <div className="TableDataView__StatusIndicator">
      <FontAwesomeIcon icon={['fal', 'spinner']} spin />
    </div>
  )

  renderHeaderRow = () => {
    const { columns } = this.props
    return (
      <tr className="TableDataView__HeaderRow">
        { columns.map(this.renderHeaderCell) }
      </tr>)
  }

  renderHeaderCell = (column) => {
    const { metaData: { ordering } } = this.props
    const attributes = [column.accessor, column.ordering]
    const showAsc = attributes.includes(ordering.attribute) && ordering.asc
    const showDesc = attributes.includes(ordering.attribute) && !ordering.asc
    const style = column.style || {}
    return (
      <th key={`header-${column.header}`} style={style} >
        <button className="TableDataView__HeaderButton" type="button" onClick={this.handleSort(column)}>
          {column.header}
          { showAsc && <span className="TableDataView__SortIndicator">{'\uF0DE'}</span>}
          { showDesc && <span className="TableDataView__SortIndicator">{'\uF0DD'}</span>}
        </button>
      </th>)
  }

  renderRow = entity => (
    <tr className="TableDataView__Row" key={`row-${entity.id}`} onClick={this.handleSelectEntity(entity)}>
      { this.props.columns.map(column => this.renderCell(entity, column))}
    </tr>)

  renderCell = (entity, column) => {
    const value = isFunction(column.accessor) ? column.accessor(entity) : get(entity, column.accessor)
    const style = column.style || {}
    return (
      <td className="TableDataView__Cell" style={style} key={`cell-${entity.id}-${column.header}`}>{value}</td>
    )
  }

  changePage = pageChange => () => {
    const { changePage, viewId } = this.props
    changePage(viewId, pageChange)
  }

  renderTable = () => {
    const { data } = this.props

    return (
      <div className="TableDataView__TableContainer">
        <table className="TableDataView__Table">
          <thead>
            { this.renderHeaderRow() }
          </thead>
          <tbody>
            { data.map(entity => this.renderRow(entity))}
          </tbody>
        </table>
      </div>)
  }

  renderPaging = () => {
    const { metaData } = this.props
    return (
      <div className="TableDataView__Pagination">
        <button
          type="button"
          className={modifyClassName('TableDataView__PaginationButton', { visible: metaData?.paging?.hasPrevious })}
          onClick={this.changePage(-1)}
        >
          <FontAwesomeIcon icon={['fal', 'angle-left']} />
        </button>
        <div className="TableDataView__PaginationCount">{`Seite ${metaData?.paging?.page}/${metaData?.paging?.pageCount}`}</div>
        <button
          type="button"
          className={modifyClassName('TableDataView__PaginationButton', { visible: metaData?.paging?.hasNext })}
          onClick={this.changePage(1)}
        >
          <FontAwesomeIcon icon={['fal', 'angle-right']} />
        </button>
      </div>)
  }

  renderError = () => (
    <div className="TableDataView__StatusIndicator">
      Error
    </div>
  )

  renderData = () => {
    const { metaData } = this.props
    switch (metaData?.status) {
      case 'FETCHED':
        return this.renderTable()
      case 'ERROR':
        return this.renderError()
      default:
        return this.renderLoading()
    }
  }

  render = () => (
    <div className="TableDataView">
      <ScrollContainer className="TableDataView__Data" scrollVertical>
        {this.renderData()}
      </ScrollContainer>
      {this.renderPaging()}
    </div>)
}

const mapStateToProps = (state, props) => ({
  metaData: metaDataSelector(state, props),
  data: dataSelector(state, props),
})
const mapDispatchToProps = {
  intializeView: intializeViewAction,
  changePage: changePageAction,
  changeSort: changeSortAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TableDataView))
