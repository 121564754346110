import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HotKeys } from 'react-hotkeys'
import preventDefaultPropagation from 'helper/eventHandler'

import './style.less'

class SearchField extends Component {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    placeholder: '',
    onSubmit: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  handleOnChange = (e) => {
    this.setState({
      value: e.target.value,
    })
  }

  handleSubmitPress = () => {
    const { value } = this.state
    const { onSubmit } = this.props
    onSubmit(value)
  }

  render() {
    const {
      className,
      placeholder,
    } = this.props

    const { value } = this.state

    const handlers = {
      enter: preventDefaultPropagation(this.handleSubmitPress),
    }

    return (
      <HotKeys focused handlers={handlers}>
        <div className={`SearchField ${className}`}>
          <div className="SearchField__InputContainer">
            <input className="SearchField__Input" type="text" onChange={this.handleOnChange} value={value} placeholder={placeholder} />
            <button className="SearchField__Button" type="button" onClick={this.handleSubmitPress}>
              <FontAwesomeIcon icon={['fal', 'search']} />
            </button>
          </div>
        </div>
      </HotKeys>
    )
  }
}

export default SearchField
