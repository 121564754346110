import * as api from 'api'
import { denormalize } from 'normalizr'

import { contractorSchema } from 'api/schemas'
import { receiveEntities } from 'actions/entities'

export const createContractorAction = contractor => async (dispatch, getState) => {
  const payload = await api.createContractor(contractor)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, contractorSchema, getState().entities)
}

export const updateContractorAction = contractor => async (dispatch, getState) => {
  const payload = await api.updateContractor(contractor)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, contractorSchema, getState().entities)
}