import { createAction } from 'redux-actions'
import * as api from 'api'
import { getActiveTenant } from 'selectors/authentication'

export const userMeChanged = createAction('USER_ME_CHANGED')
export const activeTenantChanged = createAction('ACTIVE_TENANT_CHANGED')

export const userMeAction = () => async (dispatch, getState) => {
  const payload = await api.userMe()
  const { tenants = [] } = payload
  // user got one tenant
  if (tenants.length === 1) {
    await dispatch(activeTenantChanged(tenants[0]))
  } else {
    // check if activeTenant still part of user.tenants
    const storedActiveTenant = getActiveTenant(getState())
    if (storedActiveTenant) {
      const foundTenant = tenants.find(tenant => tenant.id === storedActiveTenant.id)
      // override activeTenant to save possible changes in local storage
      if (foundTenant) {
        await dispatch(activeTenantChanged(foundTenant))
      } else {
        // the stored active tenant is no longer part of user.tenants
        // -> deleting local stored tenant data
        await dispatch(activeTenantChanged(null))
      }
    }
  }
  const result = await dispatch(userMeChanged(payload))
  return result
}
