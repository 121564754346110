import React from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash'
import Task from 'components/task/Task'
import TaskLaneHeader from 'components/task/TaskLaneHeader'
import modifyClassName from 'helper/modifyClassName'
import ScrollContainer from 'components/common/ScrollContainer'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import './style.less'

const TaskLane = ({
  className,
  laneLabel,
  isFirstLane,
  isLastLane,
  tasks,
  onTaskSelect,
}) => (
  <div className={`TaskLane ${className || ''}`}>
    <TaskLaneHeader laneLabel={laneLabel} isFirstLane={isFirstLane} isLastLane={isLastLane} />
    <ScrollContainer className={modifyClassName('TaskLane__TaskContainer', { rightBorder: !isLastLane })} scrollVertical>
      {values(tasks).map(task => <Task key={task.id} task={task} onSelect={onTaskSelect} />)}
    </ScrollContainer>
  </div>
)

TaskLane.propTypes = {
  onTaskSelect: PropTypes.func,
  laneLabel: PropTypes.string,
  isFirstLane: PropTypes.bool,
  isLastLane: PropTypes.bool,
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  ...i18nPropTypes,
}

TaskLane.defaultProps = {
  onTaskSelect: () => {},
  tasks: [],
  isFirstLane: false,
  isLastLane: false,
  className: null,
  laneLabel: '',
}

export default translate()(TaskLane)
