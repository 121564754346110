import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.less'

class Checkbox extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    enabled: PropTypes.bool,
    loading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    checked: false,
    enabled: true,
    loading: false,
    className: '',
  }

  handleOnChange = () => {
    const { checked, onChange } = this.props
    onChange(!checked)
  }

  render = () => {
    const {
      label,
      checked,
      enabled,
      className,
      loading,
    } = this.props
    
    return (
      <button type="button" className={`Checkbox ${className}`} onClick={this.handleOnChange} disabled={!enabled || loading}>
        {loading ? <div className="Checkbox__Loading"><FontAwesomeIcon icon={['fal', 'spinner']} spin /></div> : <div className="Checkbox__Box" />}
        { (checked && !loading) && <FontAwesomeIcon className="Checkbox__Mark" icon={['fal', 'check']} />}
        <div className="Checkbox__Label">{label}</div>
      </button>
    )
  }
}

export default Checkbox
