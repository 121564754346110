import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { requestEntitiesAction } from 'actions/entities'
import ContractorSelector from 'selectors/contractor'

import RFDropDownInput from 'components/common/RFDropDownInput'

class RFContractorDropDown extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    catalog: PropTypes.shape({}),
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    onChange: PropTypes.func,
    ...i18nPropTypes,
  }

  static defaultProps = {
    className: '',
    input: {
      onChange: () => {},
    },
    meta: {},
    onChange: () => {},
    catalog: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      catalogId: null,
    }
    this.fetchContractors()
  }

  componentDidUpdate() {
    const { catalog, input, contractors } = this.props
    const { loading, catalogId } = this.state
    if (!loading && (catalogId !== catalog?.id)) {
      this.fetchContractors()
    }

    if (input?.value && catalog?.id) {
      if (contractors.find(contractor => contractor.id === input.value.id) === undefined) {
        input.onChange(null)
      }
    }
  }

  fetchContractors = async () => {
    const { requestEntities, catalog } = this.props
    this.setState({ loading: true })
    if (catalog?.id !== undefined) {
      await requestEntities('contractors', { catalog: catalog?.id })
    }
    this.setState({ loading: false, catalogId: catalog?.id })
  }

  onSuggestionsFetchRequested = () => { }

  getSuggestionValue = contractor => contractor?.name || contractor?.id

  render = () => {
    const { contractors } = this.props
    const { loading } = this.state
    return (
      <RFDropDownInput
        {...this.props}
        isLoading={loading}
        suggestions={contractors}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        getSuggestionValue={this.getSuggestionValue}
      />
    )
  }
}

const makeMapStateToProps = () => {
  const getContractorsForCatalogInForm = ContractorSelector.makeContractorsForCatalogInFormSelector()
  const mapStateToProps = (state, ownProps) => ({
    contractors: getContractorsForCatalogInForm(state, ownProps),
  })
  return mapStateToProps
}

const connectedComponent = connect(makeMapStateToProps, {
  requestEntities: requestEntitiesAction,
})(RFContractorDropDown)

const translatedComponent = translate()(connectedComponent)

export default translatedComponent
