import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import UserSelector from 'selectors/user'
import { createCommentAction } from 'actions/comments'
import CommentInput from 'components/common/CommentInput'
import ChangelogItem from 'components/common/ChangelogItem'

import './style.less'

class CommentContainer extends Component {
  static propTypes = {
    lineItem: PropTypes.shape({}),
    orderHeader: PropTypes.shape({}),
    currentUser: PropTypes.shape({}).isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    lineItem: null,
    orderHeader: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      fetching: false,
    }
  }

  handleOnSubmit = async (comment) => {
    const { lineItem, createComment, orderHeader } = this.props
    await this.setState({ fetching: true })
    await createComment({
      lineItemId: lineItem?.id,
      orderHeaderId: orderHeader?.id,
      comment,
    })
    await this.setState({ fetching: false })
  }

  onClickFilter = (filter) => {
    this.setState({ filter })
  }

  render() {
    const { currentUser, lineItem, orderHeader, products, t } = this.props
    const { fetching } = this.state
    const comments = lineItem?.comments || orderHeader?.comments || []
    const changelogs = (lineItem?.changelogs || [])
      .filter(item => item.orderProcessState !== 'Draft')
      .map(item => {
        if (item.attribute === 'product') {
          item = {
            ...item,
            oldValue: products ? products[item.oldValue] : null,
            newValue: products ? products[item.newValue] : null,
          }
        }
        return {
          ...item,
          product: products ? products[lineItem.product] : null,
        }
      })

    let items = [...comments, ...changelogs]
      .sort((a, b) => a.created > b.created ? -1 : 1)

    return (
      <div className="CommentContainer">
        <CommentInput user={currentUser} onSubmit={this.handleOnSubmit} fetching={fetching} />
        {changelogs.lemgth > 0 && <div className="CommentContainer__ChangelogTitle">{t('changelogs.title')}</div>}
        {items?.map(item => <ChangelogItem key={item?.id} changelogItem={item} />)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: UserSelector.getCurrentUser(state),
  products: state.entities.products,
})

export default connect(mapStateToProps, { createComment: createCommentAction })(translate()(CommentContainer))
