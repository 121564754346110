import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import {
  reduxForm,
  Field,
  propTypes as reduxPropTypes,
  FieldArray,
} from 'redux-form'

import { values as _values } from 'lodash'

import {
  requestRoomSizesAction,
  updateRoomSizeAction,
  createRoomSizeAction,
  deleteRoomSizeAction,
} from 'actions/roomsizes'

import EnumValuesSelector from 'selectors/enumValues'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RoomSizeItem from 'components/form/RoomSizeItem'
import { PrimaryButton } from 'components/common/Button'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

class RoomSizeConfigurationForm extends Component {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    requestRoomSizesAction: PropTypes.func.isRequired,
    createRoomSizeAction: PropTypes.func.isRequired,
    updateRoomSizeAction: PropTypes.func.isRequired,
    deleteRoomSizeAction: PropTypes.func.isRequired,
    roomSizes: PropTypes.shape({}),
    ...reduxPropTypes,
    ...i18nPropTypes,
  }

  static defaultProps = {
    roomSizes: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    this.fetchRoomSizes()
  }

  fetchRoomSizes = async () => {
    const { requestRoomSizesAction: requestRoomSizes } = this.props
    this.setState({ loading: true })
    await requestRoomSizes()
    this.setState({ loading: false })
  }

  handleAddRoomSize = async () => {
    const { createRoomSizeAction: createRoomSize } = this.props
    await this.setState({ loading: true })
    await createRoomSize()
    await this.setState({ loading: false })
  }

  handleDeleteRoomSize = async (roomsize) => {
    const { deleteRoomSizeAction: deleteRoomSize } = this.props
    await this.setState({ loading: true })
    await deleteRoomSize(roomsize)
    await this.setState({ loading: false })
  }

  onSubmit = async (values) => {
    const {
      updateRoomSizeAction: updateRoomSize,
    } = this.props

    const { roomsizes } = values

    await this.setState({ loading: true })
    await Promise.all(_values(roomsizes).map(roomsize => updateRoomSize(roomsize)))
    await this.setState({ loading: false })
  }

  renderRoomSizeItems = ({
    fields,
    loading,
    handleDeleteRoomSize,
  }) => (
    <ul className="RoomSizeConfigurationForm__FieldArray">
      {fields
        .map((member, index) => (
          <li key={member}>
            <Field
              name={member}
              component={RoomSizeItem}
              roomSize={fields.get(index)}
              isLoading={loading}
              handleDeleteRoomSize={handleDeleteRoomSize}
            />
          </li>
        ))
    }
    </ul>
  )

  render() {
    const {
      t,
      handleSubmit,
      roomSizes,
      dirty,
    } = this.props

    if (!roomSizes) return null

    const { loading } = this.state

    return (
      <form className="RoomSizeConfigurationForm" onSubmit={handleSubmit(this.onSubmit)}>
        <ScrollContainer className="RoomSizeConfigurationForm__Fields" scrollVertical>
          <FieldArray name="roomsizes" component={this.renderRoomSizeItems} loading={loading} handleDeleteRoomSize={this.handleDeleteRoomSize} />
          <button className="RoomSizeConfigurationForm__PlusButton" type="button" onClick={this.handleAddRoomSize}>
            <FontAwesomeIcon className="RoomSizeConfigurationForm__PlusIcon" icon={['fal', 'plus']} />
            {t('settings.roomsize.add')}
          </button>
        </ScrollContainer>
        <div className="RoomSizeConfigurationForm__Footer">
          <PrimaryButton type="submit" onClick={this.handleCreateOrder} disabled={!dirty}>
            {loading ? <FontAwesomeIcon className="RFApartmentRoomSizes__Spinner" icon={['fal', 'spinner']} spin /> : t('buttons.save')}
          </PrimaryButton>
        </div>
      </form>
    )
  }
}

const form = {
  enableReinitialize: true,
  form: 'ROOM_SIZE_CONFIGURATION_FORM',
}

const mapStateToProps = state => ({
  roomSizes: EnumValuesSelector.getRoomSizes(state),
  initialValues: { roomsizes: _values(EnumValuesSelector.getRoomSizes(state)) },
})

const formComponent = reduxForm(form)(RoomSizeConfigurationForm)
const connectedComponent = connect(mapStateToProps, {
  requestRoomSizesAction,
  updateRoomSizeAction,
  createRoomSizeAction,
  deleteRoomSizeAction,
})(formComponent)
const translatedComponent = translate()(connectedComponent)

export default translatedComponent
