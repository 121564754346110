import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const ValueWithLabel = ({
  className,
  valueClassName,
  labelClassName,
  children,
  label,
  value,
  valueState,
}) => (
  <div className={`ValueWithLabel ${className}`}>
    <div className={`ValueWithLabel__Label ${labelClassName}`}>{label}</div>
    <div className={`ValueWithLabel__Value ${valueClassName} ValueWithLabel__Value--${valueState}`}>{children || value}</div>
  </div>
)

ValueWithLabel.propTypes = {
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueState: PropTypes.string,
}

ValueWithLabel.defaultProps = {
  className: '',
  valueClassName: '',
  labelClassName: '',
  children: '',
  label: '',
  value: '',
  valueState: null,
}

export default ValueWithLabel
