import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Price from 'components/common/Price'
import moment from 'moment'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import './style.less'

class Task extends Component {
  static propTypes = {
    task: PropTypes.shape({}).isRequired,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    ...i18nPropTypes,
  }

  static defaultProps = {
    onSelect: () => {},
    className: null,
  }

  handleClick = (e) => {
    e.stopPropagation()
    const { onSelect, task } = this.props
    onSelect(task)
  }

  render() {
    const { className, task } = this.props
    const splittedAddress = task?.apartment?.address?.split(',') || ['', '']
    const fromNow = moment(task.modified).fromNow()
    return (
      <div className="Task">
        <button type="button" onClick={this.handleClick} className={`Task__Button ${className || ''}`}>
          <div className="Task__ExternalId">
            {task.apartment.externalId}
          </div>
          <div className="Task__ApartmentLocation">
            {task.apartment.location}
          </div>
          <div className="Task__Address">
            <div className="Task__Street">
              {splittedAddress[0]}
            </div>
            <div className="Task__Zipcode">
              {splittedAddress[1]}
            </div>
          </div>
          <div className="Task__Price">
            <Price>{task.totalPrice}</Price>
          </div>
          <div className="Task__Dates">
            <div className="Task__Created">
              {`Angelegt am: ${moment(task.created).format('DD.MM.YYYY')}`}
            </div>
            <div className="Task__Modified">
              {`${fromNow} aktualisiert`}
            </div>
          </div>
        </button>
      </div>
    )
  }
}

export default translate()(Task)
