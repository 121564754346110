import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'

import { TopBar } from 'components/common/ToolBar'
import { PrimaryButton } from 'components/common/Button'
import BoolValue from 'components/common/BoolValue'
import TableDataViewDeleteButton from 'containers/common/TableDataViewDeleteButton'
import { getIsInTenantContext } from 'selectors/navigation'
import UserSelector from 'selectors/user'

import TableDataView from 'containers/common/TableDataView'

import './style.less'

const VIEW_ID = 'PACKAGES'
const ENTITY_TYPE = 'packages'

class ProductPackageList extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    isInTenantContext: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  handleSelectEntity = (entity) => {
    const { isInTenantContext, push: routeTo } = this.props
    if (isInTenantContext) {
      routeTo(`/settings/packages/${entity.id}`)
    } else if (entity?.owner) {
      routeTo(`/packages/${entity.id}`)
    }
  }

  handleCreatePackage = () => (this.props.isInTenantContext ? this.props.push('/settings/packages/create') : this.props.push('/packages/create'))

  render() {
    const { t, isInTenantContext } = this.props

    const columns = [
      { header: t('table.packageName'), accessor: 'name' },
      { header: t('table.isPrivatePackage'), accessor: productPackage => <BoolValue value={productPackage?.owner !== null} /> },
      { header: t('table.catalogName'), accessor: productPackage => productPackage?.catalog?.name || '' },
      { header: t('buttons.delete'), accessor: productPackage => (isInTenantContext || productPackage?.owner ? <TableDataViewDeleteButton entity={productPackage} entityType={ENTITY_TYPE} /> : null) },
    ]
    return (
      <div className="ProductPackageList">
        <TopBar className="ProductPackageList__TopBar">
          <PrimaryButton onClick={this.handleCreatePackage}>{t('buttons.createPackage')}</PrimaryButton>
        </TopBar>
        <TableDataView
          viewId={VIEW_ID}
          entityType={ENTITY_TYPE}
          columns={columns}
          onSelectEntity={this.handleSelectEntity}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: UserSelector.getCurrentUser(state),
  isInTenantContext: getIsInTenantContext(state),
})

const mapDispatchToProps = {
  push,
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProductPackageList))
