import moment from 'moment'
import { createSelector } from 'reselect'
import i18n from 'i18n'
import {
  flatten,
  without,
  isEmpty,
} from 'lodash'

const reportMetaSelector = state => state.report || {}
const reportsSelector = state => state.entities?.reports || {}

const subtractOne = context => date => date.clone().add(-1, context)

const TIME_FRAMES_META = {
  THIS_MONTH: { context: 'month' },
  LAST_MONTH: { processDate: subtractOne('month'), context: 'month' },
  THIS_QUARTER: { context: 'quarter' },
  LAST_QUARTER: { processDate: subtractOne('quarter'), context: 'quarter' },
  THIS_YEAR: { context: 'year' },
  LAST_YEAR: { processDate: subtractOne('year'), context: 'year' },
}

const getTimeframeRelevantContextMonth = (date, context) => {
  const startMonth = date.startOf(context).month()
  const endMonth = date.endOf(context).month()
  return {
    from: startMonth,
    to: endMonth,
  }
}

const flattenData = {
  STACKED_BAR_TIME_SERIES: (report, timeframeKey) => {
    const now = moment()
    const timeframe = getTimeframeRelevantContextMonth(
      TIME_FRAMES_META[timeframeKey]?.processDate?.(now) || now,
      TIME_FRAMES_META[timeframeKey].context,
    )
    const months = moment.monthsShort()

    return months.map((month, index) => {
      const detailData = report.detailData?.find(data => data.label === index + 1)?.data || []
      const data = detailData.reduce((obj, current) => ({ ...obj, [current.label]: current.data }), {})
      return { label: month, ...data }
    }).slice(timeframe.from, timeframe.to + 1)
  },
  STACKED_BAR: (report, timeframeKey, filters) => {
    return report.detailData?.map(({ label, data }) => {
      const notAssigned = i18n.t('common.notAssigned')
      let flattenedData = flattenDataRecursive(data)

      // combine teams data if no team filter is selected
      // if (!teams || teams.length === 0) {
      if (filters.teamsAccumulated) {
        flattenedData = Object.keys(flattenedData).reduce((acc, key) => {
          const segmentKey = key.split('.').at(1)
          const value = flattenedData[key]
          if (!acc[`Alle Teams.${segmentKey}`]) {
            acc[`Alle Teams.${segmentKey}`] = value
          } else {
            acc[`Alle Teams.${segmentKey}`] += value
          }
          return acc
        }, {})

        // combine percent
        if (filters.segments === 'percentage') {
          const sum = Object.keys(flattenedData).reduce((acc, key) => {
            return acc + flattenedData[key]
          }, 0)
          flattenedData = Object.keys(flattenedData)
            .reduce((acc, key) => {
              acc[key] = flattenedData[key] / sum * 100
              return acc
            }, {})
        }

        // combine avg days
        if (report.detailValueType === 'DAYS') {
          const sum = Object.keys(flattenedData).reduce((acc, key) => {
            if (!acc[key]) {
              acc[key] = flattenedData[key]
            } else {
              acc[key] += flattenedData[key]
            }
            return acc
          }, {})
          const teamsCount = filters.teams.length || filters.teamsCount
          flattenedData = Object.keys(flattenedData)
            .reduce((acc, key) => {
              acc[key] = sum[key] / teamsCount
              return acc
            }, {})
        }
      }

      return { label: label || notAssigned, ...flattenedData }
    })
  },
}

const flattenDataRecursive = (data, label = null, flatObj = {}) => {
  return data.reduce((obj, current) => {
    const newLabel = label ? label + '.' + current.label : current.label
    // console.log(newLabel, current.label, current.data.constructor === Array)
    if (current.data.constructor === Array) {
      flattenDataRecursive(current.data, newLabel, obj)
    } else {
      obj[newLabel] = current.data
    }
    return obj
  }, flatObj)
}

export const TIME_FRAMES_KEYS = [
  'THIS_MONTH',
  'LAST_MONTH',
  'THIS_QUARTER',
  'LAST_QUARTER',
  'THIS_YEAR',
  'LAST_YEAR',
]

const getDates = (date, context) => {
  const startDate = date.startOf(context).format('YYYY-MM-DD')
  const endDate = date.endOf(context).format('YYYY-MM-DD')
  return {
    from: startDate,
    to: endDate,
  }
}

const getFlattenedReportData = (report, timeframe, filters) => flattenData[report.detailChartType]?.(report, timeframe, filters) || []

const getDataKeysFromFlattenedReportData = (flattenedData) => {
  const keys = new Set(flatten(flattenedData.map(item => Object.keys(item))))
  return without([...keys], 'label')
}

const getSelectedReportIdentifier = createSelector(
  reportMetaSelector,
  reportMeta => reportMeta?.selectedReport?.id,
)

const getSelectedDetailReport = createSelector(
  getSelectedReportIdentifier,
  reportsSelector,
  (reportId, reports) => reports?.[reportId] || {},
)

const getSelectedTimeframe = createSelector(
  reportMetaSelector,
  reportMeta => reportMeta.selectedFilters.timeframe,
)

// const getSelectedTeamFilter = createSelector(
//   reportMetaSelector,
//   reportMeta => reportMeta.selectedFilters.teams,
// )

// const getSelectedTeamsAccumulatedFilter = createSelector(
//   reportMetaSelector,
//   reportMeta => reportMeta.selectedFilters.teamsAccumulated,
// )
// const getSelectedSegmentsFilter = createSelector(
//   reportMetaSelector,
//   reportMeta => reportMeta.selectedFilters.segments,
// )

const getSelectedFilters = createSelector(
  reportMetaSelector,
  reportMeta => reportMeta.selectedFilters,
)

const getSelectedFlattenedDetailReport = createSelector(
  getSelectedDetailReport,
  getSelectedTimeframe,
  getSelectedFilters,
  (report, timeframe, filters) => (isEmpty(report)
    ? []
    : getFlattenedReportData(report, timeframe, filters))
)

const getSelectedDetailReportDataKeys = createSelector(
  getSelectedFlattenedDetailReport,
  flattenedData => getDataKeysFromFlattenedReportData(flattenedData),
)

const getSelectedReport = createSelector(
  reportMetaSelector,
  reportMeta => reportMeta.selectedReport,
)

export const getFromToDatesForTimeframe = createSelector(
  getSelectedTimeframe,
  (timeframe) => {
    const now = moment()
    return getDates(TIME_FRAMES_META[timeframe]?.processDate?.(now) || now, TIME_FRAMES_META[timeframe].context)
  },
)

export default {
  getSelectedTimeframe,
  getSelectedFilters,
  getSelectedReport,
  getSelectedDetailReport,
  getSelectedFlattenedDetailReport,
  getSelectedDetailReportDataKeys,
  getFromToDatesForTimeframe,
  getSelectedReportIdentifier,
}
