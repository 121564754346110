import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { centToCurrencyString, currencyToCent } from 'helper/utils'
import { numeral } from 'i18n'

import TextInput from 'components/common/TextInput'


const RFTextInput = props => (
  <TextInput
    errorMessage={(props.meta.error && props.meta.touched) ? props.meta.error : null}
    onChange={props.input.onChange}
    value={props.input.value}
    {...props}
  />
)

RFTextInput.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired,
}

export default RFTextInput

export class CurrencyField extends Component {
  static propTypes = {
    input: PropTypes.shape({}).isRequired,
    numberFormat: PropTypes.string,
    decimalPlaces: PropTypes.number,
    suffix: PropTypes.string,
  }

  static defaultProps = {
    numberFormat: '0.00',
    decimalPlaces: 0,
    suffix: '€',
  }

  constructor(props) {
    super(props)
    const value = props?.input?.value

    this.state = { strValue: value ? numeral(value / 100.00).format(props.numberFormat) : '' }
  }

  handleOnChange = (e) => {
    const { input, decimalPlaces, numberFormat } = this.props
    const { onChange } = input
    let strValue = e.target?.value || ''
    const newValue = currencyToCent(strValue) || null
    const newValueFixed = parseFloat(newValue?.toFixed(decimalPlaces)) || null

    if (newValue?.toFixed(decimalPlaces) != newValueFixed?.toFixed(decimalPlaces)) {
      strValue = numeral(newValueFixed / 100.00).format(numberFormat)
    }
    onChange(newValueFixed)
    this.setState({ strValue })
  }

  componentDidUpdate = (prevProps) => {
    const prevValue = prevProps?.input?.value
    const value = this.props?.input?.value
    const { strValue } = this.state
    const { numberFormat } = this.props

    if (prevValue !== value && value / 100 !== numeral(strValue).value()) {
      this.setState({ strValue: numeral(value / 100.00).format(numberFormat) })
    }
  }

  render() {
    const { input, suffix } = this.props
    const { strValue } = this.state
    const newInput = { ...input, value: strValue }
    const preparedProps = { ...this.props, input: newInput }
    return (
      <RFTextInput {...preparedProps} suffix={suffix} type="text" min={0} onChange={this.handleOnChange} />
    )
  }
}

// eslint-disable-next-line react/no-multi-comp
export class NumberField extends Component {
  static propTypes = {
    input: PropTypes.shape({}).isRequired,
    numberFormat: PropTypes.string,
    decimalPlaces: PropTypes.number,
  }

  static defaultProps = {
    numberFormat: '0.00',
    decimalPlaces: 2,
  }

  constructor(props) {
    super(props)
    const value = props?.input?.value

    this.state = { strValue: value ? numeral(value).format(props.numberFormat) : '' }
  }

  handleOnChange = (e) => {
    const { input, decimalPlaces, numberFormat } = this.props
    const { onChange } = input
    let strValue = e.target?.value || ''
    const newValue = numeral(strValue).value() || null
    const newValueFixed = parseFloat(newValue?.toFixed(decimalPlaces)) || null
    
    if (newValue != newValueFixed) {
      strValue = numeral(newValueFixed).format(numberFormat)
    }
    onChange(newValueFixed)
    this.setState({ strValue })
  }

  componentDidUpdate = (prevProps) => {
    const prevValue = prevProps?.input?.value
    const value = this.props?.input?.value
    const { strValue } = this.state
    const { numberFormat } = this.props

    if (prevValue !== value && value !== numeral(strValue).value()) {
      this.setState({ strValue: numeral(value).format(numberFormat) })
    }
  }

  render() {
    const { input } = this.props
    const { strValue } = this.state
    const newInput = { ...input, value: strValue }
    const preparedProps = { ...this.props, input: newInput }
    return (
      <RFTextInput {...preparedProps} type="text" min={0} onChange={this.handleOnChange} />
    )
  }
}
