import React from 'react'
import PropTypes from 'prop-types'

import './style.less'

const ModifiedProcessTitle = modifier => WrappedComponent => ({ ...props }) => (
  <WrappedComponent modifier={modifier} {...props} />
)

const ProcessTitle = ({ className, children, modifier }) => (
  <div className={`ProcessTitle ${className} ProcessTitle--${modifier}`}>{children}</div>)

ProcessTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
  modifier: PropTypes.string.isRequired,
}

ProcessTitle.defaultProps = {
  className: '',
}

export const Title = ModifiedProcessTitle('title')(ProcessTitle)
export const SubTitle = ModifiedProcessTitle('subTitle')(ProcessTitle)
