import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { contractorCatalogsDisplayName } from 'helper/viewHelper'
import { push } from 'connected-react-router'

import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import TableDataView from 'containers/common/TableDataView'
import { TopBar } from 'components/common/ToolBar'
import { PrimaryButton } from 'components/common/Button'

import './style.less'

const VIEW_ID = 'CONTRACTORS'
const ENTITY_TYPE = 'contractors'

class SettingsContractors extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  handleCreateContractor = () => this.props.push('/settings/contractors/create')

  handleSelectEntity = entity => this.props.push(`/settings/contractors/${entity.id}`)

  render() {
    const { t, catalogs } = this.props

    const columns = [
      { header: t('table.contractorName'), accessor: 'name' },
      { header: t('table.catalogNames'), accessor: contractor => contractorCatalogsDisplayName(contractor, catalogs) },
    ]

    return (
      <div className="SettingsContractors">
        <TopBar className="SettingsContractors__TopBar">
          <PrimaryButton onClick={this.handleCreateContractor}>{t('buttons.createContractor')}</PrimaryButton>
        </TopBar>
        <div className="SettingsContractors__Content">
          <TableDataView
            viewId={VIEW_ID}
            entityType={ENTITY_TYPE}
            columns={columns}
            onSelectEntity={this.handleSelectEntity}
          />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  push,
}

const connectedComponent = connect(null, mapDispatchToProps)(translate()(SettingsContractors))

export default AsyncDataLoadingHOC({
  catalogs: {
    entityType: 'catalogs',
  },
})(translate()(connectedComponent))
