import { handleActions } from 'redux-actions'

import { setScrollToProductAction, unsetScrollToProductAction } from 'actions/scrolltoproduct'

const reducer = handleActions({
  [setScrollToProductAction]: (state, action) => ({
    ...state,
    productId: action.payload.id,
  }),
  [unsetScrollToProductAction]: (state) => ({
    ...state,
    productId: null,
  }),
}, {
  productId: null,
})

export default reducer
