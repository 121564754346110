import _ from 'lodash'
import { createSelector } from 'reselect'
import { getSelectedUserId } from 'selectors/navigation'
import { denormalize } from 'normalizr'
import { userSchema } from 'api/schemas'

const entitySelector = state => state?.entities || {}
const usersSelector = state => state?.entities?.users || {}

const userSelector = state => _.get(state, 'user.current', {})

const getRoles = state => state?.user?.activeTenant?.roles || []

const getCurrentSelectedUser = createSelector(
  entitySelector,
  getSelectedUserId,
  (entities, selectedUserId) => {
    if (!entities.users) return null
    return denormalize(selectedUserId, userSchema, entities)
  },
)

export default {
  getRoles,
  getCurrentSelectedUser,
  getCurrentUser: userSelector,
  getUsersAsArray: state => _.values(usersSelector(state)),
}
