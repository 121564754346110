import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field } from 'redux-form'

import RFTextInput from 'components/common/RFTextInput'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import './style.less'

class RoomSizeItem extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    handleDeleteRoomSize: PropTypes.func.isRequired,
    input: PropTypes.shape({}).isRequired,
    meta: PropTypes.shape({}).isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    isLoading: false,
  }

  handleDeleteRoomSize = () => {
    const { handleDeleteRoomSize, input } = this.props
    const { value } = input
    handleDeleteRoomSize(value)
  }

  renderInputFields = () => {
    const {
      input,
      t,
    } = this.props

    const { name } = input

    return (
      <div className="RoomSizeItem__Inputs">
        <Field className="RoomSizeItem__Field" name={`${name}.label`} component={RFTextInput} label={t('settings.roomsize.label')} />
        <Field className="RoomSizeItem__Field" name={`${name}.externalId`} component={RFTextInput} label={t('settings.roomsize.externalId')} />
        <Field className="RoomSizeItem__Field" name={`${name}.order`} component={RFTextInput} label={t('settings.roomsize.order')} type="number" />
        <button className="RoomSizeItem__DeleteButton" type="button" onClick={this.handleDeleteRoomSize}><FontAwesomeIcon className="RoomSizeItem__Spinner" icon={['fal', 'trash-alt']} /></button>
      </div>
    )
  }

  render() {
    const {
      isLoading,
    } = this.props

    return (
      <div className="RoomSizeItem">
        {isLoading && <div className="RoomSizeItem__Spinner"><FontAwesomeIcon className="RoomSizeItem__Spinner" icon={['fal', 'spinner']} spin /></div>}
        {!isLoading && this.renderInputFields()}
      </div>
    )
  }
}

export default translate()(RoomSizeItem)
