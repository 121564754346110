import moment from 'moment'

const subtractOne = context => date => date.clone().add(-1, context)

const TIME_FRAMES_META = {
  THIS_MONTH: { context: 'month' },
  LAST_MONTH: { processDate: subtractOne('month'), context: 'month' },
  THIS_QUARTER: { context: 'quarter' },
  LAST_QUARTER: { processDate: subtractOne('quarter'), context: 'quarter' },
  THIS_YEAR: { context: 'year' },
  LAST_YEAR: { processDate: subtractOne('year'), context: 'year' },
}

export const TIME_FRAMES_KEYS = [
  'THIS_MONTH',
  'LAST_MONTH',
  'THIS_QUARTER',
  'LAST_QUARTER',
  'THIS_YEAR',
  'LAST_YEAR',
]

const getDates = (date, context) => {
  const startDate = date.startOf(context).format('YYYY-MM-DD')
  const endDate = date.endOf(context).format('YYYY-MM-DD')
  return {
    from: startDate,
    to: endDate,
  }
}

export const getFromToDatesForTimeframe = (timeframe) => {
  const now = moment()
  return getDates(TIME_FRAMES_META[timeframe]?.processDate?.(now) || now, TIME_FRAMES_META[timeframe].context)
}
