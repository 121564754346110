import { createAction } from 'redux-actions'
import { AUTH0_CLIENT_ID } from 'helper/constants'

export const authChanged = createAction('AUTH_CHANGED')
export const logout = createAction('LOGOUT')

const AUTH0_RESET_PASSWORD = 'https://ritterwald.eu.auth0.com/dbconnections/change_password'

export const resetPassword = email => async () => {
  const headers = new Headers({
    'content-type': 'application/json',
  })

  const data = {
    client_id: AUTH0_CLIENT_ID,
    email,
    connection: 'Username-Password-Authentication',
  }

  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(data),
  }

  const resp = await fetch(AUTH0_RESET_PASSWORD, options)

  return resp
}
