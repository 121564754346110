import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import modifyClassName from 'helper/modifyClassName'
import { processStateColors } from 'helper/viewHelper'

import './style.less'

const renderNextLine = (isLastLane) => {
  if (isLastLane) return <div className="TaskLaneHeader__PlaceHolder" />
  return (
    <div className="TaskLaneHeader__LineContainer">
      <div className="TaskLaneHeader__Line" />
      <div className="TaskLaneHeader__Arrow">
        <FontAwesomeIcon icon={['fal', 'arrow-right']} />
      </div>
    </div>
  )
}

const renderFirstLane = (isFirstLane) => {
  if (isFirstLane) return <div className="TaskLaneHeader__PlaceHolder" />
  return (
    <div className="TaskLaneHeader__LineContainer">
      <div className={modifyClassName('TaskLaneHeader__Line', { marginLeft: true })} />
    </div>
  )
}

const TaskLaneHeader = ({
  laneLabel,
  isFirstLane,
  isLastLane,
  t,
}) => (
  <div className="TaskLaneHeader">
    {renderFirstLane(isFirstLane)}
    <div className={`TaskLaneHeader__Label ProcessState--${processStateColors[laneLabel] || 'other'}`}>
      {t(`process.states.${laneLabel}`)}
    </div>
    {renderNextLine(isLastLane)}
  </div>
)

TaskLaneHeader.propTypes = {
  laneLabel: PropTypes.string,
  isFirstLane: PropTypes.bool,
  isLastLane: PropTypes.bool,
  ...i18nPropTypes,
}

TaskLaneHeader.defaultProps = {
  isFirstLane: false,
  isLastLane: false,
  laneLabel: '',
}

export default translate()(TaskLaneHeader)
