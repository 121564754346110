import React, { Component } from 'react'
import { values as _values } from 'lodash'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import UserSelector from 'selectors/user'

import RFDropDownInput from 'components/common/RFDropDownInput'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'

import { userDisplayName } from 'helper/viewHelper'

class RFUserDropDownInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()),
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    displaySuggestionsOnTop: PropTypes.bool,
    ...i18nPropTypes,
  }

  static defaultProps = {
    className: '',
    input: {
      onChange: () => {},
    },
    meta: {},
    onChange: () => {},
    users: undefined,
    isLoading: false,
    displaySuggestionsOnTop: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
    }
  }

  componentDidMount() {
    const { users } = this.props
    if (users) {
      this.setState({
        suggestions: users,
      })
    }
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    const { users, fetchedUsers } = this.props
    const source = users ? _values(users) : _values(fetchedUsers)
    let suggestions = null
    if (reason === 'input-changed') {
      suggestions = source
        .filter(user => user.firstName.toLowerCase().includes(value.toLowerCase()) || user.lastName.toLowerCase().includes(value.toLowerCase()))
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .slice(0, 5)
    } else {
      suggestions = source
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .slice(0, 5)
    }
    this.setState({ suggestions })
  }

  render = () => {
    const { isLoading } = this.props
    const { suggestions } = this.state
    return (
      <RFDropDownInput
        {...this.props}
        isLoading={isLoading}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        getSuggestionValue={userDisplayName}
      />
    )
  }
}

export default translate()(RFUserDropDownInput)

export const RFUserDropDownInputWithHOC = AsyncDataLoadingHOC({
  fetchedUsers: {
    entitySelector: UserSelector.getUsersAsArray,
    entityType: 'users',
  },
})(translate()(RFUserDropDownInput))
