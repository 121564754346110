import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { isEmpty } from 'lodash'

import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LOGOUT_URL } from 'helper/constants'
import { userMeAction } from 'actions/user'
import { fetchEnumValuesAction } from 'actions/entities'
import App from 'containers/App'
import Login from 'containers/auth/Login'
import TenantSelect from 'containers/auth/TenantSelect'

import UserSelector from 'selectors/user'
import AuthenticationSelector from 'selectors/authentication'

import './style.less'

/*
TODO: Should consider a different solution to prevent Authentication method from blocking App rerendering.
solution now: wrapping Authentication in withRouter HOC
possible other solution: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
*/

class Authentication extends Component {
  static propTypes = {
    auth0: PropTypes.shape({}),
    currentUser: PropTypes.shape({}),
    activeTenant: PropTypes.shape({}),
    fetchUser: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    auth0: {},
    currentUser: null,
    activeTenant: null,
  }

  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidMount = async () => {
    const { fetchUser, currentUser, auth0, fetchEnumValues, activeTenant } = this.props

    if (auth0.isAuthenticated() && !currentUser) {
      try {
        await fetchUser()
        if (activeTenant) {
          await fetchEnumValues()
        }
      } catch (e) {
        console.error(e)
        this.setState({ error: e })
      }
    }
  }

  fetchEnumValues = async () => {
    const { fetchEnumValues } = this.props
    try {
      await fetchEnumValues()
    } catch (e) {
      console.error(e)
      this.setState({ error: e })
    }
  }

  renderContainer = children => <div className="Authentication">{children}</div>

  renderLoading = () => this.renderContainer(
    <>
      <FontAwesomeIcon className="Authentication__Spinner" icon={['fal', 'spinner']} spin />
      <div>{this.props.t('common.loadingApp')}</div>
    </>,
  )

  renderNoTenants = () => this.renderContainer(
    <div className="Authentication__ErrorContainer">
      <div className="Authentication__Error">
        {this.props.t('errors.userHasNoTenants')}
      </div>
      <div className="Authentication__ErrorLogout">
        <a target="_blank" href={LOGOUT_URL}>{this.props.t('buttons.logout')}</a>
      </div>
    </div>,
  )

  renderError = () => this.renderContainer(
    <div className="Authentication__Error">
      {this.props.t('common.loadingAppError')}
    </div>,
  )

  render = () => {
    const { auth0, currentUser, activeTenant } = this.props
    const { error } = this.state

    if (error) return this.renderError()
    if (!auth0.isAuthenticated()) return <Login auth0={auth0} />
    if (!currentUser) return this.renderLoading()
    if (isEmpty(currentUser.tenants)) return this.renderNoTenants()
    if (!activeTenant) return <TenantSelect onTenantSelect={this.fetchEnumValues} />
    return <App />
  }
}

const mapStateToProps = state => ({
  currentUser: UserSelector.getCurrentUser(state),
  activeTenant: AuthenticationSelector.getActiveTenant(state),
})

const mapDispatchToProps = {
  fetchUser: userMeAction,
  fetchEnumValues: fetchEnumValuesAction,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(translate()(Authentication)))
