import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const SimpleUserRow = ({
  user,
  isHeader,
  t,
  onRemoveClick,
}) => (
  <div className="SimpleUserRow">
    <div className={modifyClassName('SimpleUserRow__Value', { isHeader })}>{isHeader ? t('table.firstName') : user.firstName}</div>
    <div className={modifyClassName('SimpleUserRow__Value', { isHeader })}>{isHeader ? t('table.lastName') : user.lastName}</div>
    <div className="SimpleUserRow__LastValue">
      <div className={modifyClassName('SimpleUserRow__Value', { isHeader })}>{isHeader ? t('table.email') : user.email}</div>
      {!isHeader && (
        <button
          className="SimpleUserRow__RemoveUserButton"
          type="button"
          onClick={onRemoveClick}
        >
          <FontAwesomeIcon icon={['fal', 'trash-alt']} />
        </button>
      )}
    </div>
  </div>
)

SimpleUserRow.propTypes = {
  user: PropTypes.shape({}),
  onRemoveClick: PropTypes.func,
  isHeader: PropTypes.bool,
  ...i18nPropTypes,
}

SimpleUserRow.defaultProps = {
  user: {},
  onRemoveClick: () => {},
  isHeader: false,
}

export default translate()(SimpleUserRow)
