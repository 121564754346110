import React from 'react'
import { connect } from 'react-redux'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'
import ReportSelector from 'selectors/report'
import { downloadReportAction } from 'actions/report'
import { getFromToDatesForTimeframe } from 'helper/timeframes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.less'

export const DownloadCsvReport = ({ selectedDetailReport, downloadReport, selectedTimeframeDates, selectedTimeframe }) => {

  const onClick = () => {
    const filename = [
      'export',
      selectedTimeframeDates.from,
      'to',
      selectedTimeframeDates.to,
    ]
    downloadReport(selectedDetailReport.detailData, filename.join('_') + '.csv')
  }

  return (
    <div className="DownloadCsvReport">
      <div className="DownloadCsvReport__Container">
        <div className="DownloadCsvReport__Icon">
          <FontAwesomeIcon icon={['fal', 'file-csv']} />
        </div>
        <p className="DownloadCsvReport__Text">
          Laden Sie jetzt eine CSV Datei für den oben gewählten Zeitraum herunter.
        </p>
        <button onClick={onClick} className="Button Button--primary DownloadCsvReport__Button">
          CSV Herunterladen
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedReport: ReportSelector.getSelectedReport(state),
    selectedTimeframe: ReportSelector.getSelectedTimeframe(state),
    selectedTimeframeDates: getFromToDatesForTimeframe(ReportSelector.getSelectedTimeframe(state)),
  }
}

const mapDispatchToProps = {
  downloadReport: downloadReportAction
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(DownloadCsvReport)

export default AsyncDataLoadingHOC({
  selectedDetailReport: {
    idSelector: ReportSelector.getSelectedReportIdentifier,
    entityType: 'reports',
  },
})(connectedComponent)
