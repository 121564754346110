import React from 'react'
import PropTypes from 'prop-types'
import { userDisplayName, getCapitalizedFirstCharacters } from 'helper/viewHelper'

import './style.less'

const ModifiedUserNameCircle = modifier => WrappedComponent => ({ ...props }) => (
  <WrappedComponent modifier={modifier} {...props} />
)

const UserNameCircle = ({
  className,
  user,
  modifier,
}) => (
  <div className={`UserNameCircle UserNameCircle--${modifier} ${className}`}>
    {getCapitalizedFirstCharacters(userDisplayName(user))}
  </div>
)

UserNameCircle.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({}),
  modifier: PropTypes.string,
}

UserNameCircle.defaultProps = {
  modifier: '',
  className: '',
  user: {},
}

export const BigUserNameCircle = ModifiedUserNameCircle('big')(UserNameCircle)
export const SmallUserNameCircle = ModifiedUserNameCircle('small')(UserNameCircle)
