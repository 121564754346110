import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import ValueWithLabel from 'components/common/ValueWithLabel'

import { getSelectedApartmentId } from 'selectors/navigation'
import AsyncDataLoadingHOC from 'containers/common/AsyncDataLoading'

import './style.less'

const fieldsToRender = [
  'externalId',
  'area',
  'isWBS',
  'address',
  'location',
  'numberOfRooms',
  'size',
  'ovm',
  'isEmpty',
]

class ApartmentDetail extends Component {
  static propTypes = {
    apartment: PropTypes.shape({}),
    ...i18nPropTypes,
  }

  static defaultProps = {
    apartment: {},
  }

  keyShouldRender = key => fieldsToRender.includes(key)

  getKeyLabel = (key) => {
    const { t } = this.props
    return t(`apartment.${key}`)
  }

  getKeyValue = (key, value) => {
    const { t } = this.props
    if (key === 'isWBS') return value ? t('apartment.values.isWBS') : t('apartment.values.isNotWBS')
    if (key === 'size') return value?.length > 0 ? t('apartment.values.size', { size: value }) : '-'
    if (key === 'ovm') return value?.length > 0 ? t('apartment.values.ovm', { euro: value }) : '-'
    if (key === 'isEmpty') return value ? t('apartment.values.yes') : t('apartment.values.no')
    return value
  }


  render() {
    const { apartment } = this.props
    return (
      <div className="ApartmentDetail">
        <div className="ApartmentDetail__Header" />
        <div className="ApartmentDetail__Infos">
          {_.keys(apartment).map((key) => {
            if (!this.keyShouldRender(key)) return null
            return (
              <ValueWithLabel
                className="ApartmentDetail__Value"
                label={this.getKeyLabel(key)}
                key={key}
              >
                {this.getKeyValue(key, apartment[key])}
              </ValueWithLabel>
            )
          })}
        </div>
      </div>
    )
  }
}

export default AsyncDataLoadingHOC({
  apartment: {
    idSelector: getSelectedApartmentId,
    entityType: 'apartments',
  },
})(translate()(ApartmentDetail))
