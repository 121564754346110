import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'
import { withRouter, NavLink } from 'react-router-dom'

import Checkbox from 'components/common/Checkbox'
import ProductQty from 'components/order/ProductQty'
import CommentContainer from 'containers/common/CommentContainer'

import RFProductDropDown from 'components/common/RFProductDropDown'
import RFTextInput from 'components/common/RFTextInput'

import { setScrollToProduct } from '../../../actions/scrolltoproduct'

import './style.less'
import { debounce } from 'lodash'

class OrderLineItemDetail extends Component {
  static propTypes = {
    ...i18nPropTypes,
    product: PropTypes.shape({}).isRequired,
    allProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    apartment: PropTypes.shape({}).isRequired,
    room: PropTypes.shape({}).isRequired,
    roomType: PropTypes.shape({}), //.isRequired,
    lineItem: PropTypes.shape({}),
    onUpdateLineItem: PropTypes.func.isRequired,
    onChangeProduct: PropTypes.func.isRequired,
    isInOverview: PropTypes.bool.isRequired,
    isReadOnly: PropTypes.bool,
    roomSizeMissing: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    lineItem: {
      qty: 0,
      isRequired: false,
    },
    isReadOnly: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      description: props.lineItem.description || '',
    }
  }

  updateLineItem = async (lineItem) => {
    const { onUpdateLineItem } = this.props
    this.setState({ loading: true })
    let result
    try {
      result = await onUpdateLineItem(lineItem, false)
    } catch (e) {
      console.error(e)
      alert('Es ist ein Fehler aufgetreten, bitte laden Sie die Seite neu.')
    }
    this.setState({ loading: false })
    return result
  }

  handleIsRequiredChange = async (isRequired) => {
    const { lineItem } = this.props
    this.updateLineItem({ ...lineItem, isRequired })
  }

  handleQtyChange = async (newQty) => {
    const { lineItem, product } = this.props
    // const { lineItem, product, roomType, apartment } = this.props
    // if (product.unit === 'm²' && roomType.label?.toLowerCase() === 'sonstiges wohnung') {
    //   newQty = apartment?.size || newQty
    // }
    this.updateLineItem({ ...lineItem, qty: newQty, product: product.id })
  }

  handleDescriptionChange = (event) => {
    event.persist()
    const description = event?.target?.value || ''
    this.setState({
      description,
    })
    this.persistLineItemDescription(description)
  }

  persistLineItemDescription = debounce((description) => {
    const { lineItem, product } = this.props
    this.updateLineItem({ ...lineItem, description, product: product.id })
  }, 500)

  onClickApplyProductToMultipleRooms = () => {
    const {
      dispatch,
      history,
      product,
    } = this.props
    dispatch(setScrollToProduct(product.id))
    history.push(`../apply/${product.id}`)
  }

  render = () => {
    const {
      t,
      lineItem,
      product,
      allProducts,
      onChangeProduct,
      // isInOverview,
      isReadOnly,
      roomSizeMissing,
      // apartment,
      roomType,
    } = this.props

    const { loading } = this.state
    const showMultipleRoomsLink = roomType?.label?.toLowerCase() !== 'sonstiges wohnung' 
      && product.groupKey 
      && lineItem?.qty > 0 
      && !roomSizeMissing
    const showCommentContainer = lineItem?.qty > 0 
      || lineItem?.comments?.length > 0 
      || lineItem?.changelogs?.length > 0
    return (
      <div className="OrderLineItemDetail">
        <div className="OrderLineItemDetail__Actions">
          <ProductQty
            qty={lineItem.qty}
            unit={product.unit}
            // roomType={roomType}
            isReadOnly={isReadOnly}
            loading={loading}
            onQtyChange={this.handleQtyChange}
          />
          {/* {product.unit === 'm²' && apartment?.size && <span style={{ padding: '0 .25rem', fontWeight: 'bold' }}>{'(' + apartment?.size + ' m²)'}</span>} */}
          {(lineItem?.id && lineItem?.qty > 0) && (
            <Checkbox
              className="OrderLineItemDetail__IsRequiredCheckbox"
              label={t('orders.isRequired')}
              checked={lineItem?.isRequired}
              onChange={this.handleIsRequiredChange}
              enabled={lineItem?.qty > 0 && !isReadOnly}
              loading={loading}
            />)}
          {allProducts.length > 1 && (
            <RFProductDropDown
              className="OrderLineItemDetail__ProductDropDown"
              products={allProducts}
              onChange={onChangeProduct}
              initialProduct={product}
              label={t('common.grade')}
              value={product}
            />)}
        </div>
        {roomType?.label?.toLowerCase() === 'sonstiges wohnung' && // product.qty > 0 &&
          <div className="OrderLineItemDetail__Description">
            <RFTextInput
              label={t('orders.description')}
              meta={{}}
              input={{
                name: 'description',
                value: this.state.description,
                onChange: this.handleDescriptionChange,
              }}
            />
          </div>
        }
        {showMultipleRoomsLink && (
          <a className="OrderLineItemDetail__ApplyToMultipleRooms" onClick={this.onClickApplyProductToMultipleRooms}>
            {t('orders.applyProductToMultipleRooms')}
          </a>)}
        { /*showMultipleRoomsLink && (
          <NavLink className="OrderLineItemDetail__ApplyToMultipleRooms" to={`../apply/${product.id}`}>
            {t('orders.applyProductToMultipleRooms')}
        </NavLink>)*/}
        {roomSizeMissing && (
          <NavLink className="OrderLineItemDetail__ApplyToMultipleRooms" to="../rooms">
            {t('orders.defineMissingRooms')}
          </NavLink>)}
        <div className={modifyClassName('OrderLineItemDetail__ProductDescription', { showCommentContainer })}>{product.description}</div>
        {showCommentContainer && (
          <CommentContainer lineItem={lineItem} />
        )}
      </div>)
  }
}

export default translate()(connect()(withRouter(OrderLineItemDetail)))
