import _ from 'lodash'
import { createSelector } from 'reselect'

import PageSelector from './page'
import { getSelectedOrderId } from './navigation'

const catalogsSelector = state => _.get(state, 'entities.catalogs', {})
const orderSelector = state => _.get(state, 'entities.orders', {})

/* eslint-disable no-return-assign */
const getCatalogsForCurrentPage = createSelector(
  catalogsSelector,
  PageSelector.getCatalogIdsForCurrentPage,
  (catalogs, currentPageIds) => {
    const result = {}
    currentPageIds.forEach(id => (result[id] = catalogs[id]))
    return result
  },
)

const getCatalogForSelectedOrder = createSelector(
  catalogsSelector,
  orderSelector,
  getSelectedOrderId,
  (catalogs, orders, selectedOrderId) => {
    const catalogId = orders?.[selectedOrderId]?.catalog
    const catalog = catalogs?.[catalogId]
    return catalog
  },
)

const getCatalogs = createSelector(
  catalogsSelector,
  catalogs => catalogs,
)

export default {
  getCatalogsForCurrentPage,
  getCatalogForSelectedOrder,
  getCatalogs,
  getCatalogsAsArray: state => _.values(catalogsSelector(state)),
}
