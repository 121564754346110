import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { propTypes as i18nPropTypes, translate } from 'i18n'

import { PrimaryButton, SecondaryButton } from 'components/common/Button'
import { Title } from 'components/process/ProcessTitle'

import { push } from 'connected-react-router'


import './style.less'


class CorrectState extends Component {
  static propTypes = {
    order: PropTypes.shape({}),
    onDispatchAction: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    order: null,
  }

  handleSubmitAction = async () => {
    const { order, onDispatchAction } = this.props
    onDispatchAction(order, { action: 'Submit' })
  }

  handleReviewOrder = () => this.props.push(`/orders/${this.props.order.id}/comments`)

  handleResetToDraft = async () => {
    const { order, onDispatchAction } = this.props
    onDispatchAction(order, { action: 'Draft' })
  }


  render = () => {
    const title = this.props.t('process.correctStateTitle')
    const name = this.props.order.lastProcessUser?.trim()
    return (
      <>
        <Title>
          {name && `${title} (${name})`}
          {!name && `${title}`}
        </Title>
        <div className="CorrectState">
          <SecondaryButton type="submit" onClick={this.handleResetToDraft} className="DecisionState__Button">
            {this.props.t(`process.resetToDraft`)}
          </SecondaryButton>
          <PrimaryButton type="submit" onClick={this.handleReviewOrder} className="CorrectState__Button">
            {this.props.t('process.reviewOrder')}
          </PrimaryButton>
          <PrimaryButton type="submit" onClick={this.handleSubmitAction} className="CorrectState__SubmitButton">
            {this.props.t('process.submitCorrection')}
          </PrimaryButton>
        </div>
      </>
    )
  }
}


const mapDispatchToProps = {
  push,
}

export default connect(null, mapDispatchToProps)(translate()(CorrectState))
