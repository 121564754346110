import React from 'react'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import RoomSizeConfigurationForm from 'containers/form/RoomSizeConfigurationForm'

import './style.less'

const SettingsRoomSizeConfiguration = () => (
  <div className="SettingsRoomSizeConfiguration">
    <div className="SettingsRoomSizeConfiguration__Header" />
    <RoomSizeConfigurationForm />
  </div>
)

SettingsRoomSizeConfiguration.propTypes = {
  ...i18nPropTypes,
}

export default translate()(SettingsRoomSizeConfiguration)
