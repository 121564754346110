import { createAction } from 'redux-actions'

import * as api from 'api'
import PagedDataViewSelector from 'selectors/PagedDataViewSelector'

export const receiveEntities = createAction('RECEIVE_ENTITIES')
export const removeEntity = createAction('REMOVE_ENTITY')

export const requestEntitiesForViewAction = (viewId, entityType) => async (dispatch, getState) => {
  const state = getState()
  const metaData = PagedDataViewSelector.getMetaData(state, { viewId })
  const { queries = {} } = metaData
  const payload = await api.requestEntities(entityType, queries)
  payload.viewId = viewId
  return dispatch(receiveEntities(payload))
}

export const requestEntitiesAction = (entityType, filter) => async (dispatch) => {
  const payload = await api.requestEntities(entityType, filter)
  return dispatch(receiveEntities(payload))
}

export const requestEntityAction = (entityType, id, filter = {}) => async (dispatch) => {
  const payload = await api.requestEntity(entityType, id, filter)
  return dispatch(receiveEntities({ results: payload }))
}

export const deleteEntityAction = (entityType, id) => async (dispatch) => {
  await api.deleteEntity(entityType, id)
  return dispatch(removeEntity({ entityType, entityId: id }))
}

export const fetchEnumValuesAction = () => async (dispatch) => {
  const schemas = ['roomSizes', 'roomTypes', 'grades']
  const payloads = await Promise.all(schemas.map(api.requestEntities))
  payloads.forEach(payload => dispatch(receiveEntities(payload)))
}
