import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { TopBar } from 'components/common/ToolBar'
import TaskLane from 'components/task/TaskLane'
import { requestTasksAction, orderTasksChangeAction } from 'actions/tasks'
import TaskSelector from 'selectors/task'
import { showModal } from 'actions/modal'
import SelectApartmentModal from 'containers/modal/SelectApartmentModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { push as pushAction } from 'connected-react-router'
import RFOrderTaskDropDown from 'components/common/RFOrderTaskDropDown'
import { SimpleButton, PrimaryButton } from 'components/common/Button'
import UserSelector from 'selectors/user'
import modifyClassName from 'helper/modifyClassName'
import ScrollContainer from 'components/common/ScrollContainer'

import './style.less'

const PROCESS_FOR_ME = 'me'
const PROCESS_FOR_TEAM = 'team'
const MODAL_ID = 'TASKS_CREATE_APARTMENT_MODAL'

class TasksOverview extends Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({}).isRequired,
    myTaskGroups: PropTypes.arrayOf(PropTypes.shape({})),
    teamTaskGroups: PropTypes.arrayOf(PropTypes.shape({})),
    isCaseHandler: PropTypes.bool.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    myTaskGroups: [],
    teamTaskGroups: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      processFor: PROCESS_FOR_ME,
    }
  }

  componentDidMount() {
    this.request()
  }

  request = async () => {
    const { requestTasks, currentUser } = this.props
    const { processFor } = this.state
    await this.setState({ loading: true })
    if (processFor === PROCESS_FOR_ME) {
      await requestTasks({ owner_or_process_user: currentUser.id })
    } else {
      await requestTasks({ process_user__team: currentUser.team })
    }
    await this.setState({ loading: false })
  }

  handleTaskSelect = (task) => {
    const { push } = this.props
    push(`/orders/${task.id}`)
  }

  handleProcessForByMyClicked = () => {
    this.setState({
      processFor: PROCESS_FOR_ME,
    }, () => this.request())
  }

  handleProcessForByTeamClicked = () => {
    this.setState({
      processFor: PROCESS_FOR_TEAM,
    }, () => this.request())
  }

  handleDropDownOrderChange = (orderBy) => {
    const { orderTasksChange } = this.props
    orderTasksChange(orderBy.id)
  }

  handleShowModal = () => this.props.showModal(MODAL_ID)

  renderLoading = () => <div className="TasksOverview TasksOverview__Loading"><FontAwesomeIcon icon={['fal', 'spinner']} spin /></div>

  render() {
    const {
      t,
      currentUser,
      myTaskGroups,
      teamTaskGroups,
      isCaseHandler,
    } = this.props
    const { team } = currentUser
    const { loading, processFor } = this.state
    const taskGroups = processFor === PROCESS_FOR_ME ? myTaskGroups : teamTaskGroups
    return (
      <div className="TasksOverview">
        <TopBar>
          <div className="TasksOverview__TopBarLeftContainer">
            <SimpleButton onClick={this.handleProcessForByMyClicked}>
              <div className={modifyClassName('TasksOverview__Button', { selected: processFor === PROCESS_FOR_ME })}>
                {t('tasks.orderByMe')}
              </div>
            </SimpleButton>
            <SimpleButton onClick={this.handleProcessForByTeamClicked} disabled={team === null}>
              <div className={modifyClassName('TasksOverview__Button', { selected: processFor === PROCESS_FOR_TEAM, disabled: team === null })}>
                {t('tasks.orderByTeam')}
              </div>
            </SimpleButton>
            <RFOrderTaskDropDown className="TasksOverview__DropDown" onChange={this.handleDropDownOrderChange} />
          </div>
          { isCaseHandler && <PrimaryButton onClick={this.handleShowModal}>{t('orders.create')}</PrimaryButton>}
        </TopBar>
        {loading ? this.renderLoading() : (
          <ScrollContainer className="TasksOverview__Container" scrollHorizontal>
            { taskGroups.map(({ name, tasks }, index) => (
              <TaskLane
                key={`task_${name}`}
                laneLabel={name}
                tasks={tasks}
                onTaskSelect={this.handleTaskSelect}
                isFirstLane={index === 0}
                isLastLane={index === taskGroups.length - 1}
              />))}
          </ScrollContainer>
        )}
        <SelectApartmentModal id={MODAL_ID} disableCloseButton />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: UserSelector.getCurrentUser(state),
  myTaskGroups: TaskSelector.getTasks(PROCESS_FOR_ME)(state),
  teamTaskGroups: TaskSelector.getTasks(PROCESS_FOR_TEAM)(state),
  isCaseHandler: UserSelector.getRoles(state).includes('CASE_HANDLER'),
})

export default connect(mapStateToProps, {
  requestTasks: requestTasksAction,
  push: pushAction,
  orderTasksChange: orderTasksChangeAction,
  showModal,
})(translate()(TasksOverview))
