import _ from 'lodash'
import { createSelector } from 'reselect'

const pagedDataViewSelector = (state, props) => _.get(state, `pagedDataViews.${props.viewId}`, {})
const entitiesSelector = (state, props) => _.get(state, `entities.${props.entityType}`, {})

const getMetaData = createSelector(
  pagedDataViewSelector,
  pagedDataView => pagedDataView || {},
)

/* eslint-disable no-return-assign */
const getEntities = createSelector(
  pagedDataViewSelector,
  entitiesSelector,
  (pagedDataView, entities) => {
    const ids = pagedDataView?.page?.ids || []
    return _.fromPairs(ids.map(id => [id, entities[id]]))
  },
)

export default {
  getMetaData,
  getEntities,
}
