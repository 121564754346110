import * as api from 'api'
import { denormalize } from 'normalizr'

import { tenantSchema } from 'api/schemas'
import { receiveEntities } from 'actions/entities'

/* eslint-disable import/prefer-default-export */
export const updateTenantAction = tenant => async (dispatch, getState) => {
  const payload = await api.updateTenant(tenant)
  dispatch(receiveEntities({ results: payload }))
  return denormalize(payload.result, tenantSchema, getState().entities)
}
