import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.less'

const ModifiedButton = modifier => WrappedComponent => ({ ...props }) => (
  <WrappedComponent modifier={modifier} {...props} />
)

/* eslint-disable react/button-has-type */
const Button = ({
  disabled,
  modifier,
  type = 'button',
  circleModifier = '',
  className = '',
  onClick,
  children,
  onMouseEnter,
  onMouseLeave,
}) => (
  <button
    className={`
      Button Button--${modifier} 
      Button--${circleModifier} 
      ${className}
      ${disabled ? 'Button--disabled' : ''} `}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    type={type}
    disabled={disabled}
  >
    { children }
  </button>
)

/* eslint-disable react/require-default-props */
Button.propTypes = {
  modifier: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  circleModifier: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

class CallbackButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    callbackValue: PropTypes.shape().isRequired,
    UseComponent: PropTypes.func.isRequired,
  }

  handleOnClick = () => {
    const { onClick, callbackValue } = this.props
    onClick(callbackValue)
  }

  render() {
    const { UseComponent } = this.props
    const restProps = { ...this.props, onClick: this.handleOnClick }
    return <UseComponent {...restProps} />
  }
}

/* eslint-disable import/prefer-default-export */
export const PrimaryButton = ModifiedButton('primary')(Button)
export const SecondaryButton = ModifiedButton('secondary')(Button)
export const LinkButton = ModifiedButton('link')(Button)
export const IconButton = ModifiedButton('icon')(Button)
export const SimpleButton = ModifiedButton('simple')(Button)

export const PrimaryCallbackButton = props => <CallbackButton UseComponent={PrimaryButton} {...props} />
export const SimpleCallbackButton = props => <CallbackButton UseComponent={SimpleButton} {...props} />
