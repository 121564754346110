export const selector = state => state.modal

export const isVisible = (state, id) => state?.modal?.[id]?.show || false
export const getContent = (state, id) => state?.modal?.[id]?.content
export const getData = (state, id) => state?.modal?.[id]?.data

export default {
  isVisible,
  getContent,
  getData,
}
