import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { orderAttributes } from 'reducers/TaskReducer'
import RFDropDownInput from 'components/common/RFDropDownInput'

import './style.less'

class RFOrderTaskDropDown extends Component {
  static propTypes = {
    className: PropTypes.string,
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    onChange: PropTypes.func,
    ...i18nPropTypes,
  }

  static defaultProps = {
    className: '',
    input: {
      onChange: () => {},
    },
    meta: {},
    onChange: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      value: { id: orderAttributes.orderByUpdateNewest, label: props.t(`tasks.${orderAttributes.orderByUpdateNewest}`) },
    }

    this.orderValues = values(orderAttributes).map(orderAttribute => ({ id: orderAttribute, label: props.t(`tasks.${orderAttribute}`) }))
  }

  handleOnChange = (orderBy) => {
    const { onChange } = this.props
    this.setState({
      value: orderBy,
    })
    onChange(orderBy)
  }

  onSuggestionsFetchRequested = () => { }

  getSuggestionValue = orderBy => orderBy.label.toUpperCase()

  render = () => {
    const { value } = this.state
    const { t } = this.props
    return (
      <RFDropDownInput
        {...this.props}
        label={t('tasks.sortBy')}
        isLoading={false}
        suggestions={this.orderValues}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        getSuggestionValue={this.getSuggestionValue}
        value={value}
        onChange={this.handleOnChange}
        className="RFOrderTaskDropDown"
        searchable={false}
      />
    )
  }
}

export default translate()(RFOrderTaskDropDown)
