import React, { Component } from 'react'
import { connect } from 'react-redux'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { push } from 'connected-react-router'

import { TopBar } from 'components/common/ToolBar'
import Filter from 'components/common/Filter'
import { PrimaryButton } from 'components/common/Button'
import TableDataView from 'containers/common/TableDataView'
import BoolValue from 'components/common/BoolValue'
import { localDateTime } from 'helper/viewHelper'


import './style.less'

const VIEW_ID = 'USERS'
const ENTITY_TYPE = 'users'

class SettingsUser extends Component {
  static propTypes = {
    ...i18nPropTypes,
  }

  handleInviteUser = () => this.props.push('/settings/users/invite')

  handleSelectEntity = entity => this.props.push(`/settings/users/${entity.id}`)

  render() {
    const { t } = this.props

    const columns = [
      { header: t('table.email'), accessor: 'email' },
      { header: t('table.firstName'), accessor: 'firstName' },
      { header: t('table.lastName'), accessor: 'lastName' },
      { header: t('table.isTenantAdmin'), accessor: user => <BoolValue value={user.isTenantAdmin} /> },
      { header: t('table.isTenantAnalyst'), accessor: user => <BoolValue value={user.isTenantAnalyst} /> },
      { header: t('table.isEnabled'), accessor: user => <BoolValue value={user.isEnabled} /> },
      { header: t('table.createdAt'), accessor: user => localDateTime(user.dateJoined) },
    ]

    return (
      <div className="SettingsUser">
        <TopBar>
          <Filter />
          <PrimaryButton onClick={this.handleInviteUser}>{t('buttons.invite')}</PrimaryButton>
        </TopBar>
        <TableDataView viewId={VIEW_ID} entityType={ENTITY_TYPE} columns={columns} onSelectEntity={this.handleSelectEntity} />
      </div>
    )
  }
}

export default connect(null, { push })(translate()(SettingsUser))
