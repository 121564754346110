import { get } from 'lodash'
import { createSelector } from 'reselect'

const pathNameSelector = state => get(state, 'router.location.pathname')

const getPathSegment = segment => (path) => {
  if (path) {
    const segments = path.split('/')
    if (segments.length > segment) {
      return segments[segment]
    }
  }
  return undefined
}

export const getSelectedApartmentId = createSelector(pathNameSelector, getPathSegment(2))
export const getSelectedTeamId = createSelector(pathNameSelector, getPathSegment(3))
export const getSelectedContractorId = createSelector(pathNameSelector, getPathSegment(3))
export const getSelectedOrderId = createSelector(pathNameSelector, getPathSegment(2))
export const getSelectedRoomId = createSelector(pathNameSelector, getPathSegment(4))
export const getSelectedUserId = createSelector(pathNameSelector, getPathSegment(3))
export const getSelectedProductId = createSelector(pathNameSelector, getPathSegment(4))

const getContextPathName = createSelector(pathNameSelector, getPathSegment(1))
export const getIsInTenantContext = createSelector(
  getContextPathName,
  (pathname) => {
    if (pathname === 'settings') return true
    return false
  },
)

export const getSelectedProductPackageId = createSelector(
  pathNameSelector,
  getContextPathName,
  (pathname, contextPathname) => {
    if (contextPathname === 'settings') return getPathSegment(3)(pathname)
    return getPathSegment(2)(pathname)
  },
)

export default pathNameSelector
