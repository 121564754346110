import _ from 'lodash'
import { createSelector } from 'reselect'
import { getSelectedApartmentId, getSelectedOrderId } from 'selectors/navigation'
import { denormalize } from 'normalizr'
import { apartmentSchema } from 'api/schemas'

import PageSelector from './page'

const entitySelector = state => state?.entities || {}

const apartmentSelector = state => _.get(state, 'entities.apartments', {})

const selectedApartmentIdSelector = state => _.get(state, 'apartment.selectedApartmentId')

/* eslint-disable no-return-assign */
const getApartmentsForCurrentPage = createSelector(
  apartmentSelector,
  PageSelector.getApartmentIdsForCurrentPage,
  (apartments, currentPageIds) => {
    const result = {}
    currentPageIds.forEach(id => (result[id] = apartments[id]))
    return result
  },
)

const getCurrentSelectedApartment = createSelector(
  entitySelector,
  getSelectedApartmentId,
  (entities, selectedApartmentId) => {
    if (!entities.apartments) return null
    return denormalize(selectedApartmentId, apartmentSchema, entities)
  },
)

const getCurrentSelectedApartmentFromState = createSelector(
  entitySelector,
  selectedApartmentIdSelector,
  (entities, selectedApartmentId) => {
    if (!entities.apartments) return null
    return denormalize(selectedApartmentId, apartmentSchema, entities)
  },
)

const getSelectedApartmentFromOrderId = createSelector(
  entitySelector,
  getSelectedOrderId,
  (entities, selectedOrderId) => {
    if (!entities.orders) return null
    return denormalize(entities.orders[selectedOrderId].apartment, apartmentSchema, entities)
  },
)

export default {
  getApartmentsForCurrentPage,
  getCurrentSelectedApartment,
  getCurrentSelectedApartmentFromState,
  getSelectedApartmentFromOrderId,
}
