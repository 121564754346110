import { handleActions } from 'redux-actions'

import { initViewAction, updateViewAction } from 'actions/data-view'
import { removeEntity } from 'actions/entities'

const DEFAULT_PAGE_SIZE = 20

const reducer = handleActions({
  [initViewAction]: (state, action) => ({
    ...state,
    [action.payload.id]: {
      id: action.payload.id,
      entityType: action.payload.entityType,
      status: 'FETCHING',
      paging: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      },
      ordering: {
        attribute: null,
        asc: true,
      },
      filters: action?.payload?.filters || {},
      data: {
        ids: [],
        count: 0,
      },
    },
  }),
  [updateViewAction]: (state, action) => ({
    ...state,
    [action.payload.id]: {
      ...state[action.payload.id],
      ...action.payload,
    },
  }),
  [removeEntity]: (state, action) => {
    const result = { ...state }
    Object.entries(result).forEach(([key, val]) => {
      if (val.entityType === action.payload.entityType) {
        result[key] = {
          ...val,
          data: {
            ...val.data,
            ids: val.data.ids.filter(id => id !== action.payload.entityId),
          },
        }
      }
    })
    return result
  },
}, {})

export default reducer
