import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { connect } from 'react-redux'

import { PrimaryButton, LinkButton } from 'components/common/Button'
import TextInput from 'components/common/TextInput'

import { validateEmail } from 'helper/validators'

import { resetPassword } from 'actions/authentication'

import './style.less'

class Login extends Component {
  static propTypes = {
    auth0: PropTypes.shape({}),
    resetPassword: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  static defaultProps = {
    auth0: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      showPasswordReset: false,
      emailValue: '',
      errorMessage: null,
      resetSuccess: false,
    }
  }

  handleLogin = () => {
    const { auth0 } = this.props
    auth0.login()
  }

  handleResetPasswordClick = () => {
    const { showPasswordReset } = this.state
    this.setState({
      showPasswordReset: !showPasswordReset,
    })
  }

  handlePasswordResetFieldChange = (e) => {
    this.setState({
      emailValue: e.target.value,
    })
  }

  handleResetPassword = async () => {
    const { emailValue } = this.state
    const { resetPassword: reset, t } = this.props
    if (!validateEmail(emailValue)) {
      this.setState({
        errorMessage: t('errors.invalidEmail'),
      })
    } else {
      const response = await reset(emailValue)
      const { status = 400 } = response

      if (status === 200) {
        this.setState({
          resetSuccess: true,
        })
      } else {
        this.setState({
          errorMessage: t('auth.login.resetFailure', { email: emailValue }),
        })
      }
    }
  }

  renderLogin = t => (
    <div className="Login__Buttons">
      <PrimaryButton className="Login__SubmitButton" onClick={this.handleLogin} type="button">{t('buttons.login')}</PrimaryButton>
      <LinkButton className="Login__SecondaryButton" onClick={this.handleResetPasswordClick} type="button">{t('buttons.forgotPassword')}</LinkButton>
    </div>
  )


  renderField = (t, emailValue, errorMessage) => (
    <div className="Login__Buttons">
      <TextInput name="email" label="E-Mail" type="email" value={emailValue} errorMessage={errorMessage} onChange={this.handlePasswordResetFieldChange} />
      <PrimaryButton className="Login__SubmitButton" type="button" onClick={this.handleResetPassword}>{t('buttons.resetPassword')}</PrimaryButton>
      <LinkButton className="Login__SecondaryButton" onClick={this.handleResetPasswordClick} type="button">{t('buttons.login')}</LinkButton>
    </div>
  )

  renderSuccess = (t, emailValue) => (
    <div className="Login__Buttons">
      <div className="Login__ResetSuccess">{t('auth.login.resetSuccess', { email: emailValue })}</div>
      <PrimaryButton className="Login__SubmitButton" type="button" onClick={this.handleLogin}>{t('buttons.login')}</PrimaryButton>
    </div>
  )

  renderPasswordReset = () => {
    const { t } = this.props
    const { emailValue, errorMessage, resetSuccess } = this.state
    return resetSuccess ? this.renderSuccess(t, emailValue) : this.renderField(t, emailValue, errorMessage)
  }

  render() {
    const { t } = this.props
    const { showPasswordReset, emailValue, errorMessage } = this.state

    return (
      <div className="Login">
        <div className="Login__Title">{t('auth.login.title')}</div>
        { showPasswordReset ? (this.renderPasswordReset(t, emailValue, errorMessage)) : this.renderLogin(t) }
      </div>
    )
  }
}

export default connect(null, { resetPassword })(translate()(Login))
