import React from 'react'
import PropTypes from 'prop-types'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const BadgeCount = ({ badgeCount }) => (
  <div className={modifyClassName('BadgeCount', { multi: badgeCount > 9 })}>{badgeCount}</div>
)

BadgeCount.propTypes = {
  badgeCount: PropTypes.number,
}

BadgeCount.defaultProps = {
  badgeCount: null,
}

export default BadgeCount
